<template>
  <div class="admin-cv-container">
    <!-- Affichage de la table si aucun membre n'est sélectionné -->
    <div v-if="!selectedMember" class="interactive-table-admin">
      <br>
      <table>
        <thead>
          <tr>
            <th>Username</th>
            <th>Photo de profil</th>
            <!-- Nouvelle colonne pour le CV -->
            <th>CV</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, index) in filteredRows" :key="index">
            <!-- Username -->
            <td>{{ row.username }}</td>

            <!-- Photo de profil -->
            <td>
              <img
                v-if="row.profilePicture"
                :src="row.profilePicture"
                alt="Profile Picture"
                class="profile-img"
              />
              <img
                v-else
                src="default_profile.jpg"
                alt="Default Profile"
                class="profile-img"
              />
            </td>

            <!-- Colonne CV : bouton pour afficher le CV -->
            <td>
              <button 
                v-if="row.cvUrl" 
                class="view-cv-button"
                @click="handleViewCV(row)"
              >
                Voir CV
              </button>
              <span v-else>Aucun CV</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Affichage du CV si un membre est sélectionné -->
    <div v-else class="cv-viewer">
      <button @click="selectedMember = null" class="back-button">Retour</button>

      <div v-if="isPdf(selectedMember.cvUrl)">
        <iframe :src="selectedMember.cvUrl" class="cv-iframe"></iframe>
      </div>
      <div v-else>
        <img :src="selectedMember.cvUrl" alt="CV" class="cv-image" />
      </div>
    </div>
  </div>
</template>

<script>
import UserServices from "@/services/UserServices";

export default {
  name: "AdminCV",
  data() {
    return {
      rows: [],
      selectedMember: null,
    };
  },
  computed: {
    filteredRows() {
      return this.rows;
    },
  },
  methods: {
    async fetchUsersWithCV() {
      try {
        const response = await UserServices.fetchUserWithCV();
        this.rows = response.data.map((user) => ({
          username: user.username,
          profilePicture: 'https://kameleonback.jrcan.dev'+user.profile_picture,
          cvUrl: user.cv_url,
        }));
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs avec CV :", error);
      }
    },

    handleViewCV(row) {
      const fullUrl = `https://kameleonback.jrcan.dev${row.cvUrl}`;
      window.open(fullUrl, "_blank");
    },

    isPdf(url) {
      if (!url) return false;
      return url.toLowerCase().endsWith(".pdf");
    },
  },
  mounted() {
    // On récupère les utilisateurs dès que le composant est monté
    this.fetchUsersWithCV();
  },
};
</script>

<style src="../assets/admincv.css"></style>
