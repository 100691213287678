<template>
    <div>
      <h2>Enchaînement d'énigmes</h2>
      <!-- Étape 1 -->
      <Step_1
        v-if="currentStep === 1"
        @solvedStep_1="handleStep_1Solved"
      />
  
      <!-- Étape 2, dépend de Step_1 (par exemple) -->
      <Step_2
        v-if="currentStep === 2"
        :dataFromStep_1="Step_1Result" 
        @solvedStep_2="handleStep_2Solved"
      />
  
      <!-- Vous pouvez ajouter autant d’étapes que nécessaire -->
      <!--
      <Step3
        v-if="currentStep === 3"
        :dataFromStep_2="Step_2Result"
        @solvedStep3="handleStep3Solved"
      />
      -->
    </div>
  </template>
  
  <script>
  import Step_1 from "./EnigmeCoop1_Step1.vue";
  import Step_2 from "./EnigmeCoop1_Step2.vue";
  
  export default {
    name: "EnigmeCoop_Puzzle",
    components: {
      Step_1,
      Step_2,
    },
    data() {
      return {
        currentStep: 1,   // indique l’étape en cours
        Step_1Result: null, // ce qu’on récupère quand Step_1 est résolu
        // Step_2Result: null, etc. si besoin pour les autres étapes
      };
    },
    methods: {
      // Quand Step_1 est résolu
      handleStep_1Solved(result) {
        // On stocke le résultat pour éventuellement l'envoyer à la Step_2
        this.Step_1Result = result;
        // On passe à l’étape 2
        this.currentStep = 2;
      },
      // Quand Step_2 est résolu
      handleStep_2Solved(result) {
        // Idem pour l’étape2 -> Step_2Result, etc.
        // this.Step_2Result = result;
        // On passe à l’étape 3, etc.
        alert("Toutes les étapes sont résolues ! Ou passer à l'étape 3...");
        console.log(result);
        // this.currentStep = 3;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Styles globaux du parent, si nécessaire */
  </style>
  