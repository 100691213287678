<template>
    <div class="stats-container">
      <div class="tabs-stat">
        <button 
          class="tab-button-stat" 
          :class="{ active: activeTab === 'clans' }" 
          @click="activeTab = 'clans'">
          Classement des Clans
        </button>
        <button 
          class="tab-button-stat" 
          :class="{ active: activeTab === 'players' }" 
          @click="activeTab = 'players'">
          Classement des Joueurs
        </button>
      </div>
  
      <div v-if="activeTab === 'clans'" class="ranking-table">
        <h2>Classement des Clans</h2>
        <table>
          <thead>
            <tr>
              <th scope="col">Rang</th>
              <th scope="col">Photo</th>
              <th scope="col">Nom</th>
              <th scope="col">Membres</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(clan, index) in clanData" :key="clan.rank">
              <td scope="row">{{ index + 1 }}</td>
              <td><img :src="clan.clan_pci || '/img/default_clan.png'" :alt="clan.clan_name" class="table-photo"></td>
              <td>{{ clan.clan_name }}</td>
              <td>{{ clan.clan_members_count }}</td>
                <td>{{ clan.clan_elo.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <div v-if="activeTab === 'players'" class="ranking-table">
        <h2>Classement des Joueurs</h2>
        <table>
          <thead>
            <tr>
              <th scope="col">Rang</th>
              <th scope="col">Photo</th>
              <th scope="col">Nom</th>
              <th scope="col">Points</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(player, index) in playerData" :key="player.rank">
              <td scope="row">{{ index + 1 }}</td>
              <td><img :src="'https://kameleonback.jrcan.dev'+player.user.profile_picture" :alt="player.user.username" class="table-photo"></td>
              <td>{{ player.user.username }}</td>
              <td>{{ player.member_score }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </template>
  
  <script>
  import ClanServices from '../services/ClanServices';
  import UserServices from '../services/UserServices';
  export default {
    name: "StatsView",
    data() {
      return {
        activeTab: 'clans',
        clanData: [],
        playerData: [],
      };
    },
    mounted() {
      this.fetchClanData();
      this.fetchMembersData();
    },
    methods: {
      async fetchClanData() {
        try {
          const response = await ClanServices.getClan();
          this.clanData = response.data;
        } catch (error) {
          console.error("Erreur lors de la récupération des clans :", error);
        }
      },

      async fetchMembersData() {
        try {
          const response = await UserServices.fetchMembers();
          this.playerData = response.data;
        } catch (error) {
          console.error("Erreur lors de la récupération des membres :", error);
        }
      },
    }
  };
  </script>
  
  <style src="../assets/statistiques.css"></style>