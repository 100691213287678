<template>
   <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
    </div>
  <main class="main">
    <div class="enigmeregle-rules-container">
      <h2 class="enigmeregle-title">📜 Règles Générales</h2>
      <ul class="enigmeregle-list">
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">🔒 Utilisation des Comptes :</strong> Chaque participant doit utiliser son propre compte pour une meilleure gestion des énigmes. Le partage de comptes est <span class="enigmeregle-highlight warning">interdit</span>, afin de garantir l’équité entre tous.
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">🎯 Tentatives et Réponses :</strong> Vous pouvez faire un <span class="enigmeregle-highlight positive">nombre illimité</span> de tentatives, mais gardez à l’esprit que chaque essai est enregistré. Les réponses incorrectes ou mal formatées seront simplement <span class="enigmeregle-highlight warning">rejetées</span>.
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">💡 Utilisation des Indices :</strong> Les indices sont disponibles pour vous aider si besoin, mais cela entraînera une <span class="enigmeregle-highlight neutral">petite pénalité en points</span>. Utilisez-les judicieusement pour maximiser votre score.
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">⚙️ Respect des Règles Techniques :</strong> Pour assurer une expérience agréable pour tous, nous vous invitons à éviter toute tentative d'exploiter des failles ou d'utiliser des outils non autorisés. Cela pourrait entraîner des <span class="enigmeregle-highlight warning">sanctions immédiates</span>.
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">⏳ Contraintes de Temps :</strong> Certaines énigmes peuvent inclure une contrainte de temps. Une fois le délai écoulé, les soumissions ne seront malheureusement plus prises en compte. Pensez à surveiller votre chrono !
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">🔓 Déblocage Progressif :</strong> Les énigmes se débloquent progressivement en fonction de vos réussites. Résolvez une énigme pour découvrir les suivantes et avancer dans le jeu !
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">🛠️ Outils Autorisés :</strong> Vous pouvez utiliser des outils pratiques comme une calculatrice, des scripts ou de la documentation. Cependant, l’utilisation de <span class="enigmeregle-highlight warning">sites de solutions ou d’intelligences artificielles</span> n’est pas permise pour préserver l’équité.
        </li>
        <br>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">📬 Feedback :</strong> Après chaque soumission, vous recevrez un message vous informant si votre réponse <span class="enigmeregle-highlight positive">est correcte</span> ou <span class="enigmeregle-highlight neutral">non</span>. Les détails des erreurs ne sont pas fournis pour ne pas donner d’indices involontaires.
        </li>
      </ul>

      <h2 class="enigmeregle-title">🚨 Sanctions</h2>
      <ul class="enigmeregle-list">
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">❌ Suspension :</strong> En cas de comportement non conforme, une <span class="enigmeregle-highlight warning">suspension temporaire</span> pourrait être appliquée pour évaluer la situation.
        </li>
        <li class="enigmeregle-list-item">
          <strong class="enigmeregle-highlight-begin">🚫 Disqualification :</strong> En cas de récidive ou d’infraction grave, vous pourriez être <span class="enigmeregle-highlight danger">disqualifié(e)</span>. Nous comptons sur votre fair-play !
        </li>
      </ul>
    </div>
  </main>
</template>


<script>
export default {
  name: 'EnigmeRegle',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style src="../assets/enigmeregle.css"></style>
