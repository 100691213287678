<template>
  <div id="app" class="homepage">
    <div class="home-section">
      <div class="home-content">
        <h1>Pourquoi pas <span class="highlight-purple">toi</span> ?</h1>
        <p>
          deviens le <span class="highlight-purple">prochain</span> collaborateur
          de l’entreprise <span class="highlight-green">R&MI</span>
          en <span>participant</span> à nos
          <span class="highlight-purple">énigmes</span> !
        </p>
        <div class="buttons">
          <button class="connect-button">
            <RouterLink to="/login">
              Se connecter
            </RouterLink>
          </button>
          <button class="project-button" @click="scrollToSection">Le projet</button>
        </div>
      </div>
      <div class="logo_accueil">
        <img src="/img/logo_accueil.png" alt="R&MI Logo" />
      </div>
    </div>


    <div class="home-section list_modes">
      <div class="grid">
        <div class="card clan"> 
          <i class="icon fas fa-users"></i>
          <h2>Clan</h2>
          <p>Rejoignez ou créez un clan pour collaborer, relever des défis et affronter d'autres groupes !</p>
          <button class="learn-more clan-button"><RouterLink to="/clan">
            en savoir plus
      </RouterLink></button>
        </div>
        <div class="card enigma">
          <i class="icon fas fa-question"></i>
          <h2>Énigme</h2>
          <p>Plongez dans des énigmes variées, organisées par thèmes, pour explorer un arbre de défis captivants.</p>
          <button class="learn-more enigma-button"><RouterLink to="/enigme">
            en savoir plus
      </RouterLink></button>
        </div>
        <div class="card rank">
          <i class="icon fas fa-medal"></i>
          <h2>Rank</h2>
          <p>Progressez à travers des rangs personnalisés et débloquez de nouvelles opportunités en fonction de votre niveau.</p>
          <button class="learn-more rank-button"><RouterLink to="/enigme">
            en savoir plus
      </RouterLink></button>
        </div>
        <div class="card versus">
          <i class="icon fas fa-shield-alt"></i>
          <h2>Versus</h2>
          <p>Affrontez d'autres membres dans des combats d'énigmes stratégiques et prouvez votre supériorité !</p>
          <button class="learn-more versus-button"><RouterLink to="/versus">
            en savoir plus
      </RouterLink></button>
        </div>
        <div class="card leaderboard">
          <i class="icon fas fa-chart-bar"></i>
          <h2>Classement</h2>
          <p>Suivez votre progression et comparez vos performances grâce à un classement individuel et de clan.</p>
          <button class="learn-more leaderboard-button"><RouterLink to="/clan">
            en savoir plus
      </RouterLink></button>
        </div>
      </div>
    </div>
 
    <div id="project-section" class="home-section-green">
      <div class="text">
        <h1>Kameleon, c'est quoi ?</h1>
        <p>
          Plongez dans l'univers de Kameleon : créez votre compte, 
          rejoignez un clan, résolvez des énigmes captivantes et 
          grimpez au sommet du classement. Développez vos talents 
          en cryptologie tout en vous amusant !
        </p>
      </div>
      <div class="project_video">
        <iframe class="bordered-video" width="560" height="315"
          src="https://www.youtube.com/embed/vT8q1mXeZ2A?si=jjPKpODnhHZUZl_k&amp;controls=0"
          title="YouTube video player" frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
        </iframe>
      </div>
    </div>
  </div>
</template> 

<script>
export default {
  name: "App",
  methods: {
    scrollToSection() {
      const section = document.getElementById('project-section');
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
};
</script>

<style src="../assets/home.css"></style>