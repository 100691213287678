<template>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="enigme-9-puzzle-container">
    <h1 id="enigme9-title">Énigme N°10</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : moyen 🟡</span>
        <span class="time_enigme-attempts">Nombre de tentatives : 3</span> 
        <span class="time_enigme-points">Points : 50</span>
      </p>
    <!-- Texte crypté -->
    <div class="enigme-9-crypted-section">
      <h3>Texte crypté :</h3>
      <p class="enigme-9-crypted-text">
        {{ cryptedText }}
      </p>
    </div>

    <!-- Tableau des fréquences du texte 'clair' (exemple) -->
    <div class="flex-9">
      <div class="enigme-9-frequency-section">
        <h4>Fréquence des lettres (dans le texte en clair) :</h4>
        <table>
          <thead>
            <tr>
              <th>Lettre</th>
              <th>Fréquence (%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="([letter, freq], index) in sortedFrequencyTable" :key="index">
              <td>{{ letter }}</td>
              <td>{{ freq.toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Indices successifs -->
      <div class="enigme-9-droite">
        <div class="enigme-9-hints-section">
          <h3>💡 Indices</h3>
          <ul>
            <li v-for="(hint, i) in hints" :key="i" class="enigme-9-hint-item">
              <button @click="revealHint(i)" :disabled="isHintButtonDisabled(i)">
                Voir indice {{ i + 1 }}
              </button>
              <span v-if="revealedHints.includes(i)"> : {{ hint }}</span>
            </li>
          </ul>
        </div>

        <!-- Réponse utilisateur -->
        <div class="enigme-9-answer-section">
          <h3>Votre réponse :</h3>
          <input type="text" v-model="userAnswer" placeholder="Tapez ici la réponse finale" />
          <button @click="validate" class="enigme-9-validate-button">Valider</button>
        </div>
      </div>
    </div>

      <!-- Message de validation -->
      <div class="enigme-9-validation-message" v-if="message">
        <p>{{ message }}</p>
      </div>
      <div class="quote-box">
      <p>" Quelques jours passent, aucune énigme en vue, vous voilà bien déçu. Mais vous recevez un e-mail. Il s’agit d’une mise en demeure de la part du Chef… Un fichier PDF accompagne ce mail. Une mystérieuse chaîne de caractère apparaît en fin de mail également
        "</p>
    </div>
    </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  data() {
    let cryptedText = "je teste juste un truc.";
    let frequencyTable = {};
    let space = 0;
    let uncountedCaracters = [" ", ".", "!", "?", ","];
    for (let i = 0; i < cryptedText.length; i++) {
      let letter = cryptedText[i].toLowerCase();
      if (uncountedCaracters.includes(letter)) {
        space++;
      }
      else {
        if (letter in frequencyTable) {
          frequencyTable[letter]++;
        }
        else {
          frequencyTable[letter] = 1;
        }
      }
    }

    console.log(frequencyTable)
    console.log(space)

    for (let letter in frequencyTable) {
      frequencyTable[letter] = (frequencyTable[letter] / (cryptedText.length - space)) * 100;
    }

    // Convertir l'objet frequencyTable en une liste de paires [lettre, fréquence]
    let sortedFrequencyTable = Object.entries(frequencyTable).sort((a, b) => b[1] - a[1]);

    return {
      // Exemple de texte crypté
      cryptedText,

      // Tableau de fréquences trié
      sortedFrequencyTable,

      // Indices successifs
      hints: [
        "Comparez la fréquence des lettres les plus courantes...",
        "Vérifiez la correspondance entre le texte crypté et le tableau.",
        "Le texte clair révèle un mot clé final que vous devez saisir."
      ],
      revealedHints: [],

      userAnswer: "",
      message: "",

      // Réponse attendue (exemple)
      expectedAnswer: "analyse",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(10, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(10, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(10, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(10, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    isHintButtonDisabled(index) {
      // Bloquer le hint #1 => toujours accessible (index=0).
      // Bloquer le hint #2 => seulement si hint #1 est révélé.
      // Bloquer le hint #3 => seulement si hint #2 est révélé.
      if (index === 0) return false;
      return !this.revealedHints.includes(index - 1);
    },
    revealHint(index) {
      if (!this.revealedHints.includes(index)) {
        this.revealedHints.push(index);
      }
    },
    validate() {
      if (
        this.userAnswer.trim().toLowerCase() ===
        this.expectedAnswer.toLowerCase()
      ) {
        this.message = "Bravo ! Vous avez trouvé la bonne réponse.";
        this.markSolved();
      } else {
        this.message = "Ce n'est pas la bonne réponse, réessayez ou servez-vous des indices.";
        this.incrementErrors();
      }
    },
  },
};
</script>

<style src="../assets/enigmeCrypto.css"></style>