import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import EnigmePage from './components/EnigmePage.vue';
import AppLogin from './components/AppLogin.vue';
import AppSignIn from './components/AppSignIn.vue';
import AppContact from './components/AppContact.vue';
import ClanPage from './components/ClanPage.vue';
import FaqPage from './components/FaqPage.vue';
import EnigmeLabyrinthe from './components/Enigme1_Labyrinthe.vue';
import EnigmeCarte from './components/Enigme2_Carte.vue';
import EnigmeHistoire from './components/Enigme3_Histoire.vue';
import EnigmeInverse from './components/Enigme4_Inverse.vue';
import EnigmeImage from './components/Enigme5_Image.vue';
import VersusPage from './components/VersusPage.vue';
import SkillTree from './components/SkillTree.vue';
import CoopMainMenu from './components/CoopRiddlesMain.vue';
import ClanPage_login from './components/ClanPage_login.vue';
import Dashboard_login from './components/Dashboard_login.vue';
import ClanCreate from './components/ClanCreate.vue';
import EnigmeIndisponible from './components/EnigmeIndisponible.vue';
import EnigmeScytale from './components/Enigme7_Scytale.vue';
import EnigmeOrdinateur from './components/Enigme8_Ordinateur.vue';
import EnigmeEquation from './components/Enigme9_Equation.vue';
import EnigmeCrypto from './components/Enigme10_Crypto.vue';
import EnigmeIS from './components/Enigme6_IS.vue';
import EnigmeHill from './components/Enigme11_Hill.vue';
import EnigmeCarte2 from './components/Enigme12_Carte2.vue';
import CalculatriceVue from './components/Calculatrice.vue';
import enigmeRegle from './components/EnigmeRegle.vue';
import TimeEnigme from './components/TimeEnigme.vue';

import JoinClan from './components/JoinClan.vue';
import ClanDashboard from './components/ClanDashboard.vue';
import EnigmeTelechargement from './components/Enigme13_Telechargement.vue';
import EnigmeWolseley from './components/Enigme15_Wolseley.vue';
import EnigmeRailFence from './components/Enigme16_RailFence.vue';
import EnigmeVigenere from './components/Enigme17_Vigenere.vue';

import EnigmeCodebin from './components/Enigme18_Code.vue';
import EnigmeCodebin2 from './components/Enigme19_Code2.vue';
import EnigmeRSA from './components/Enigme20_RSA.vue';

import EnigmeCoop_Puzzle from './components/EnigmeCoop/EnigmeCoop1_Index.vue';

import SuccesMail from './components/SuccesMail.vue';
import ResetPassword from './components/ResetPassword.vue';

import MentionsLegales from './components/MentionsLegales.vue';
import AdminCV from './components/AdminCV.vue';
import Statistiques from './components/Statistiques.vue';
import MultiModule1 from './components/MultiModule1.vue';

const routes = [
  { path: '/', component: HomePage, meta: { title: 'Kameleon' } },
  { path: '/enigme', component: EnigmePage, meta: { title: 'Enigme' } },
  { path: '/login', component: AppLogin, meta: { title: 'Connexion' } },
  { path: '/sign-in', component: AppSignIn, meta: { title: 'Inscription' } },
  { path: '/contact', component: AppContact, meta: { title: 'Contact' } },
  { path: '/clan', component: ClanPage, meta: { title: 'Clan' } },
  { path: '/clan_login', component: ClanPage_login, meta: { title: 'Clan_login' } },
  { path: '/faq', component: FaqPage, meta: { title: 'FAQ' } },
  { path: '/enigme1', component: EnigmeLabyrinthe, meta: { title: 'Enigme 1' } },
  { path: '/enigme2', component: EnigmeCarte, meta: { title: 'Enigme 2' } },
  { path: '/enigme4', component: EnigmeInverse, meta: { title: 'Enigme 4' } },
  { path: '/enigme5', component: EnigmeImage, meta: { title: 'Enigme 5' } },
  { path: '/enigme8', component: EnigmeOrdinateur, meta: { title: 'Enigme 8' } },
  { path: '/enigme3', component: EnigmeHistoire, meta: { title: 'Enigme 3' } },
  { path : '/enigme7', component: EnigmeScytale, meta: { title: 'Enigme 7' } },
  { path : '/enigme9', component: EnigmeEquation, meta: { title: 'Enigme 9' } },
  { path : '/enigme10', component: EnigmeCrypto, meta: { title: 'Enigme 10' } },
  { path : '/enigme6', component: EnigmeIS, meta: { title: 'Enigme 6' } },
  { path : '/enigme11', component: EnigmeHill, meta:{title:'Enigme 11'}},
  { path: '/enigme12', component: EnigmeCarte2, meta: { title: 'Enigme 12' } },
  { path: '/enigme13', component: EnigmeTelechargement, meta: { title: 'Enigme 13' } },
  { path: '/enigme15', component: EnigmeWolseley, meta: { title: 'Enigme 14' } },
  { path: '/enigme16', component: EnigmeRailFence, meta: { title: 'Enigme 15' } },
  { path: '/enigme17', component: EnigmeVigenere, meta: { title: 'Enigme 16' } },

  { path: '/enigme18', component: EnigmeCodebin, meta: { title: 'Enigme 17' } },
  { path: '/enigme19', component: EnigmeCodebin2, meta: { title: 'Enigme 18' } },
  { path: '/enigme20', component: EnigmeRSA, meta: { title: 'Enigme 19' } },

  { path: '/enigmecoop1', component: EnigmeCoop_Puzzle, meta: { title: 'Enigme Coop Puzzle' } },

  { path: '/versus', component: VersusPage, meta: { title: 'Versus' } },
  { path: '/skilltree/', component: SkillTree, meta: { title: 'Enigme Tree' } },
  { path: '/coop', component: CoopMainMenu, meta: { title: 'Coop' } },
  { path: '/dashboard_login', component: Dashboard_login, meta: { title: 'Dashboard_login' } },
  { path: '/clan_create', component: ClanCreate, meta: { title: 'Clan_create' } },
  { path : '/enigme_indisponible', component: EnigmeIndisponible, meta: { title: 'Enigme Indisponible' } },
  { path: '/calculatrice', component: CalculatriceVue, meta: { title: 'Calculatrice' } },
  { path: '/enigme_regle', component: enigmeRegle, meta: { title: 'Enigme Regle' } },
  { path: '/time_enigme', component: TimeEnigme, meta: { title: 'Time Enigme' } },
  { path: '/join_clan', component: JoinClan, meta: { title: 'JoinClan' } },
  { path: '/clan_dashboard', component: ClanDashboard, meta: { title: 'ClanDashboard' } },

  { path: '/succes_mail', component: SuccesMail, meta: { title: 'SuccesMail' } },
  { path: '/reset_password', component: ResetPassword, meta: { title: 'ResetPassword' } },

  { path: '/mentions_legales', component: MentionsLegales, meta: { title: 'MentionsLegales' } },
  { path: '/multi1', component: MultiModule1, meta: { title: 'MultiModule1' } },
  // { path: '/multi1', component: MultiModule1, meta: { title: 'MultiModule1', hideHeader: true } },


  { path: '/admin_cv', component: AdminCV, meta: { title: 'AdminCV' } },
  { path: '/statistiques', component: Statistiques, meta: { title: 'Statistiques' } },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
