<template>
  <div>
    <div class="calculator-icon" v-if="isMinimized" @click="restoreCalculator">
      <img src="/img/calculator-icon.png" alt="Calculator Icon" />
    </div>
    <div class="calculator-container" ref="calculator" v-else>
      <div class="calculator-header" @mousedown="startDrag" @mouseup="stopDrag" @mousemove="drag" @dragstart.prevent>
        <span>Calculatrice</span>
        <button class="close-button" @click="minimizeCalculator">X</button>
      </div>
      <div class="tabs">
        <button :class="{ active: activeTab === 'calculator' }" @click="activeTab = 'calculator'">Calculatrice</button>
        <button :class="{ active: activeTab === 'tags' }" @click="activeTab = 'tags'">Tags Enregistrés</button>
      </div>
      <div v-if="activeTab === 'calculator'">
        <input class="calculator-display" type="text" :value="currentInput" disabled />

        <div class="buttons-grid">
          <!-- Nombres -->
          <button @click="append('7')">7</button>
          <button @click="append('8')">8</button>
          <button @click="append('9')">9</button>
          <button @click="append('/')">/</button>
          <button @click="clearInput()">C</button>

          <button @click="append('4')">4</button>
          <button @click="append('5')">5</button>
          <button @click="append('6')">6</button>
          <button @click="append('*')">*</button>
          <button @click="append('(')">(</button>

          <button @click="append('1')">1</button>
          <button @click="append('2')">2</button>
          <button @click="append('3')">3</button>
          <button @click="append('-')">-</button>
          <button @click="append(')')">)</button>

          <button @click="append('0')">0</button>
          <button @click="append('.')">.</button>
          <button @click="evaluate()">=</button>
          <button @click="append('+')">+</button>
          <button @click="append(',')">,</button>

          <!-- Fonctions avancées -->
          <button @click="squareRoot()">√</button>
          <button @click="append('mod')">mod</button>
          <button @click="append('PGCD(')">PGCD</button>
          <button @click="append('PPCM(')">PPCM</button>
        </div>

        <!-- Sauvegarde -->
        <div class="save-section">
          <label for="tag">Tag :</label>
          <input id="tag" type="text" v-model="saveTag" placeholder="Saisir un tag" />
          <button @click="saveResult">Sauvegarder le résultat</button>
        </div>
      </div>
      
      <div v-if="activeTab === 'tags'" class="tags-section">
        <h3>Tags Enregistrés</h3>
        <ul>
          <li v-for="(item, index) in savedValues" :key="index">
            {{ item.tag }}: {{ item.value }}
          </li>
        </ul>
      </div>


      <!-- Poignée de redimensionnement -->
      <div class="resize-handle" @mousedown="startResize"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalculatriceVue",
  data() {
    return {
      currentInput: "",
      saveTag: "",
      savedValues: [],
      isDragging: false,
      dragStartX: 0,
      dragStartY: 0,
      initialX: 0,
      initialY: 0,
      isResizing: false,
      resizeStartX: 0,
      resizeStartY: 0,
      initialWidth: 0,
      initialHeight: 0,
      aspectRatio: 1, // Rapport d'aspect initial
      activeTab: 'calculator', // Onglet actif
      isMinimized: false, // État minimisé
    };
  },
  mounted() {
    // Définition des fonctions cryptographiques dans le scope global.
    // Ainsi, elles sont appelables depuis eval().
    window.PGCD = function gcd(a, b) {
      if (!b) return Math.abs(a);
      return window.PGCD(b, a % b);
    };

    window.PPCM = function lcm(a, b) {
      if (a === 0 || b === 0) return 0;
      return Math.abs(a * b) / window.PGCD(a, b);
    };

    window.mod = function mod(a, b) {
      return ((a % b) + b) % b;
    };

    window["Pui.Mod"] = function modPow(base, exp, m) {
      let result = 1;
      let x = base % m;
      let e = exp;

      while (e > 0) {
        if (e % 2 === 1) {
          result = (result * x) % m;
        }
        x = (x * x) % m;
        e = Math.floor(e / 2);
      }
      return result;
    };

    const rect = this.$refs.calculator.getBoundingClientRect();
    this.aspectRatio = rect.width / rect.height;

    document.addEventListener("mousemove", this.drag);
    document.addEventListener("mouseup", this.stopDrag);
    document.addEventListener("mousemove", this.resize);
    document.addEventListener("mouseup", this.stopResize);
  },
  beforeUnmount() {
    document.removeEventListener("mousemove", this.drag);
    document.removeEventListener("mouseup", this.stopDrag);
    document.removeEventListener("mousemove", this.resize);
    document.removeEventListener("mouseup", this.stopResize);
  },
  methods: {
    // Ajoute un caractère au champ de saisie
    append(value) {
      this.currentInput += value;
    },

    // Remet l'expression à zéro
    clearInput() {
      this.currentInput = "";
    },

    // Transforme l'expression pour rendre les fonctions disponibles à eval()
    formatExpression(expression) {
      // 1) Remplacer '^' par '**' (puissance en JS)
      let exp = expression.replace(/\^/g, "**");

      // 2) Remplacer "a mod b" par "mod(a,b)" :
      exp = exp.replace(
        /(\d+)\s+mod\s+(\d+)/g,
        (match, p1, p2) => `mod(${p1},${p2})`
      );

      // 3) Remplacer les labels français par les noms de fonctions globales
      exp = exp.replace(/\bPGCD\(/g, "window.PGCD(");
      exp = exp.replace(/\bPPCM\(/g, "window.PPCM(");
      exp = exp.replace(/\bPui\.Mod\(/g, "window['Pui.Mod'](");

      return exp;
    },

    // Évalue l'expression
    evaluate() {
      try {
        const finalExpression = this.formatExpression(this.currentInput);
        const result = eval(finalExpression);
        this.currentInput = result.toString();
      } catch (error) {
        alert("Expression invalide :\n" + error);
      }
    },

    // Calcule la racine carrée du résultat actuel
    squareRoot() {
      try {
        // On évalue d’abord ce qui est dans l'afficheur
        this.evaluate();
        const val = parseFloat(this.currentInput);
        if (isNaN(val)) {
          throw new Error(
            "Impossible de calculer la racine d’un résultat non numérique."
          );
        }
        this.currentInput = Math.sqrt(val).toString();
      } catch (error) {
        alert("Expression invalide pour la racine carrée :\n" + error);
      }
    },

    // Sauvegarde le résultat actuel avec un tag
    saveResult() {
      if (!this.currentInput) {
        alert("Aucun résultat à sauvegarder.");
        return;
      }

      // Évalue si nécessaire puis stocke le nombre
      try {
        this.evaluate(); // s’assure que currentInput est un résultat numérique
        const numericValue = parseFloat(this.currentInput);

        if (isNaN(numericValue)) {
          throw new Error("Le résultat n'est pas un nombre.");
        }

        this.savedValues.push({
          tag: this.saveTag || "Sans tag",
          value: numericValue,
        });

        // Réinitialise le champ tag
        this.saveTag = "";
      } catch (error) {
        alert("Impossible de sauvegarder ce résultat.\n" + error);
      }
    },

    // Commence le déplacement de la calculatrice
    startDrag(event) {
      this.isDragging = true;
      this.dragStartX = event.clientX;
      this.dragStartY = event.clientY;
      const rect = this.$refs.calculator.getBoundingClientRect();
      this.initialX = rect.left - this.dragStartX;
      this.initialY = rect.top - this.dragStartY;
      document.body.style.userSelect = 'none'; // Empêche la sélection de texte
    },

    // Déplace la calculatrice
    drag(event) {
      if (this.isDragging) {
        const dx = event.clientX + this.initialX;
        const dy = event.clientY + this.initialY;
        this.$refs.calculator.style.left = `${dx}px`;
        this.$refs.calculator.style.top = `${dy}px`;
      }
    },

    // Arrête le déplacement de la calculatrice
    stopDrag() {
      this.isDragging = false;
      document.body.style.userSelect = ''; // Réactive la sélection de texte
    },

    // Commence le redimensionnement de la calculatrice
    startResize(event) {
      this.isResizing = true;
      this.resizeStartX = event.clientX;
      this.resizeStartY = event.clientY;
      const rect = this.$refs.calculator.getBoundingClientRect();
      this.initialWidth = rect.width;
      this.initialHeight = rect.height;
    },

    // Redimensionne la calculatrice proportionnellement
    resize(event) {
      if (this.isResizing) {
        const dx = event.clientX - this.resizeStartX;
        const newWidth = Math.min(Math.max(this.initialWidth + dx, 300), 800); // Limites min et max
        const newHeight = newWidth / this.aspectRatio;
        this.$refs.calculator.style.width = `${newWidth}px`;
        this.$refs.calculator.style.height = `${newHeight}px`;
        this.$refs.calculator.style.fontSize = `${newWidth / 25}px`; // Ajuste la taille de la police proportionnellement
      }
    },

    // Arrête le redimensionnement de la calculatrice
    stopResize() {
      this.isResizing = false;
    },

    // Minimise la calculatrice
    minimizeCalculator() {
      this.isMinimized = true;
    },

    // Restaure la calculatrice
    restoreCalculator() {
      this.isMinimized = false;
    },
  },
};
</script>

<style src="../assets/calculatrice.css"></style>
