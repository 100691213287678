<template>
  <div class="coop-chat">
    <h2>Chat</h2>
    <div class="coop-messages">
      <div 
        v-for="(msg, index) in messages" 
        :key="index"
        class="coop-message-line"
      >
        <span class="coop-username">{{ msg.username }}:</span>
        <span class="coop-text">{{ msg.body }}</span>
      </div>
    </div>
    <input
      v-model="newMessage"
      placeholder="Écrivez votre message..."
      @keydown.enter="sendMessage"
      class="coop-input"
    />
    <button @click="sendMessage" class="coop-button">Envoyer</button>
  </div>
</template>

<script>
export default {
  name: "ChatBox",
  
  props: {
    // Exemple: on rend l'URL WebSocket configurable depuis l'extérieur
    wsUrl: {
      type: String,
      default: "wss://kameleonback.jrcan.dev/ws/chat/",
    },
    // Idem pour éventuellement un username par défaut
    defaultUsername: {
      type: String,
      default: "Anonyme",
    },
  },

  data() {
    return {
      socket: null,
      messages: [], // Liste des messages reçus
      newMessage: "", // Message à envoyer
    };
  },

  methods: {
    connectToChat() {
      // Utilise la prop `wsUrl` pour établir la connexion
      this.socket = new WebSocket(this.wsUrl);

      this.socket.onopen = () => {
        console.log("WebSocket connection opened");
      };

      this.socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // On suppose que le serveur envoie { username, body } 
        // ou qu’il faut adapter selon la structure renvoyée.
        this.messages.push({
          username: data.username,
          body: data.body,
        });
      };

      this.socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };

      this.socket.onclose = () => {
        console.log("WebSocket connection closed");
      };
    },

    sendMessage() {
      if (this.socket && this.newMessage.trim()) {
        // Récupérer un userInfo localStorage si nécessaire
        const userData = JSON.parse(localStorage.getItem("userInfo"));

        this.socket.send(
          JSON.stringify({
            username: userData?.username || this.defaultUsername,
            message: this.newMessage,
          })
        );
        this.newMessage = "";
      }
    },
  },

  mounted() {
    // Établir la connexion WebSocket dès que le composant est monté
    this.connectToChat();
  },

  beforeUnmount() {
    if (this.socket) {
      this.socket.close();
    }
  },
};
</script>

<style scoped>
.coop-chat {
  width: 400px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;       /* Fond légèrement gris */
  border-radius: 8px;             /* Coins arrondis */
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Légère ombre */
}

.coop-chat h2 {
  margin-top: 0;
  color: #444;
  font-size: 1.4rem;
  text-align: center;
}

.coop-messages {
  background-color: #fff;         /* Fond blanc */
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  height: 220px;
  overflow-y: auto;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.coop-message-line {
  margin-bottom: 8px;
}

.coop-username {
  color: #007BFF;    /* Bleu pour le pseudo */
  font-weight: bold;
  margin-right: 5px;
}

.coop-text {
  color: #333;
}

.coop-input {
  width: 70%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.coop-button {
  padding: 8px 15px;
  margin-left: 5px;
  background-color: #007BFF; /* Bouton bleu */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.coop-button:hover {
  background-color: #0056b3; /* Couleur hover */
}
</style>
