<template>
  <div class="multi1-enigme-container">
    <h1>Énigme en 3 modules (tous visibles)</h1>
    <p class="multi1-intro">
      3 modules vous attendent, complétez-les dans l'ordre souhaité. 
      Votre objectif est de renseigner les paramètres de RSA, 
      résoudre la suite géométrique, puis effectuer une addition finale.
    </p>

    <!-- MODULE 1 : Paramètres du chiffre de RSA -->
    <div :class="['multi1-module-RSA', { validatedmulti1: isRSAValid }]">
      <h2>Module 1 : Paramètres RSA</h2>

      <div class="multi1-RSA-params">
        <label>p :</label>
        <input type="number" v-model.number="p" class="multi1-param-input" />

        <label>q :</label>
        <input type="number" v-model.number="q" class="multi1-param-input" />

        <label>n :</label>
        <input type="number" v-model.number="n" class="multi1-param-input" />

        <label>phi(n) :</label>
        <input type="number" v-model.number="phi" class="multi1-param-input" />

        <label>e :</label>
        <input type="number" v-model.number="e" class="multi1-param-input" />

        <label>d :</label>
        <input type="number" v-model.number="d" class="multi1-param-input" />
        <br><br>
        <button @click="validateRSAParams" class="multi1-action-button">
          Valider
        </button>
      </div>
      <p class="multi1-message" v-if="RSAMessage">{{ RSAMessage }}</p>
    </div>

    <!-- MODULE 2 : Suite géométrique -->
    <div :class="['multi1-module-geom', { validatedmulti1: isGeomValid }]">
      <h2>Module 2 : Suite géométrique</h2>
      <p>
        "Considérons une suite définie par : 
        n0 = 4 et n+1 = 2n². 
        Calculez n3."
      </p>
      <label>Valeur de n3 :</label>
      <input type="number" v-model.number="n3" class="multi1-geom-input" />
      <button @click="validateGeometric" class="multi1-action-button">
        Valider
      </button>
      <p class="multi1-message" v-if="geomMessage">{{ geomMessage }}</p>
    </div>

    <!-- MODULE 3 : Addition des 2 valeurs aboutissant à une suite de nombres -->
    <div :class="['multi1-module-addition', { validatedmulti1: isAdditionValid }]">
      <h2>Module 3 : Addition</h2>
      <p>
        Afin de coder votre message "Lasagne", prenez les valeurs des lettres, utilisez RSA sur chacune d'elle puis ajoutez y n3."
      </p>
      <label>Résultat (n + n3) :</label>
      <input type="number" v-model.number="additionValue" class="multi1-addition-input" />
      <button @click="validateAddition" class="multi1-action-button">
        Valider la suite
      </button>
      <p class="multi1-message" v-if="additionMessage">{{ additionMessage }}</p>
    </div>
  </div>
</template>
  
  <script>
  function isPrime(n) {
    if (n < 2) return false;
    for (let i = 2; i <= Math.sqrt(n); i++) {
      if (n % i === 0) return false;
    }
    return true;
  }
  export default {
    name: "MultiModule1",
    data() {
      return {
        // MODULE 1 (RSA)
        p: null,
        q: null,
        e: null,
        phi: null,
        n: null,
        d: null,
        RSAMessage: "",
        isRSAValid: false,
  
        // MODULE 2 (Suite géométrique)
        n3: null,
        geomMessage: "",
        isGeomValid: false,
  
        // MODULE 3 (Addition)
        additionValue: null,
        additionMessage: "",
        isAdditionValid: false,
      };
    },
    methods: {
      // MODULE 1: Valider RSA
      validateRSAParams() {
        if (!this.p || !this.q || !this.e || !this.phi || !this.n || !this.d) {
          this.RSAMessage = "Veuillez renseigner p, q, e, phi(n), n, d.";
          return;
        }
        // Vérifier p,q premiers
        if (!isPrime(this.p) || !isPrime(this.q)) {
          this.RSAMessage = "p et q doivent être premiers.";
          return;
        }
        // Vérifier n = p×q
        if (this.n !== this.p * this.q) {
          this.RSAMessage = "n n'est pas égal à p×q !";
          return;
        }
        // Vérifier phi(n) = (p-1)*(q-1)
        if (this.phi !== (this.p - 1)*(this.q - 1)) {
          this.RSAMessage = "phi(n) n'est pas (p-1)*(q-1) !";
          return;
        }
        // Vérifier (e×d) % phi(n) = 1
        const modVal = (this.e * this.d) % this.phi;
        if (modVal !== 1) {
          this.RSAMessage = "e×d n’est pas ≡ 1 mod φ(n).";
          return;
        }
        this.RSAMessage = "Paramètres RSA validés !";
        this.isRSAValid = true;
      },
  
      // MODULE 2: Suite géométrique
      validateGeometric() {
        // Suite : a0 = 1, a_{n+1} = 2 * a_n^2
        // On attend par ex. n3
        // n1 = 2 * 1^2 = 2
        // n2 = 2 * 2^2 = 8
        // n3 = 2 * 8^2 = 128
        if (this.n3 === null) {
          this.geomMessage = "Veuillez renseigner n3.";
          return;
        }
        if (this.n3 === 128) {
          this.geomMessage = "Suite validée, n3=128 !";
          this.isGeomValid = true; 
        } else {
          this.geomMessage = "Valeur incorrecte, réessayez.";
        }
      },
  
      // MODULE 3: Valider l'addition
      validateAddition() {
        if (!this.additionValue) {
          this.additionMessage = "Veuillez saisir un nombre.";
          return;
        }
        // Suppose qu'on attend 1234
        if (this.additionValue === 1234) {
          this.additionMessage = "Suite validée, Bravo !";
          this.isAdditionValid = true;
        } else {
          this.additionMessage = "Suite incorrecte, réessayez.";
        }
      },
    },
  };
  </script>

<style src="../assets/multimodule1.css"></style>