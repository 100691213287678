<template>
    <div class="development-container-enigme">
        <div class="development-content-enigme">
        <h1>Page <span>Indisponible</span></h1>
        <p>Cette page est actuellement <span class="highligh-green">interdite d'accès</span>.</p>
        <p>Résolvez les énigmes précédentes afin de déverrouiller cette page.</p>
        <div class="loader-cross"></div>
        </div>
    </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style src="../assets/enigmeIndisponible.css"></style>