<template>
  <CalculatriceVue v-if="calculatrice" />
  <div class="header-container">
    <button @click="goBack" class="bouton-gauche">Retour</button>
    <button @click="goToRules" class="bouton-gauche">Règles</button>
  </div>
  <div class="enigme16-puzzle-container">
    <!-- Énoncé -->
    <h2 class="enigme16-enonce-section">Énigme N°15</h2>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <!-- Conteneur flex pour les sections de téléchargement et d'indices -->
    <div class="enigme16-columns">
      <!-- Bouton pour télécharger un fichier -->
      <div class="enigme16-download-section">
        <p class="enigme16-enonce">La palisade cryptographique n'attend que vous.</p>
        <button @click="downloadFile" class="enigme16-download-button">
          Télécharger script_enigme16.py
        </button>
      </div>

      <div class="hint-section">
        <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
        <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
        <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
      </div>

      <!-- Afficher les indices demandés -->
      <div class="hints">
        <p v-if="hintsText">{{ hintsText }}</p>
      </div>

    <!-- Champ de réponse -->
    <div class="enigme16-answer-row">
      <h3 class="enigme16-answer">Votre réponse :</h3>
      <input 
        type="text" 
        v-model="userAnswer"
        placeholder="Entrez ici la solution déchiffrée"
        class="enigme16-answer-input"
      />
      <button @click="validateAnswer" class="enigme16-validate-button">
        Valider
      </button>
    </div>

    <!-- Message de validation finale -->
    <div class="enigme16-validation-message" v-if="message">
      <p>{{ message }}</p>
    </div>
  </div>
  <div class="quote-box">
    <p>" Après avoir bien réfléchi, vous aimez bien résoudre ces énigmes, peut-être qu’un jour cela vous donnera une belle opportunité     "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import UserServices from "../services/UserServices";
import CalculatriceVue from "./Calculatrice.vue";
export default {
  name: "EnigmeRailFence",
  components: {
    CalculatriceVue,
  },
  data() {
    return {
      completedEnigmas: 0,
      fileUrl: "/scripts/script_enigme16.py",

      userAnswer: "",
      expectedAnswer: "RAILFENCE",
      hints: {
        1: false,
        2: false,
        3: false,
      },
      hintsText: "",
      message: "",
      calculatrice: false,
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.haveCalculatrice();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(15);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(15, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(15, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(15, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(15, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    downloadFile() {
      const link = document.createElement("a");
      link.href = this.fileUrl;
      link.download = "script_enigme16.py";
      link.click();
    },
    async haveCalculatrice() {
      try {
        const response = await UserServices.haveCalculatrice();
        if (response) {
          console.log("calculatrice");
          this.calculatrice = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la calculatrice :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(15, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 15);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },
    async validateAnswer() {
      const userUp = this.userAnswer.toUpperCase().trim();
      try {
        const response = await riddleServices.IsRiddleSolved(15, userUp);
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style src="../assets/enigmerailfence.css"></style>