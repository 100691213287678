import axios from 'axios';

export default {

    getCV() {
        const accessToken = localStorage.getItem("accessToken");
        return axios.get(`https://kameleonback.jrcan.dev/api/cv/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },

    uploadCV(file) {
        const accessToken = localStorage.getItem("accessToken");
        const formData = new FormData();
        formData.append("cv", file);
      
        return axios.post("https://kameleonback.jrcan.dev/api/cv/upload/", formData, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        });
      }

}