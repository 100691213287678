<template> 
  <div class="titre_clan">
      <h2>Création du clan</h2>
      <p>Vous êtes sur le point de créer votre clan. Veillez à bien remplir ce formulaire afin d’éviter tout problème à l’avenir.</p>
  </div>
  <div class="creation_clan">
      <div class="gauche_clan">
          <form @submit.prevent="creerClan" class="form_clan">
              <p>Nom du Clan</p>
              <input
                  type="text"
                  v-model="nomClan"
                  placeholder="Entrez votre Nom de Clan..."
                  required
              />
  
              <p>Description du Clan</p>
              <textarea
                  v-model="descriptionClan"
                  placeholder="Description de votre clan"
                  required
              ></textarea>
  
              <p>Nombre de participants maximum</p>
              <div class="slider-container">
                  <input
                      type="range"
                      v-model="nombreMaxParticipants"
                      min="2"
                      max="10"
                      step="1"
                      class="slider"
                  />
                  <span class="nb_participants">{{ nombreMaxParticipants }}</span>
              </div>
          </form>
      </div>
      <div class="droite_clan">
          <form>
              <p>Photo de Clan</p>
              <div class="photo-container">
                  <input type="file" id="file-input" @change="previewImage" accept="image/*" />
                  <label for="file-input" class="file-label">Choisir une photo</label>
                  <div class="preview">
                      <img
                          v-if="imagePreview"
                          :src="imagePreview"
                          alt="Prévisualisation"
                      />
                  </div>
              </div>
  
              <button class="button_clan" @click="createClan">Créer</button>
          </form>
      </div>
  </div>
</template>

<script>
import clanService from "../services/ClanServices";

export default {
  data() {
    return {
      nomClan: "",
      descriptionClan: "",
      nombreMaxParticipants: 10,
      imagePreview: null,
      imageFile: null,
    };
  },
  methods: {
    previewImage(event) {
      const file = event.target.files[0];
      if (file) {
        this.imageFile = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async createClan() {
      if (!this.nomClan || !this.descriptionClan) {
        alert("Veuillez remplir tous les champs obligatoires.");
        return;
      }

      const formData = new FormData();
      formData.append("clan_name", this.nomClan);
      formData.append("clan_bio", this.descriptionClan);
      formData.append("clan_members_max_count", this.nombreMaxParticipants);
      if (this.imageFile) {
        formData.append("clan_pci", this.imageFile);
      }

      try {
        const response = await clanService.createClan(formData);
        alert(`Clan créé avec succès : ${response.data.clan_name}`);
        this.$router.push("/clan_dashboard");
      } catch (error) {
        console.error(error);
        alert("Une erreur est survenue lors de la création du clan.");
      }
    },
  },
};
</script>

<style src="../assets/clan_create.css"></style>
