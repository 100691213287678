<template>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="enigme-10-quiz-puzzle-container">
    <h1>Énigme N°6</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
        <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
        <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
      </p>

    <!-- Affichage du quiz -->
    <div v-for="(question, qIndex) in questions" :key="qIndex" class="enigme-10-question-block">
      <h4>Question {{ qIndex + 1 }}</h4>
      <p>{{ question.text }}</p>

      <!-- Affichage des 3 réponses -->
      <div
        v-for="(answer, aIndex) in question.answers"
        :key="aIndex"
        class="enigme-10-answer-choice"
      >
        <input
          type="radio"
          :name="'question_' + qIndex"
          :id="'q'+qIndex+'_a'+aIndex"
          :value="aIndex"
          v-model="userAnswers[qIndex]"
        />
        <label :for="'q'+qIndex+'_a'+aIndex">{{ answer.text }}</label>
      </div>
    </div>

    <!-- Indice (révélé seulement si questionIndex dans revealedIndices) -->
    <!-- Boutons pour demander les indices -->
    <div class="enigme-10-hint-block">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
      <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
    </div>

    <!-- Afficher les indices demandés -->
    <div class="hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>

    <!-- Bouton de validation -->
    <div class="enigme-10-validate-section">
      <button @click="submitQuiz" class="enigme-10-submit-button">Valider mes réponses</button>
    </div>

    

    <!-- Résultat de la tentative -->
    <div v-if="feedback" class="enigme-10-result-block">
      <p>{{ feedback }}</p>
    </div>
    <div class="quote-box">
      <p>" Après une longue discussion, le Chef vous apprend que votre famille s’est toujours intéressée aux énigmes et qu’il n’est donc pas un hasard si il en restait dans cette vieille maison. Aussi, un club secret de résolution traîne en ville pourquoi ne pas tenter de les rejoindre ?

Pendant cette discussion qui vous à laissé avec plus de questions que de réponses, vous avez remarquez une petite porte intrigante et voulez absolument voir ce qu’il y a derrière.
"</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeIS",
  data() {
    return {
      // Exemple de données
      questions: [
        {
          text: "Quelle heure ?",
          answers: [
            { text: "14h" },
            { text: "3h" },
            { text: "Minuit" },
          ],
        },
        {
          text: "Comment laisser le moins de traces possibles",
          answers: [
            { text: "Eviter de toucher inutilement des objets" },
            { text: "Mettre des gants " },
            { text: "Il n'y en a pas" },
          ],
        },
        {
          text: "Comment ouvrir la porte ?",
          answers: [
            { text: "Forcer la serrure" },
            { text: "Imiter la clef" },
            { text: "Casser une fenêtre" },
          ],
        }
      ],
      hints: { 1: false, 2: false, 3: false },
      hintsText: "",
      // Stockage des réponses de l'utilisateur
      userAnswers: [],
      // Indices déjà révélés (index des questions)
      revealedIndices: [],
      // Nombre de tentatives
      attemptCount: 0,
      // Indicateur si l'utilisateur a déjà validé au moins une fois
      attempted: false,
      // Nombre d'erreurs dans la tentative en cours
      errorCount: 0,
      // Indicateur pour savoir si toutes les réponses sont correctes
      allCorrect: false,
      feedback: "",
      errors_count: 0,
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(6);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(6, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(6, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(6, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(6, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 6);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
        console.error("Erreur lors de la vérification de l'énigme :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(6, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async submitQuizAndValidate() {
    this.attemptCount++;
    this.attempted = true;

    // Construire le résultat au format attendu
    const userResponse = this.questions.reduce((acc, question, index) => {
      acc[(index + 1).toString()] = parseInt(this.userAnswers[index]);
      return acc;
    }, {});

    console.log("User Response:", userResponse); // Debug : Vérifiez l'objet construit

    try {
      const response = await riddleServices.IsRiddleSolved(6, userResponse);
      if (response.is_solved) {
        this.feedback = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
        this.isPasswordIncorrect = false;
        this.markSolved();
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.$router.push('/skilltree');
      } else {
        this.feedback = "Erreur ! Essayez de nouveau.";
        this.isPasswordIncorrect = true;
        this.incrementErrors();
        this.errors_count += 1;
      }
    } catch (error) {
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
  },

  // Reste des méthodes inchangées
  submitQuiz() {
    this.submitQuizAndValidate(); // Appelez la nouvelle méthode
  },
},
  mounted() {
    console.log("Page mounted successfully.");
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
    this.userAnswers = new Array(this.questions.length).fill(null);
  },
};
</script>

<style src="../assets/enigmeIS.css"></style>
