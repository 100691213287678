<template>
    <div class="succes-container">
        <div class="succes-content">
            <h1>Réinitialisation du Mot de Passe</h1>
            <p>Veuillez entrer votre <span class="highlight-purple">nouveau mot de passe</span> ci-dessous.</p>
            <form class="succes-form" @submit.prevent="updatePassword">
                <p>Nouveau Mot de Passe</p>
                <input
                    type="password"
                    v-model="newPassword"
                    placeholder="Entrez votre nouveau mot de passe..."
                    required
                />
                <p>Confirmer Mot de Passe</p>
                <input
                    type="password"
                    v-model="confirmPassword"
                    placeholder="Confirmez votre nouveau mot de passe..."
                    required
                />
                <br />
                <input type="submit" value="Valider" class="button_reset" />
            </form>
            <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
            <p v-if="successMessage" class="success">{{ successMessage }}</p>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: "ResetPassword",
    data() {
        return {
            newPassword: '',
            confirmPassword: '',
            errorMessage: '',
            successMessage: '',
            uid: '',
            token: '',
        };
    },
    methods: {
        async updatePassword() {
            // Vérifier si les mots de passe correspondent
            if (this.newPassword !== this.confirmPassword) {
                this.errorMessage = "Les mots de passe ne correspondent pas.";
                this.successMessage = '';
                return;
            }

            // Vérifier la longueur du mot de passe
            if (this.newPassword.length < 8) {
                this.errorMessage = "Le mot de passe doit contenir au moins 8 caractères.";
                this.successMessage = '';
                return;
            }

            try {
                // Envoyer la requête de réinitialisation au backend
                const response = await axios.post('https://kameleonback.jrcan.dev/password-reset-confirm/', {
                    uid: this.uid,
                    token: this.token,
                    new_password: this.newPassword,
                });

                console.log(response.data);
                this.successMessage = 'Mot de passe réinitialisé avec succès. Vous pouvez maintenant vous connecter.';
                this.errorMessage = '';

                // Rediriger vers la page de connexion après un court délai
                setTimeout(() => {
                    this.$router.push('/login');
                }, 3000);
            } catch (error) {
                this.errorMessage = error.response?.data?.error || "Une erreur s'est produite lors de la réinitialisation du mot de passe.";
                this.successMessage = '';
            }
        },
    },
    mounted() {
        // Récupérer les paramètres uid et token depuis l'URL
        const urlParams = new URLSearchParams(window.location.search);
        this.uid = urlParams.get('uid');
        this.token = urlParams.get('token');

        if (!this.uid || !this.token) {
            this.errorMessage = "Lien de réinitialisation invalide.";
        }
    },
};
</script>

<style src="../assets/resetpassword.css"></style>