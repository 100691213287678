<template>
    <div class="puzzle-container">
      <!-- Titre étape (optionnel) -->
      <h3>Étape 1</h3>
  
      <!-- Afficher l'image de l'énigme -->
      <div class="image-section">
        <img :src="imageSource" alt="Enigme" class="puzzle-image" />
        <div class="image-caption">
          <p><strong>Vercingétorix jette ses armes aux pieds de César</strong></p>
          <p>Peint par Lionel Royer, 1899</p>
          <p>Musée Crozatier, Le Puy-en-Velay, France</p>
        </div>
      </div>
  
      <!-- Table de substitution toujours visible -->
      <div class="substitution-table">
        <h3>Table de substitution</h3>
        <div class="table">
          <div
            v-for="(letter, index) in substitutionTable"
            :key="index"
            class="table-item"
          >
            {{ letter }} = {{ index + 1 }}
          </div>
        </div>
      </div>
  
      <!-- Afficher le texte crypté -->
      <div class="text-section">
        <p>{{ cryptedText }}</p>
      </div>
  
      <!-- Saisie de la réponse -->
      <div class="input-section">
        <input
          type="text"
          v-model="userInput"
          placeholder="Votre réponse..."
          @keyup.enter="validateAnswer"
        />
        <button @click="validateAnswer">Valider</button>
      </div>
  
      <!-- Boutons pour demander les indices -->
      <div class="hint-section">
        <button
          :disabled="false"
          @click="showHint(1)"
          :class="{ disabled: false }"
        >
          Indice 1
        </button>
        <button
          :disabled="!hints[1]"
          @click="showHint(2)"
          :class="{ disabled: !hints[1] }"
        >
          Indice 2
        </button>
        <button
          :disabled="!hints[2]"
          @click="showHint(3)"
          :class="{ disabled: !hints[2] }"
        >
          Indice 3
        </button>
      </div>
  
      <!-- Afficher les indices demandés -->
      <div class="hints">
        <p v-if="hints[1]">1: Le décalage est dissimulé dans la légende du tableau</p>
        <p v-if="hints[2]">2: Un calcul est nécessaire selon le nombre de caractères dans la table de substitution</p>
        <p v-if="hints[3]">3: 1899%30</p>
      </div>
  
      <!-- Afficher le feedback -->
      <div v-if="feedback" class="feedback">
        <p>{{ feedback }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Step_1",
    data() {
      return {
        imageSource: "/img/vercingetorix_jette_ses_armes_aux_pieds_de_cesar.png",
        cryptedText: ".JUJMN LN.J,", 
        userInput: "",
        feedback: "",
        substitutionTable: "ABCDEFGHIJKLMNOPQRSTUVWXYZ,.?!".split(""),
        correctAnswer: "SALADE CESAR", // Réponse en dur pour l'étape 1
        hints: { 1: false, 2: false, 3: false },
      };
    },
    methods: {
      validateAnswer() {
        // Vérifier si la réponse est correcte (ignorer la casse)
        if (this.userInput.trim().toUpperCase() === this.correctAnswer) {
          this.feedback = "Bravo ! Vous avez trouvé la réponse.";
          // Émettre l'événement 'solvedStep1' vers le parent en lui passant 
          // éventuellement un objet ou la réponse trouvée
          this.$emit("solvedStep1", {
            step1Answer: this.correctAnswer,
            message: "Étape 1 résolue !",
          });
        } else {
          this.feedback = "Erreur ! Réessayez.";
        }
      },
      showHint(hintNumber) {
        this.hints[hintNumber] = true;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Vous pouvez reprendre les mêmes styles que dans votre exemple */
  .puzzle-container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 600px;
  }
  .image-section img {
    max-width: 10%;
    height: auto;
    border: 2px solid #000;
    margin-bottom: 10px;
  }
  .image-caption {
    margin-top: 10px;
    background-color: #000;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 14px;
    color: #fff; /* Texte en blanc pour contraste */
    font-style: italic;
    border-radius: 5px;
    text-align: left;
  }
  .image-caption p {
    margin: 5px 0;
  }
  .substitution-table {
    margin-top: 20px;
    font-family: Arial, sans-serif;
  }
  .substitution-table h3 {
    margin-bottom: 10px;
  }
  .table {
    display: grid;
    grid-template-columns: repeat(13, auto);
    gap: 10px;
  }
  .table-item {
    padding: 5px;
    background-color: #f0f0f0;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .text-section p {
    font-size: 24px;
    font-weight: bold;
    margin: 10px 0;
  }
  .input-section input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
  }
  .input-section button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
  .hint-section button {
    padding: 8px 12px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
  .hint-section button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .hint-section button:not(.disabled) {
    background-color: #f39c12;
  }
  .hints {
    margin-top: 20px;
    font-size: 16px;
    font-style: italic;
  }
  .feedback {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  </style>
  