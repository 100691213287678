<template>
  <div>
    <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <h1 id="titre_enigme_1">Énigme N°1</h1>
    
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    
        
    <div class="test-container">
      <div :class="['maze-container', { 'slide-left': inputVisible }]">
        <section class="controls">
          <span>Z</span>
          <span>Q</span>
          <span>S</span>
          <span>D</span>
        </section>
        <canvas ref="gameCanvas" width="780" height="540" tabindex="0"></canvas>
      </div>
      <div :class="['password-section', { 'visible': inputVisible }]">
        <p>Vous avez trouvé le coffre ! Entrez le mot de passe.</p>
        <div class="terminal-input">
          <div class="input-wrapper">
            <input ref="passwordInput" type="text" v-model="userPassword" placeholder="Votre réponse..." @keyup.enter="validatePassword" />
          </div>
        </div>
        <button @click="validatePassword">Valider</button>
        <div v-if="feedback" :class="['feedback', { 'error': isPasswordIncorrect }]">{{ feedback }}</div>
        <div v-if="tableVisible" class="substitution-table">
          <h3>Table de substitution</h3>
          <div class="table">
            <div v-for="(letter, index) in substitutionTable" :key="index" class="table-item">
              {{ letter }} = {{ index + 1 }}
            </div>
          </div>
        </div>

        <!-- Boutons pour demander les indices -->
        <div class="hint-section">
          <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
          <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
          <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
        </div>

        <!-- Afficher les indices demandés -->
        <div class="hints">
          <p v-if="hintsText">{{ hintsText }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeLabyrinthe",
  data() {
    return {
      maze: [
        [1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, ],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
        [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, ],
        [1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, ],
        [1, 1, 1, 0, 1, 1, 1, 1, 1, 0, 1, 0, 1, ],
        [0, 0, 1, 0, 1, 0, 0, 0, 1, 1, 1, 0, 1],
        [0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 1],
        [0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1],
      ],
      key: {
        x: 0,
        y: 0,
      },
      cellSize: 60,
      inputVisible: false,
      tableVisible: false,
      userPassword: "",
      currentRiddleId: 1,
      feedback: "",
      isPasswordIncorrect: false,
      keyImage: null,
      substitutionTable: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split(""),
      hints: { 1: false, 2: false, 3: false },
      hintsText: "",
      errors_count: 0,
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  watch: {
    inputVisible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.passwordInput.focus();
          }, 500);
        });
      }
    }
  },
  mounted() {
    this.isRiddleLocked().then(() => {
      this.loadKeyImage();
      this.loadChestImage();
      this.drawMaze();
      this.createStatsForRiddle();
      this.incrementTries();
      this.fetchRiddlesInfo();
      const canvas = this.$refs.gameCanvas;
      canvas.focus();
      canvas.addEventListener("keydown", this.handleKeyDown);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 1);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
    } catch (error) {
      const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
  },
  loadKeyImage() {
    this.keyImage = new Image();
    this.keyImage.src = "/img/key.jpg";
    this.keyImage.onload = () => {
      this.drawMaze();
    };
  },
  loadChestImage() {
    this.chestImage = new Image();
    this.chestImage.src = "/img/coffre.png";
    this.chestImage.onload = () => {
      this.drawMaze();
    };
  },
    drawMaze() {
      const canvas = this.$refs.gameCanvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      for (let row = 0; row < this.maze.length; row++) {
        for (let col = 0; col < this.maze[row].length; col++) {
          if (this.maze[row][col] === 0) {
            ctx.fillStyle = "#000";
          } else {
            ctx.fillStyle = "#fff";
          }
          ctx.fillRect(col * this.cellSize, row * this.cellSize, this.cellSize, this.cellSize);
          ctx.strokeStyle = "#ddd";
          ctx.strokeRect(col * this.cellSize, row * this.cellSize, this.cellSize, this.cellSize);
        }
      }
      if (this.keyImage) {
        ctx.drawImage(
          this.keyImage,
          this.key.x * this.cellSize + 10,
          this.key.y * this.cellSize + 10,
          this.cellSize - 20,
          this.cellSize - 20
        );
      }
      if (this.chestImage) {
        ctx.drawImage(
          this.chestImage,
          6 * this.cellSize + 10,
          8 * this.cellSize + 10,
          this.cellSize - 20,
          this.cellSize - 20
        );
      }
    },
    handleKeyDown(event) {
      let newX = this.key.x;
      let newY = this.key.y;
      if (event.key === "z") {
        newY = Math.max(0, this.key.y - 1);
      } else if (event.key === "s") {
        newY = Math.min(this.maze.length - 1, this.key.y + 1);
      } else if (event.key === "q") {
        newX = Math.max(0, this.key.x - 1);
      } else if (event.key === "d") {
        newX = Math.min(this.maze[0].length - 1, this.key.x + 1);
      }
      if (this.maze[newY][newX] === 1) {
        this.key.x = newX;
        this.key.y = newY;
        this.drawMaze();
        if (this.key.x === 6 && this.key.y === 8) {
          this.inputVisible = true;
          this.tableVisible = true;
        } else {
          this.feedback = "";
        }
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(this.currentRiddleId, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(this.currentRiddleId);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async fetchRiddlesStats() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchRiddleStats(this.currentRiddleId, username);
        this.errors_count = response.errors_count;
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(this.currentRiddleId, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(this.currentRiddleId, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(this.currentRiddleId, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async validatePassword() {
      try {
        // Get the response data
        const data = await riddleServices.IsRiddleSolved(this.currentRiddleId, this.userPassword.toUpperCase());
        if (data.is_solved) {
          this.feedback = "Bravo ! Vous avez résolu l'énigme ! Rédirection dans 2 secondes..."; // Send feedback
          this.isPasswordIncorrect = false;
          await this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.feedback = "Mauvaise réponse. Réessayez !"; // Send feedback
          this.isPasswordIncorrect = true;
          await this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Une erreur est survenue. Veuillez réessayer.");
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(this.currentRiddleId, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
  },
};
</script>

<style src="../assets/enigmelabyrinthe.css"></style> 