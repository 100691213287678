<template>
    <div class="puzzle-container">
      <h3>Étape 2</h3>
      
      <!-- Exemple : on affiche la réponse de l’étape 1 pour montrer qu’on l’a bien récupérée -->
      <p><strong>Résultat de l’étape 1 :</strong> {{ dataFromStep1 }} <br>réponse de l'étape2: AAAH</p>
  
      <!-- Ici, vous placez la nouvelle énigme -->
      <div class="text-section">
        <p>Nouvelle énigme : "Quel est le cri de la carotte ?"</p>
      </div>
  
      <!-- Saisie de la réponse -->
      <div class="input-section">
        <input
          type="text"
          v-model="userInput"
          placeholder="Votre réponse..."
          @keyup.enter="validateAnswer"
        />
        <button @click="validateAnswer">Valider</button>
      </div>
  
      <!-- Indices -->
      <div class="hint-section">
        <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">
          Indice 1
        </button>
        <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">
          Indice 2
        </button>
      </div>
  
      <div class="hints">
        <p v-if="hints[1]">1: Pensez aux onomatopées</p>
        <p v-if="hints[2]">2: Carotte -> ... ?</p>
      </div>
  
      <!-- Feedback -->
      <div v-if="feedback" class="feedback">
        <p>{{ feedback }}</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "Step_2",
    props: {
      // On récupère la donnée de l'étape 1
      dataFromStep1: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        userInput: "",
        feedback: "",
        correctAnswer: "AAAH", // Réponse en dur pour l'étape 2
        hints: { 1: false, 2: false },
      };
    },
    methods: {
      validateAnswer() {
        if (this.userInput.trim().toUpperCase() === this.correctAnswer) {
          this.feedback = "Bravo ! Vous avez trouvé la réponse de l'étape 2.";
          // On émet l’événement pour signaler la fin de l’étape
          this.$emit("solvedStep2", {
            step2Answer: this.correctAnswer,
            message: "Étape 2 résolue !",
          });
        } else {
          this.feedback = "Erreur ! Réessayez.";
        }
      },
      showHint(hintNumber) {
        this.hints[hintNumber] = true;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Styles inspirés du même modèle */
  .puzzle-container {
    text-align: center;
    font-family: Arial, sans-serif;
    margin: 20px auto;
    max-width: 600px;
  }
  .text-section p {
    font-size: 18px;
    margin: 10px 0;
  }
  .input-section input {
    padding: 10px;
    font-size: 16px;
    margin-right: 10px;
  }
  .input-section button {
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
  }
  .hint-section button {
    padding: 8px 12px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #fff;
  }
  .hint-section button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .hint-section button:not(.disabled) {
    background-color: #f39c12;
  }
  .feedback {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
  }
  </style>
  