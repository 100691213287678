<template>
    <div class="join-clan">
        <h2>Rejoindre un <span class="highlight-green">clan</span></h2>
        
        <div class="search-bar">
            <input
                v-model="searchName"
                placeholder="Rechercher un clan par nom"
                class="search-input"
            />
            <select v-model="filterSize" class="search-select">
                <option value="">Tous les clans</option>
                <option value="petit">Petit (1-10)</option>
                <option value="moyen">Moyen (11-50)</option>
                <option value="grand">Grand (50+)</option>
            </select>
        </div>

        <InteractiveTable
            :headers="['Nom', 'Taille', 'Elo']"
            :rows="filteredClans"
            :isClickable="true"
            @row-click="openJoinModal"
        />

        <div v-if="selectedClan" class="modal" @click.self="closeModal">
            <div class="modal-content">
                <h3>Rejoindre le clan: <span class="highlight-green">{{ selectedClan.clan_name }}</span></h3>
                <p>Taille: {{ selectedClan.clan_members_count }} / {{ selectedClan.clan_members_max_count }}</p>
                <button @click="joinClan(selectedClan)" class="modal-button">Rejoindre</button>
                <button @click="closeModal" class="modal-button cancel">Annuler</button>
            </div>
        </div>
    </div>
</template>

<script>
import InteractiveTable from "./InteractiveTable.vue";
import clanServices from "../services/ClanServices";

export default {
    components: {
        InteractiveTable,
    },
    data() {
        return {
            clans: [], // Données des clans récupérées via l'API
            searchName: "",
            filterSize: "",
            selectedClan: null,
        };
    },
    computed: {
        filteredClans() {
            return this.clans
                .filter(clan => {
                    const matchesName = clan.clan_name.toLowerCase().includes(this.searchName.toLowerCase());
                    const matchesSize = this.filterSize === "" || this.filterClanSize(clan);
                    return matchesName && matchesSize;
                })
                .map(clan => ({
                    Nom: clan.clan_name, // Renommer la clé pour correspondre à l'en-tête
                    Taille: `${clan.clan_members_count}/${clan.clan_members_max_count}`,
                    Elo: clan.clan_elo.toFixed(2),
                }));
        },
    },
    methods: {
        async fetchClans() {
            try {
                const response = await clanServices.getClan();
                this.clans = response.data;
            } catch (error) {
                console.error("Erreur lors de la récupération des clans :", error);
                alert("Impossible de charger les clans. Veuillez réessayer plus tard.");
            }
        },
        filterClanSize(clan) {
            const membersCount = clan.clan_members_count;
            if (this.filterSize === "petit") return membersCount <= 3;
            if (this.filterSize === "moyen") return membersCount > 3 && membersCount <= 7;
            if (this.filterSize === "grand") return membersCount > 7;
            return true;
        },
        openJoinModal(clan) {
            this.selectedClan = clan;
        },
        closeModal() {
            this.selectedClan = null;
        },
        async joinClan(clan) {
            try {
                console.log(clan);
                const data = { clan_name: clan.Nom };
                console.log(data);
                await clanServices.joinClan(data);
                const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
                userInfo.clan = clan.Nom;
                localStorage.setItem("userInfo", JSON.stringify(userInfo));

                this.closeModal();
                this.$router.push("/clan_dashboard");
            } catch (error) {
                console.error("Erreur lors de la tentative de rejoindre le clan :", error);
                alert(error.response?.data?.error || "Impossible de rejoindre le clan. Veuillez réessayer.");
            }
        },
    },
    async created() {
        await this.fetchClans(); // Récupérer les clans au chargement du composant
    },
};
</script>

<style src="../assets/joinClan.css"></style>