<template>
  <div class="time_enigme-container">
    <div class="time_enigme-header">
      <h3 class="time_enigme-title">🕵️‍♂️ Énigme N°4</h3>

      <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">
        <span class="time_enigme-difficulty-level">Niveau : Moyen 🟡</span>
        <span class="time_enigme-attempts">Nombre de tentatives : 3</span>
        <span class="time_enigme-points">Points : 50</span>
      </p>
    </div>

    <div class="time_enigme-lives">
      <span
        v-for="(life, index) in lives"
        :key="index"
        class="time_enigme-heart"
        :class="{ lost: index >= remainingLives }"
      >
        💜
      </span>
    </div>

    <div class="time_enigme-progress-container">
      <!-- Barre de temps -->
      <div class="time_enigme-progress-bar">
        <div
          class="time_enigme-progress-bar-inner"
          :style="{ width: `${progress}%` }"
        ></div>
      </div>
      <p class="time_enigme-timer">⏳ Temps restant : {{ timeLeft }}s</p>

      <!-- Barre de progression -->
      <div class="time_enigme-progress-bar">
        <div
          class="time_enigme-progress-bar-inner secondary"
          :style="{ width: `${completedProgress}%` }"
        ></div>
      </div>
      <p class="time_enigme-timer">📈 Progression : {{ completedProgress }}%</p>
    </div>

    <div class="time_enigme-button-container">
      <button @click="loseLifeManually" class="time_enigme-action-button">
        Retirer une vie
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeEnigme",
  data() {
    return {
      timeLeft: 60,
      totalTime: 60,
      timer: null,
      totalLives: 5,
      remainingLives: 5,
      completedEnigmas: 5,
    };
  },
  computed: {
    progress() {
      return ((this.totalTime - this.timeLeft) / this.totalTime) * 100;
    },
    completedProgress() {
      return (this.completedEnigmas / 20) * 100; // Calcul de la progression
    },
    lives() {
      return Array.from({ length: this.totalLives }, (_, i) => i + 1);
    },
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timer = setInterval(() => {
        if (this.timeLeft > 0) {
          this.timeLeft -= 1;
        } else {
          clearInterval(this.timer);
          this.timer = null;
          this.loseLife();
        }
      }, 1000);
    },
    loseLife() {
      if (this.remainingLives > 0) {
        this.remainingLives -= 1;
        if (this.remainingLives === 0) {
          this.timeUp();
        } else {
          this.resetTimer();
        }
      }
    },
    resetTimer() {
      this.timeLeft = this.totalTime;
      this.startTimer();
    },
    timeUp() {
      alert("💔 Vous avez perdu toutes vos vies !");
    },
    loseLifeManually() {
      if (this.remainingLives > 0) {
        this.remainingLives -= 1;
      }
      if (this.remainingLives === 0) {
        alert("💔 Vous avez perdu toutes vos vies !");
      }
    },
  },
  beforeUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
  
  <style>
  .time_enigme-container {
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
  }
  
  .time_enigme-header {
    margin-bottom: 15px;
  }
  
  .time_enigme-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #8524BB;
    margin: 0;
  }
  
  .time_enigme-difficulty {

    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;

}

.time_enigme-difficulty span {
  display: inline-block;
  background-color: #ffffff; 
  color: #000000;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 8px; 
  text-align: center;
}

.time_enigme-completed-button {
  display: flex;
  justify-content: center;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  color: #ffffff; 
  font-weight: bold;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 8px; 
  text-align: center;
  white-space: nowrap; 
  min-width: 200px; 
}


/* Couleur spécifique pour chaque point clé */
.time_enigme-difficulty-level {
  color: #00FF56; 
}

.time_enigme-attempts {
  color: #FFC107; 
}

.time_enigme-points {
  color: #FF4D4D; 
}

  
  .time_enigme-lives {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .time_enigme-heart {
    font-size: 2rem;
    transition: transform 0.3s ease, color 0.3s ease;
  }
  
  .time_enigme-heart.lost {
    color: #8524BB;
    transform: scale(0.8);
    opacity: 0.5;
  }
  
  .time_enigme-progress-container {
    margin-top: 20px;
  }
  
  .time_enigme-progress-bar {
    position: relative;
    height: 25px;
    width: 100%;
    background-color: rgba(133, 36, 187, 0.1);
    border-radius: 12px;
    overflow: hidden;
  }
  
  .time_enigme-progress-bar-inner {
    height: 100%;
    background-color: #00FF56;
    width: 0%;
    border-radius: 12px 0 0 12px;
    transition: width 0.3s ease;
  }
  
  .time_enigme-timer {
    font-size: 1.2rem;
    color: #ffffff;
    margin-top: 10px;
  }
  
  /* STYLES DU BOUTON POUR RETIRER UNE VIE */
  .time_enigme-button-container {
    margin-top: 20px;
  }
  
  .time_enigme-action-button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #ff4d4d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .time_enigme-action-button:hover {
    background-color: #d94444;
    transform: translateY(-3px);
  }
  /* FIN DES STYLES DU BOUTON */
  </style>
  