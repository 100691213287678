<template>
  <div class="header-container">
        <button @click="goBack" class="bouton-gauche">Retour</button>
        <button @click="goToRules" class="bouton-gauche">Règles</button>
      </div>
    <div class="scytale-puzzle">
      <h1>Énigme N°7</h1>
      <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
        <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
        <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
      </p>
      <!-- Énoncé -->
      <div class="enonce">
        <img :src="imageSource" alt="Enigme" class="scytale-puzzle-image" />
      </div>
  
      <!-- Texte crypté -->
      <div class="crypted-text">
        <h3>Texte crypté :</h3>
        <p>{{ cryptedMessage }}</p>
      </div>
  
      <!-- Choix du nombre de colonnes + Inversion -->
      <div class="columns-section">
        <label for="columns">Choisissez le nombre de colonnes :</label>
        <input
          id="columns"
          type="number"
          min="1"
          :max="cryptedMessage.length"
          v-model.number="columns"
        />
        <button @click="toggleRowColumn" class="toggle-button">
          Inverser lignes/colonnes
        </button>
        <p>
          (Actuellement en mode
          <strong>{{ rowColumnSwapped ? 'inversé' : 'normal' }}</strong>)
        </p>
      </div>
  
      <!-- Affichage du tableau Scytale -->
      <div class="scytale-table" v-if="tableRows.length > 0">
        <h4>Tableau Scytale :</h4>
        <table>
          <tbody>
            <tr v-for="(row, rowIndex) in tableRows" :key="rowIndex">
              <td v-for="(char, colIndex) in row" :key="colIndex">
                <span>{{ char }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
      <!-- Trois indices successifs -->
      <div class="hints-section">
          <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
          <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
          <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
        </div>

        <!-- Afficher les indices demandés -->
        <div class="hints">
          <p v-if="hintsText">{{ hintsText }}</p>
        </div>
  
      <!-- Réponse utilisateur -->
      <div class="answer-section">
        <h4>Votre réponse déchiffrée :</h4>
        <input
          type="text"
          v-model="userAnswer"
          placeholder="Tapez ici la phrase déchiffrée"
        />
        <button @click="validateAnswer" class="validate-button">Valider</button>
      </div>
  
      <!-- Message de validation -->
      <div class="validation-message" v-if="message">
        <p>{{ message }}</p>
      </div>
      <div class="quote-box">
      <p>" L’infiltration dans le restaurant est une réussite ! La porte n’est pas verrouillée, vous l’ouvrez. À l'intérieur de la pièce, un ordinateur, protégé par un mot de passe, un bâton et un post-it, peut-être pouvez-vous retrouver ce mot de passe
        "</p>
    </div>
    </div>
  </template>
  
  <script>
  import riddleServices from "../services/RiddleServices";
  export default {
    data() {
      return {
        imageSource: "/img/enigme7.png",
        // Exemple de texte crypté
        cryptedMessage: "jttutceeenr  s  u ",
        columns: 3,
        // Inversion des logiques (lignes/colonnes)
        rowColumnSwapped: false,
  
        // Indices (3 indices)
        hints: {
          1: false,
          2: false,
          3: false,
        },
        hintsText: "",
  
        userAnswer: "",
        message: "",
        errors_count: 0,
        riddle_difficulty: 1,
        riddle_points: 0,
      };
    },
    computed: {
      // Construction du tableau
      tableRows() {
        if (this.columns < 1) return [];
  
        const totalChars = this.cryptedMessage.length;
  
        if (!this.rowColumnSwapped) {
          // Mode "normal"
          const rowCount = Math.ceil(totalChars / this.columns);
          return this.buildTable(rowCount, this.columns);
        } else {
          // Mode "inversé"
          const colCount = Math.ceil(totalChars / this.columns);
          return this.buildTable(this.columns, colCount);
        }
      },
    },
    mounted() {
      this.isRiddleLocked();
      this.createStatsForRiddle();
      this.incrementTries();
      this.fetchRiddlesInfo();
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      goToRules() {
        this.$router.push('/enigme_regle'); 
      },
      async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 7);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
        console.error("Erreur lors de la vérification de l'énigme :", error);
      }
    },
      async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(7);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
      async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(7, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(7, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(7, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(7, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
      buildTable(rows, cols) {
        const matrix = [];
        let index = 0;
        for (let r = 0; r < rows; r++) {
          const rowArray = [];
          for (let c = 0; c < cols; c++) {
            if (index < this.cryptedMessage.length) {
              rowArray.push(this.cryptedMessage[index]);
              index++;
            } else {
              rowArray.push(" ");
            }
          }
          matrix.push(rowArray);
        }
        return matrix;
      },
      toggleRowColumn() {
        this.rowColumnSwapped = !this.rowColumnSwapped;
      },
      async validateAnswer() {
        try {
        // Get the response data
        const data = await riddleServices.IsRiddleSolved(7, this.userAnswer.trim().toUpperCase());
        if (data.is_solved) {
          this.message = "Bravo ! Vous avez résolu l'énigme ! Rédirection dans 2 secondes..."; // Send feedback
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Mauvaise réponse. Réessayez !"; // Send feedback
          this.incrementErrors();
          this.errors_count += 1;
        }
        } catch (error) {
          console.error(error);
          this.$toast.error("Une erreur est survenue. Veuillez réessayer.");
        }
      },
      async showHint(hintNumber) {
        try {
          const hint = await riddleServices.getRiddleHint(7, hintNumber);
          this.hintsText = hint.hint;
          this.hints[hintNumber] = true;
        } catch (error) {
          console.error("Erreur lors de la récupération de l'indice :", error);
        }
      },
    },
  };
  </script>
  
  <style src="../assets/enigmeScytale.css"></style>
  