<template>
  <div class="mentions-container">
    <div class="mentions-content">
      <h1>Mentions <span>Légales</span></h1>
      <p>En vigueur au 01/01/2025</p>
      <h2>1. Présentation du site.</h2>
      <p>En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il
        est précisé aux utilisateurs du site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> l'identité des différents intervenants dans le cadre de sa
        réalisation et de son suivi :</p>
      <p><strong>Propriétaire</strong> : R&MI – SAS – Calais</p>
      <p><strong>Créateur</strong> : L'Équipe Ciphers</p>
      <p><strong>Responsable publication</strong> : L'Équipe Ciphers  </p>
      <p><strong>Chef de projet</strong> : Baron Jules </p>
      <p><strong>Développeur Front-End</strong> : Delrue Antoine – Capon Hugo - Baron Jules </p>
      <p><strong>Développeur Back-End</strong> : Nathan Fourny </p>
      <p><strong>Responsable des énigmes</strong> : Artu Florient </p>
      <p><strong>Hébergeur</strong> : JrCanDev </p>
      <p><strong>Délégué à la protection des données</strong> : Fourny Nathan – DPO</p>
        
      <p>Les mentions légales peuvent être modifiées à tout moment. Il est de la responsabilité de l'utilisateur de
        consulter régulièrement les mentions légales.</p>

      <h2>2. Conditions générales d’utilisation du site et des services proposés.</h2>
      <p>L’utilisation du site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> implique l’acceptation pleine et entière des conditions générales
        d’utilisation ci-après décrites. Ces conditions d’utilisation sont susceptibles d’être modifiées ou complétées à
        tout moment, les utilisateurs du site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> sont donc invités à les consulter de manière régulière.
      </p>
      <p>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance
        technique peut être toutefois décidée par L'Équipe Ciphers, qui s’efforcera alors de communiquer préalablement aux
        utilisateurs les dates et heures de l’intervention.</p>

      <h2>3. Protection des données personnelles.</h2>
      <p>Conformément au règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 (RGPD), le site
        <a href="https://kameleon.jrcan.dev/"> Kameleon</a> s'engage à assurer la protection des données personnelles des utilisateurs.</p>
      <p>Les données collectées sur le site sont limitées au strict nécessaire et sont utilisées dans le cadre des
        finalités pour lesquelles elles ont été recueillies (gestion des commandes, contact client, etc.).</p>
      <p><strong>Droits des utilisateurs :</strong> Vous disposez des droits suivants concernant vos données
        personnelles :</p>
      <ul>
        <li>Droit d'accès, de rectification et d'effacement.</li>
        <li>Droit à la portabilité des données.</li>
        <li>Droit de limitation et d'opposition au traitement.</li>
        <li>Droit de retirer votre consentement à tout moment.</li>
      </ul>
      <p>Pour exercer ces droits, vous pouvez contacter : Fourny Nathan – DPO.
      </p>

      <h2>4. Politique de confidentialité.</h2>
      <p>Le site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> s'engage à respecter la confidentialité des données personnelles et à prendre toutes
        les mesures nécessaires pour empêcher tout accès non autorisé, altération ou divulgation de vos données.</p>
      <p>Les informations collectées ne sont en aucun cas vendues, partagées ou échangées avec des tiers sans votre
        consentement explicite.</p>

      <h2>5. Responsabilité.</h2>
      <p>Le site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> s'efforce d'assurer au mieux l'exactitude et la mise à jour des informations diffusées.
        Toutefois, il ne saurait être tenu responsable des omissions, des inexactitudes ou des carences dans la mise à
        jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.</p>
      <p>Tout téléchargement de contenu se fait aux risques et périls de l'utilisateur. Le site ne saurait être tenu
        responsable d'éventuels dommages causés au matériel informatique ou d'une perte de données suite à un
        téléchargement.</p>

      <h2>7. Propriété intellectuelle.</h2>
      <p>Tout le contenu présent sur le site <a href="https://kameleon.jrcan.dev/"> Kameleon</a> (textes, images, graphismes, logo, icônes, etc.) est la
        propriété exclusive de L'Équipe Ciphers, sauf mention contraire. Toute reproduction, modification, publication ou
        adaptation de tout ou partie des éléments du site est interdite, sauf autorisation écrite préalable.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style src="../assets/mentions.css"></style>