<template>
  <div class="enigme4-puzzle-container">
    <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <h3 class="enigme4-titre">Énigme N°4</h3>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <p class="enigme4-paragraphe">
      "Un message crypté se trouve devant vous. Réfléchissez : parfois pour percer un mystère, il faut renverser la logique.  
      Essayez de cliquer sur deux lettres pour les échanger."
    </p>

    <div class="enigme4-letters-container">
      <div
        v-for="(letter, index) in scrambledMessage"
        :key="index"
        class="enigme4-letter-box"
        :class="{ selected: selectedIndex === index }"
        @click="selectLetter(index)"
      >
        {{ letter }}
      </div>
    </div>

    <div class="enigme4-buttons-section">
      <button @click="reset" class="enigme4-action-button">Réinitialiser</button>
      <button @click="validate" class="enigme4-action-button">Valider</button>
    </div>
    <br>
    
    <div class="enigme4-message-section" v-if="message">
      <p>{{ message }}</p>
    </div>
    <!-- Boutons pour demander les indices -->
    <div class="enigme4-hints-section">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
      <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
    </div>

    <!-- Afficher les indices demandés -->
    <div class="enigme4-hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>
    <br><br>
    <div class="quote-box">
      <p>" Après vous être bien rassasié, vous décidez d’aller voir le Chef à qui appartient l’établissement. Mais son service n’est pas terminé et il a besoin urgemment de votre aide pour déchiffrer une commande écrite de manière étrange. 
        "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeQuatre",
  data() {
    return {
      originalMessage: "tzatziki",
      scrambledMessage: "ikiztazt".split(''),
      selectedIndex: null,
      message: "",
      hints : {
        1: false,
        2: false,
        3: false
      },
      hintsText: "",
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(4);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(4, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(4, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(4, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(4, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 4);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },

    selectLetter(index) {
      if (this.selectedIndex === null) {
        this.selectedIndex = index;
      } else {
        this.swapLetters(this.selectedIndex, index);
        this.selectedIndex = null;
      }
    },
    swapLetters(i1, i2) {
      const newArray = [...this.scrambledMessage];
      [newArray[i1], newArray[i2]] = [newArray[i2], newArray[i1]];
      this.scrambledMessage = newArray;
    },
    reset() {
      this.scrambledMessage = "ikiztazt".split('');
      this.message = "";
      this.selectedIndex = null;
    },
    async validate() {
      try {
        const response = await riddleServices.IsRiddleSolved(4, this.originalMessage.toUpperCase());
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(4, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    }
  }
};
</script>

<style src="../assets/enigmeinverse.css"></style>
