import axios from 'axios';

export default {
  /**
   * Récupérer la liste des énigmes
   */
  fetchRiddles() {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get('https://kameleonback.jrcan.dev/api/riddles/', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }); // URL de votre endpoint pour les Riddles
  },

  fetchCoopRiddles() {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get('https://kameleonback.jrcan.dev/api/riddles/coop/', {
        headers: {  
          Authorization: `Bearer ${accessToken}`,
        },
      }); // URL de votre endpoint pour les Riddles
  },

  /**
   * Récupérer les détails d'une énigme spécifique
   * @param {Number} riddleId - L'ID de l'énigme
   */
  fetchRiddleDetails(riddleId) {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get(`https://kameleonback.jrcan.dev/api/riddles/${riddleId}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  },

  /**
   * Récupérer les énigmes d'un membre
   * @param {string} memberName - L'ID du membre
   * @returns {Promise<Object>} - Les listes des énigmes réussies et verrouillées
   */
  fetchMemberRiddles(memberName) {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get(`https://kameleonback.jrcan.dev/api/members/${memberName}/riddles/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  fetchMemberCoopRiddles(memberName) {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get(`https://kameleonback.jrcan.dev/api/members/${memberName}/riddles/coop/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  fetchMemberDashboard(username) {
    const accessToken = localStorage.getItem("accessToken");
    return axios.get(`https://kameleonback.jrcan.dev/api/members/${username}/dashboard/`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  checkIfRiddleIsLocked(riddles, id) {
    return riddles.some(riddle => riddle.riddle_id === parseInt(id));
  },

  async IsRiddleSolved(riddle, userResponse)
  {
    const accessToken = localStorage.getItem("accessToken");
    const payload = {
      riddle_id: riddle,
      response: { value: userResponse },
    };
    console.log(payload);
    try {
      console.log("Sending request...");
      const response = await axios.post('https://kameleonback.jrcan.dev/api/riddles/solve/', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        timeout: 10000,
      });
      return response.data;
    } catch (error) {
      console.error("Error in request:", error);
      throw error; // Rejeter l'erreur pour la propager
    }
  },

  async getRiddleHint(riddle, whichClue)
  {
    const accessToken = localStorage.getItem("accessToken");
    const payload = {
      riddle_id: riddle,
      clue: whichClue,
    };
    
    try {
      const response = await axios.post('https://kameleonback.jrcan.dev/api/riddles/clue/', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la requête axios :", error);
      throw error;
    }
  },

  async createRiddleStats(riddleId, memberName) {
    const accessToken = localStorage.getItem("accessToken");

    try {
      // Vérifier si la statistique existe
      const checkResponse = await axios.get(`https://kameleonback.jrcan.dev/api/riddles/stats/check/${riddleId}/${memberName}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (checkResponse.data.exists) {
        console.log("La statistique existe déjà, aucune création nécessaire.");
        return { exists: true };
      }

      // Créer la statistique si elle n'existe pas
      const payload = {
        riddle_id: riddleId,
        member_name: memberName,
      };
      const createResponse = await axios.post('https://kameleonback.jrcan.dev/api/riddles/stats/', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      console.log("Statistique créée avec succès :", createResponse.data);
      return createResponse.data;

    } catch (error) {
      console.error("Erreur lors de la gestion des statistiques :", error);
      throw error;
    }
  },

  async updateRiddleStats(riddleId, memberName, action) {
    const accessToken = localStorage.getItem("accessToken");
    const payload = {
      riddle_id: riddleId,
      member_name: memberName,
      action: action, // 'mark_solved', 'increment_errors', 'increment_tries'
    };
    try {
      const response = await axios.post('https://kameleonback.jrcan.dev/api/riddles/stats/update/', payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Statistiques mises à jour :", response.data);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la mise à jour des statistiques :", error);
      throw error;
    }
  },

  async fetchRiddleStats(riddleId, username) {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const response = await axios.get(`https://kameleonback.jrcan.dev/api/riddles/stats/${riddleId}/${username}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log("Statistiques récupérées :", response.data);
      return response.data;
    } catch (error) {
      console.error("Erreur lors de la récupération des statistiques :", error);
      throw error;
    }
  }

};
