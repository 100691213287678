<template>
  <header class="header">
    <div class="logo_header">
      <router-link to="/">
        <img :src="logoSrc" alt="Logo" class="responsive-logo" />
      </router-link>
    </div>
    <div class="burger" @click="toggleMenu">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <nav v-if="isLoggedIn" :class="{ 'nav': true, 'active': isMenuOpen }">
      <RouterLink to="/dashboard_login" class="white-text">Accueil</RouterLink>
      <RouterLink to="/skilltree" class="white-text">Enigme</RouterLink>
      <RouterLink v-if="haveClan" to="/clan_dashboard" class="white-text">Clan</RouterLink>
      <RouterLink v-else to="/clan_login" class="white-text">Clan</RouterLink>
      <RouterLink to="/statistiques" class="white-text">Stats</RouterLink>
      <RouterLink v-if="isAdmin" to="/admin_cv" class="white-text">Admin</RouterLink>
      <RouterLink to="/faq" class="white-text">Faq</RouterLink>
    </nav>
    <nav v-else :class="{ 'nav': true, 'active': isMenuOpen }">
      <RouterLink to="/" class="white-text">Accueil</RouterLink>
      <RouterLink to="/enigme" class="white-text">Enigme</RouterLink>
      <RouterLink to="/clan" class="white-text">Clan</RouterLink>
      <RouterLink to="/versus" class="white-text">Versus</RouterLink>
      <RouterLink to="/faq" class="white-text">Faq</RouterLink>
    </nav>
    <div class="user-section">
      <template v-if="isLoggedIn">
        <div class="user-info" @click="toggleUserMenu">
          <img class="user-logo" :src="profilePicture" alt="Photo de profil" />
          <span>{{ username }}</span>
        </div>
        <div v-if="isUserMenuOpen" class="user-menu">
          <button @click="editUsername">Modifier pseudo</button>
          <label>
            <input type="file" @change="editProfilePicture" style="display: none;" ref="fileInput" />
            <span>Modifier photo</span>
          </label>
          <button class="logout" @click="logout">Déconnexion</button>
        </div>
      </template>
      <template v-else>
        <RouterLink to="/login">Se connecter</RouterLink>
      </template>
    </div>
  </header>
</template>

<script>
import axios from 'axios';

export default {
  name: "AppHeader",
  data() {
    return {
      logoSrc: "/img/logo_kameleon.png",
      isMenuOpen: false,
      isUserMenuOpen: false,
      username: "",
      profilePicture: "/img/user_nc.png",
      haveClan: false,
      isAdmin: false,
    };
  },
  computed: {
    isLoggedIn() {
      return !!localStorage.getItem("accessToken");
    },
  },
  mounted() {
    this.updateLogo();
    this.getUserInfo();
    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("resize", this.updateLogo);

    this.haveClan = this.memberHaveClan();
    this.isAdmin = this.isMemberAdmin();

  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateLogo);
    window.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    memberHaveClan() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      return !!userInfo.clan;  // true si userInfo.clan existe et n'est pas vide
    },

    isMemberAdmin() {
      const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
      return !!userInfo.isAdmin;
    },  

    updateLogo() {
      if (window.innerWidth <= 1000) {
        this.logoSrc = "/img/logo_kameleon_k.png";
      } else {
        this.logoSrc = "/img/logo_kameleon.png";
      }
    },
    handleClickOutside(event) {
      const userMenu = this.$el.querySelector(".user-menu");
      const userInfo = this.$el.querySelector(".user-info");
      if (
        userMenu &&
        userInfo &&
        !userMenu.contains(event.target) &&
        !userInfo.contains(event.target)
      ) {
        this.isUserMenuOpen = false;
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleUserMenu() {
      this.isUserMenuOpen = !this.isUserMenuOpen;
      event.stopPropagation();
    },
    getUserInfo() {
      const token = localStorage.getItem("accessToken");
      if (token) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (userInfo) {
          this.username = userInfo.username || "Utilisateur";
          this.profilePicture = userInfo.profilePicture || "/img/user_nc.png"; // Default profile picture
        } else {
          this.username = "Utilisateur";
          this.profilePicture = "/img/user_nc.png"; // Default profile picture
        }
      }
    },
    async editUsername() {
      const newUsername = prompt("Entrez votre nouveau pseudo :", this.username);
      if (newUsername) {
        try {
          const token = localStorage.getItem("accessToken");
          const response = await axios.put(
            'https://kameleonback.jrcan.dev/api/user/update/',
            { username: newUsername },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
          userInfo.username = response.data.username;
          localStorage.setItem("userInfo", JSON.stringify(userInfo));

          this.username = response.data.username;
        } catch (error) {
          alert("Une erreur s'est produite lors de la mise à jour de votre pseudo.");
        }
      }
    },
    async editProfilePicture(event) {
      const file = event.target.files[0];
      if (!file) {
        alert("Aucun fichier sélectionné.");
        return;
      }

      const formData = new FormData();
      formData.append("profile_picture", file);

      try {
        const token = localStorage.getItem("accessToken");

        const response = await axios.put(
          'https://kameleonback.jrcan.dev/api/user/update/',
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {};
        userInfo.profilePicture = "https://kameleonback.jrcan.dev" + response.data.profile_picture;
        localStorage.setItem("userInfo", JSON.stringify(userInfo));

        this.profilePicture = "https://kameleonback.jrcan.dev" + response.data.profile_picture;

        alert("Votre photo de profil a été mise à jour avec succès !");
      } catch (error) {
        console.error(error);
        alert("Une erreur s'est produite lors de la mise à jour de votre photo de profil.");
      }
    },
    logout() {
      // delete token
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userInfo");
      this.username = "";
      this.$router.push("/login");
    }
  }
};
</script>


<style src="../assets/header.css"></style>