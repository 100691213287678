<template>
  <div>
    <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <h1 id="Titre_Enigme_2">Énigme N°2</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <div class="puzzle-container">
      <!-- Afficher l'image de fond -->
      <div class="image-section">
        <div class="grid-container">
          <img :src="imageSource" alt="Enigme" class="puzzle-image" />
          <canvas
            ref="gridCanvas"
            :width="gridWidth"
            :height="gridHeight"
            class="puzzle-grid"
          ></canvas>
        </div>
      </div>

      <!-- Consigne -->
      <div class="text-section">
        <p>
          Tracez jusqu'à deux droites rouges entre les nœuds du quadrillage.
        </p>
        <p id="Enigme2_title">Équations des droites à utiliser :</p>
        <ul>
          <li>y = 6 - x</li>
          <li>y = 3 + 0,5x</li>
        </ul>
        <button @click="resetCanvas" class="reset-button">Réinitialiser</button>
        <div
          v-if="feedback"
          :class="['feedback-section', { error: isPasswordIncorrect }]"
        >
          <p>{{ feedback }}</p>
        </div>
      </div>
    </div>
    <!-- Trois indices successifs -->
    <div class="indices-section">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
      <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
    </div>

    <div class="hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>

    <div class="quote-box">
      <p>" Dans ce coffre, un petit carnet retient votre attention, à l’intérieur, une carte et des calculs. "</p>
    </div>
    <!-- feedback utilisateur -->
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeCarte",
  data() {
    return {
      hints: { 1: false, 2: false},
      hintsText: "",
      imageSource: "/img/photo-enigme-2.png", // Chemin de l'image
      gridWidth: 500, // Largeur du quadrillage
      gridHeight: 500, // Hauteur du quadrillage
      rows: 10, // Nombre de lignes du quadrillage
      cols: 10, // Nombre de colonnes du quadrillage
      nodeSize: 8, // Taille des nœuds (en pixels)
      startNode: null, // Point de départ sélectionné
      lines: [], // Liste des droites tracées
      intersections: [], // Liste des intersections calculées
      gridContext: null, // Contexte 2D du canvas
      feedback: "", // feedback utilisateur
      drawingDisabled: false, // Empêcher le traçage de nouvelles droites
      expectedIntersection: { x: 166, y: 222 }, // Intersection attendue
      isPasswordIncorrect: false,
      errors_count: 0,
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  mounted() {
    this.isRiddleLocked().then(() => {
      this.drawGrid();
      this.createStatsForRiddle();
      this.incrementTries();
      this.fetchRiddlesInfo();
      const canvas = this.$refs.gridCanvas;
      canvas.addEventListener("mousedown", this.handleMouseDown);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(2);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(2, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(2, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(2, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(2, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(2, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles,2);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
        console.error("Erreur lors de la vérification de l'énigme :", error);
      }
    },

    drawGrid() {
      const canvas = this.$refs.gridCanvas;
      this.gridContext = canvas.getContext("2d");

      const ctx = this.gridContext;
      ctx.clearRect(0, 0, this.gridWidth, this.gridHeight);

      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      // Dessiner les droites déjà tracées
      this.lines.forEach((line) => {
        ctx.strokeStyle = "#00FF56";
        ctx.lineWidth = 5;
        ctx.beginPath();
        ctx.moveTo(line.start.x, line.start.y);
        ctx.lineTo(line.end.x, line.end.y);
        ctx.stroke();
      });

      // Ajouter les axes gradués
      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          const x = col * spacingX;
          const y = row * spacingY;

          // Dessiner les nœuds sauf sur la première et dernière ligne ou colonne
          if (
            row > 0 &&
            row < this.rows - 1 &&
            col !== 0 &&
            col !== this.cols - 1
          ) {
            ctx.fillStyle =
              this.startNode && this.startNode.x === x && this.startNode.y === y
                ? "#AA00FF" // Bleu clair pour le point sélectionné
                : "black"; // Gris pour les points normaux

            ctx.beginPath();
            ctx.arc(x, y, this.nodeSize / 2, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      }
    },
    handleMouseDown(event) {
      if (this.drawingDisabled) return; // Empêcher de dessiner si désactivé

      const canvas = this.$refs.gridCanvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const node = this.getClosestNode(x, y);

      if (node) {
        if (!this.startNode) {
          // Si aucun point de départ, définir le nœud cliqué comme départ
          this.startNode = node;
        } else if (this.startNode.x === node.x && this.startNode.y === node.y) {
          // Si le point de départ et le point d'arrivée sont les mêmes, réinitialiser le point de départ
          this.startNode = null;
        } else if (this.lines.length < 2) {
          // Limiter le nombre de droites à 2
          const start = this.startNode;
          const end = node;

          // Calculer les points d'intersection avec les bords du canvas
          const extendedLine = this.getExtendedLine(start, end);
          this.lines.push(extendedLine);

          // Calculer les intersections avec toutes les lignes existantes
          this.calculateIntersections(extendedLine);

          // Réinitialiser le point de départ
          this.startNode = null;

          if (this.lines.length === 2) {
            this.drawingDisabled = true;
            this.validateSolution(); // Valider automatiquement après avoir tracé deux droites
          }
        }
        this.drawGrid(); // Redessiner pour mettre à jour les droites, intersections et surbrillances
      }
    },
    calculateIntersections(newLine) {
      this.lines.forEach((line) => {
        const intersection = this.getLineIntersection(
          newLine.start,
          newLine.end,
          line.start,
          line.end
        );
        if (intersection) {
          this.intersections.push(intersection);
        }
      });
    },
    async validateSolution() {
      const result = this.intersections.find(point => this.isCloseToExpected(point));
      const formattedResult = {
        x: Math.floor(result.x), // Supprime les décimales pour x
        y: Math.floor(result.y)  // Supprime les décimales pour y
      };
      try {
        const response = await riddleServices.IsRiddleSolved(2, formattedResult);
        if (response.is_solved) {
          this.feedback = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.isPasswordIncorrect = false;
          await this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.feedback = "Erreur ! Essayez de nouveau.";
          this.isPasswordIncorrect = true;
          await this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        this.isPasswordIncorrect = true;
        console.log(error);
      }
    },
    isCloseToExpected(point) {
      const tolerance = 1; // Tolérance pour la précision
      return (
        Math.abs(point.x - this.expectedIntersection.x) <= tolerance &&
        Math.abs(point.y - this.expectedIntersection.y) <= tolerance
      );
    },
    resetCanvas() {
      // Réinitialiser toutes les données
      this.lines = [];
      this.intersections = [];
      this.startNode = null;
      this.feedback = "";
      this.drawingDisabled = false;
      this.drawGrid(); // Redessiner un canvas vierge
    },
    getLineIntersection(p1, p2, p3, p4) {
      const denominator =
        (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);

      if (denominator === 0) return null; // Pas d'intersection (droites parallèles)

      const t =
        ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) /
        denominator;
      const u =
        ((p1.x - p3.x) * (p1.y - p2.y) - (p1.y - p3.y) * (p1.x - p2.x)) /
        denominator;

      if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        // Intersection dans les segments
        return {
          x: p1.x + t * (p2.x - p1.x),
          y: p1.y + t * (p2.y - p1.y),
        };
      }

      return null; // Pas d'intersection dans les segments
    },
    getClosestNode(x, y) {
      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          if (
            row === 0 ||
            row === this.rows - 1 ||
            col === 0 ||
            col === this.cols - 1
          )
            continue;

          const nodeX = col * spacingX;
          const nodeY = row * spacingY;
          const distance = Math.sqrt((x - nodeX) ** 2 + (y - nodeY) ** 2);

          if (distance <= this.nodeSize) {
            return { x: nodeX, y: nodeY };
          }
        }
      }
      return null;
    },
    getExtendedLine(start, end) {
      const dx = end.x - start.x;
      const dy = end.y - start.y;

      if (dx === 0) {
        // Si les points ont la même coordonnée x, tracer une ligne verticale
        return {
          start: { x: start.x, y: 0 },
          end: { x: start.x, y: this.gridHeight },
        };
      }

      const slope = dy / dx;
      const intercept = start.y - slope * start.x;

      const points = [];

      if (dx !== 0) {
        points.push({ x: 0, y: intercept });
        points.push({
          x: this.gridWidth,
          y: slope * this.gridWidth + intercept,
        });
      }

      if (dy !== 0) {
        points.push({ x: -intercept / slope, y: 0 });
        points.push({
          x: (this.gridHeight - intercept) / slope,
          y: this.gridHeight,
        });
      }

      const validPoints = points.filter(
        (point) =>
          point.x >= 0 &&
          point.x <= this.gridWidth &&
          point.y >= 0 &&
          point.y <= this.gridHeight
      );

      if (validPoints.length >= 2) {
        return { start: validPoints[0], end: validPoints[1] };
      }

      return null; // Pas de points valides
    },
  },
};
</script>

<style src="../assets/enigmecarte.css"></style>
