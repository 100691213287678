<template>
  <div>
    <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <h1 id="Titre_Enigme_2">Énigme N°3</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <div class="container-EnigmeHistoire">
      <!-- Afficher l'image de l'énigme -->
      <div class="left-part">
        <img :src="imageSource" alt="Enigme" class="puzzle-img-EnigmeHistoire" />
        <!-- Ajouter la légende sous l'image -->
        <div class="image-caption-EnigmeHistoire">
          <p><strong>Vercingétorix jette ses armes aux pieds de César</strong></p>
          <p>Peint par Lionel Royer, 1899</p>
          <p>Musée Crozatier, Le Puy-en-Velay, France</p>
        </div>
      </div>

      <div class="right-part">
        <!-- Table de substitution toujours visible -->
        <div class="substitution-table">
          <h3>Table de substitution</h3>
          <div class="table">
            <div v-for="(letter, index) in substitutionTable" :key="index" class="table-item">
              {{ letter }} = {{ index + 1 }}
            </div>
          </div>
        </div>

        <!-- Afficher le texte crypté -->
        <div class="text-section-EnigmeHistoire">
          <p>{{ cryptedText }}</p>
        </div>

        <!-- Saisie de la réponse -->
        <div class="input-section-EnigmeHistoire">
          <div class="terminal-input">
            <div class="input-wrapper">
              <input
                type="text"
                v-model="userInput"
                placeholder="Votre réponse..."
                @keyup.enter="validateAnswer"
              />
            </div>
          </div>
          <button @click="validateAnswer">Valider</button>
        </div>

        <div v-if="feedback" :class="['feedback-EnigmeHistoire', { error: isPasswordIncorrect }]">
          <p>{{ feedback }}</p>
        </div>

        <!-- Boutons pour demander les indices -->
        <div class="hint-section-EnigmeHistoire">
          <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
          <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
          <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
        </div>

        <!-- Afficher les indices demandés -->
        <div class="hints-EnigmeHistoire">
          <p v-if="hintsText">{{ hintsText }}</p>
        </div>

      </div>
    </div>
    <div class="quote-box">
      <p>" L’endroit indiqué est un restaurant connu, pourquoi ne pas aller y faire un tour ? Vous vous installez et vous vous perdez dans vos pensées, le serveur vous surprend en vous demandant votre choix
        "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeHistoire",
  data() {
    return {
      imageSource: "/img/vercingetorix_jette_ses_armes_aux_pieds_de_cesar.png", // Chemin de l'image
      cryptedText: ".JUJMN LN.J,", // Texte crypté
      userInput: "", // Réponse de l'utilisateur
      feedback: "", // Feedback après validation
      substitutionTable: "ABCDEFGHIJKLMNOPQRSTUVWXYZ,.?!".split(""), // Table de substitution
      hints: { 1: false, 2: false, 3: false }, // Suivi des indices affichés
      hintsText: "", // Texte des indices
      isPasswordIncorrect: false, // Indicateur de réponse incorrecte
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(3);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(3, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(3, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(3, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(3, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 3);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },

    async validateAnswer() {
      // Vérifier si la réponse est correcte (ignorer la casse)
      try {
        const response = await riddleServices.IsRiddleSolved(3, this.userInput.toUpperCase());
        if (response.is_solved) {
          this.feedback = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.isPasswordIncorrect = false;
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.feedback = "Erreur ! Essayez de nouveau.";
          this.isPasswordIncorrect = true;
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        this.isPasswordIncorrect = true;
        console.log(error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(3, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
  },
};
</script>

<style src="../assets/enigmehistoire.css"></style> 