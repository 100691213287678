<template>
    <div class="skill-tree-container">
      <h1 class="h1_skill-tree">Arbre de Compétences</h1>
      <a href="enigme_regle" class="skilltree_time_enigme-link">📜 Règles des énigmes</a>
      <br>
      <!-- Barre de progression -->
      <div class="skilltree_time_enigme-progress-bar">
        <div
          class="skilltree_time_enigme-progress-bar-inner secondary"
          :style="{ width: `${completedProgress}%` }"
        ></div>
      </div>
      <p class="skilltree_time_enigme-timer">📈 Progression : {{ completedProgress }}%</p>

      <div class="canvas-container">
        <canvas class="canvas_skill-tree"
          ref="canvas" 
          :width="canvasWidth" 
          :height="canvasHeight"
          @mousedown="onMouseDown"
          @mouseup="onMouseUp"
          @mousemove="onMouseMove"
          @wheel.prevent="onMouseWheel"
          @click="onCanvasClick"
        ></canvas>
      </div>
    </div>
  </template>
  
  <script>
  import riddleServices from '../services/RiddleServices';
  export default {
    name: 'SkillTree',
    data() {
      return {
        achievedRiddles: [],
        lockedRiddles: [],
        tree: null,
        edges: [],
        canvasWidth: 1000,
        canvasHeight: 800,
  
        offsetX: 540,
        offsetY: 100,
        scale: 1,
  
        isDragging: false,
        dragStartX: 0,
        dragStartY: 0,
        dragOffsetX: 0,
        dragOffsetY: 0,
  
        nodeWidth: 120,
        nodeHeight: 50,
        riddle: null,
        loading: true,
        error: null,
        hoveredNode: null,
        completedEnigmas: 5,
        maxEnigmas: 0,

        isTreeCentered: false,
        riddles: [],
      }
    },
    computed: {
    
    completedProgress() {
      console.log(this.achievedRiddles.length);
      console.log(this.riddles.length);
      if (!this.riddles || this.riddles.length === 0) {
        return 0; // Si aucune énigme, retourne 0% de progression
      }
      return ((this.achievedRiddles.length / this.riddles.length) * 100).toFixed(2);
    },
    
    },
    props: {
      riddleId: {
        type: Number,
        required: true,
      },
    },
    mounted() {
      this.fetchMemberRiddles().then(() => {
        this.fetchRiddle();
      });
      const canvas = this.$refs.canvas;
      canvas.addEventListener('mousemove', this.handleMouseMove);
      canvas.addEventListener('mouseout', this.handleMouseOut);
    },
    methods: {
      async fetchMemberRiddles()
      {
        try {
          const userInfo = JSON.parse(localStorage.getItem("userInfo"));
          const username = userInfo?.username;
          const response = await riddleServices.fetchMemberRiddles(username);
          const riddle = response.data;
          this.achievedRiddles = riddle.achievedRiddles;
          this.lockedRiddles = riddle.lockedRiddles;
          console.log(riddle);
        } catch (err) {
          this.error = "Impossible de récupérer les détails de l'énigme.";
          console.log(this.error);
          console.log(err);
        } finally {
          this.loading = false;
        }
      },

      async fetchRiddle() {
        try {
          const response = await riddleServices.fetchRiddles();
          this.riddles = response.data;
          const riddle = response.data;
          console.log(riddle);
          this.buildTree(riddle);
          this.layoutTree(this.tree);
          this.drawTree();
        } catch (err) {
          this.error = "Impossible de récupérer les détails de l'énigme.";
          console.log(this.error);
          console.log(err);
        } finally {
          this.loading = false;
        }
      },
      buildTree(riddles) {
        const riddleDict = {};

        // create a dictionnary of riddles
        riddles.forEach(riddle => {
          riddleDict[riddle.riddle_id] = {
            id: `${riddle.riddle_id}`,
            name: riddle.riddle_type,
            route: riddle.riddle_path || '/',
            children: []
          };
        });

        const allNodes = new Set(); // to follow all nodes added
        let rootNodes = []; // list of root nodes (Starting riddles)

        riddles.forEach(riddle => {
          if (riddle.dependance.length === 0) {
            // Add node without dependencies to root nodes
            rootNodes.push(riddleDict[riddle.riddle_id]);
          } else {
            // Add node with dependencies to their parent
            riddle.dependance.forEach(dep => {
              const depId = dep.riddle_id; // get the id
              if (riddleDict[depId]) {
                riddleDict[depId].children.push(riddleDict[riddle.riddle_id]);
                allNodes.add(riddleDict[riddle.riddle_id]);
              }
            });
          }
        });

        // Sort root nodes
        rootNodes = rootNodes.filter(node => !allNodes.has(node));

        const root = {
          id: 'root',
          name: 'Énigmes',
          route: '/skilltree',
          children: rootNodes
        };

        this.tree = root;
      },

      layoutTree(root) {
        const levelHeight = 150;
        const nodeWidth = this.nodeWidth;
        // const nodeHeight = this.nodeHeight;
        let maxDepth = 0;
  
        const getDepth = (node, depth=0) => {
          if (depth > maxDepth) maxDepth = depth;
          node.depth = depth;
          node.children.forEach(child => getDepth(child, depth+1));
        };
  
        getDepth(root);
  
        let levels = [];
        for (let i = 0; i <= maxDepth; i++) {
          levels[i] = [];
        }
  
        const assignNodePositions = (node) => {
          levels[node.depth].push(node);
          node.children.forEach(child => assignNodePositions(child));
        };
  
        assignNodePositions(root);
  
        levels.forEach((level, depth) => {
          let total = level.length;
          let startX = -(total * nodeWidth)/2; 
          level.forEach((node, i) => {
            node.x = startX + i * (nodeWidth + 50);
            node.y = depth * levelHeight;
          });
        });
  
        this.nodes = [];
        const flatten = (node) => {
          this.nodes.push(node);
          node.children.forEach(c => flatten(c));
        };
        flatten(root);
  
        this.edges = [];
        const addEdges = (node) => {
          node.children.forEach(child => {
            this.edges.push({ from: node, to: child });
            addEdges(child);
          });
        };
        addEdges(root);
      },

      calculateTreeBounds() {
        let minX = Infinity, maxX = -Infinity;
        let minY = Infinity, maxY = -Infinity;

        this.nodes.forEach(node => {
          if (node.x < minX) minX = node.x;
          if (node.x > maxX) maxX = node.x;
          if (node.y < minY) minY = node.y;
          if (node.y > maxY) maxY = node.y;
        });

        return { minX, maxX, minY, maxY };
      },

      drawTree() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

        if (!this.isTreeCentered) {
          // Centrer l'arbre uniquement au chargement initial
          const { minX, maxX, minY, maxY } = this.calculateTreeBounds();
          const treeWidth = maxX - minX;
          const treeHeight = maxY - minY;

          const canvasCenterX = this.canvasWidth / 2;
          const canvasCenterY = this.canvasHeight / 2;

          const treeCenterX = minX + treeWidth / 2;
          const treeCenterY = minY + treeHeight / 2;

          this.offsetX = canvasCenterX - treeCenterX * this.scale;
          this.offsetY = canvasCenterY - treeCenterY * this.scale;

          this.isTreeCentered = true; // Marque l'arbre comme centré
        }

        ctx.save();
        ctx.translate(this.offsetX, this.offsetY);
        ctx.scale(this.scale, this.scale);

        // Dessiner les arêtes
        this.edges.forEach((edge) => {
          this.drawEdge(ctx, edge.from, edge.to);
        });

        // Dessiner les nœuds
        this.nodes.forEach((node) => {
          this.drawNode(ctx, node);
        });

        ctx.restore();
      },
  
      drawNode(ctx, node) {
        if (this.loading) {
          console.log("Chargement des énigmes...");
          return;
        }

        const isHovered = this.hoveredNode === node;
        const radius = 40;
        const x = node.x;
        const y = node.y;
        
        const isAchieved = this.achievedRiddles && this.achievedRiddles.some(riddle => riddle.riddle_id === parseInt(node.id));
        const isLocked = this.lockedRiddles && this.lockedRiddles.some(riddle => riddle.riddle_id === parseInt(node.id));

        // Définir les couleurs en fonction du type de nœud
        let innerColor, outerColor;
        if (node.id === 'root') {
            innerColor = '#FF8C00';
            outerColor = '#FFA500';
        } else if (isAchieved) {
            innerColor = isHovered ? '#008000' : '#32CD32'; // Normal color
            outerColor = isHovered ? '#00FF00' : '#00FF7F';
        } else if (isLocked) {
            innerColor = isHovered ? '#565656' : '#808080'; // Normal color
            outerColor = isHovered ? '#808080' : '#565656';
        }
        else {
            innerColor = isHovered ? '#8B0000' : '#551A8B'; // Normal color
            outerColor = isHovered ? '#FF6347' : '#AA00FF';
        }

        const gradient = ctx.createRadialGradient(x, y, 10, x, y, radius);
        gradient.addColorStop(0, innerColor);
        gradient.addColorStop(1, outerColor);

        // Shadow
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;

        // Draw circle
        ctx.beginPath();
        ctx.arc(x, y, radius, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.fillStyle = gradient;
        ctx.fill();

        // Draw border
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#FFFFFF';
        ctx.stroke();

        // Reset shadow
        ctx.shadowColor = 'transparent';

        // Draw text in the center
        ctx.fillStyle = '#FFFFFF';
        ctx.font = '16px Arial';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(node.name, x, y);

        // Hitbox
        node.hitBox = {
            x: x - radius,
            y: y - radius,
            w: radius * 2,
            h: radius * 2,
        };
      },
  
      drawEdge(ctx, from, to) {
        const gradient = ctx.createLinearGradient(from.x, from.y, to.x, to.y);
        gradient.addColorStop(0, '#AA00FF');
        gradient.addColorStop(1, '#FFFFFF');

        ctx.strokeStyle = gradient;
        ctx.lineWidth = 3;

        ctx.beginPath();
        ctx.moveTo(from.x, from.y + 40);
        ctx.lineTo(to.x, to.y - 40);
        ctx.stroke();
      },
  
      // Nav on canvas
      onMouseDown(e) {
        this.isDragging = true;
        this.dragStartX = e.clientX;
        this.dragStartY = e.clientY;
        this.dragOffsetX = this.offsetX;
        this.dragOffsetY = this.offsetY;
      },
      onMouseUp() {
        this.isDragging = false;
      },
      handleMouseMove(e) {
        if (this.isDragging) {
          // Calculer le décalage par rapport à la position initiale
          const deltaX = e.clientX - this.dragStartX;
          const deltaY = e.clientY - this.dragStartY;

          // Mettre à jour les offsets du canvas
          this.offsetX = this.dragOffsetX + deltaX;
          this.offsetY = this.dragOffsetY + deltaY;

          // Redessiner le canvas avec les nouveaux offsets
          this.drawTree();
        } else {
          // Logique de survol d'un nœud
          const canvas = this.$refs.canvas;
          const rect = canvas.getBoundingClientRect();

          const mouseX = (e.clientX - rect.left - this.offsetX) / this.scale;
          const mouseY = (e.clientY - rect.top - this.offsetY) / this.scale;

          this.hoveredNode = this.nodes.find((node) => {
            const radius = 40;
            return (
              Math.pow(mouseX - node.x, 2) + Math.pow(mouseY - node.y, 2) <=
              Math.pow(radius, 2)
            );
          });

          this.drawTree();
        }
      },
      handleMouseOut() {
        this.hoveredNode = null; // Reset hover
        this.drawTree(); // Redraw Tree
      },

      onMouseWheel(e) {
        const zoomFactor = 0.1;
        const minScale = 0.5;
        const maxScale = 2;

        if (e.deltaY < 0) {
          this.scale = Math.min(this.scale * (1 + zoomFactor), maxScale);
        } else {
          this.scale = Math.max(this.scale * (1 - zoomFactor), minScale);
        }
        this.drawTree();
      },
  
      // Click detection
      onCanvasClick(e) {
        // Get coordinate
        const rect = this.$refs.canvas.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const clickY = e.clientY - rect.top;
  
        const worldX = (clickX - this.offsetX) / this.scale;
        const worldY = (clickY - this.offsetY) / this.scale;
        
        for (let node of this.nodes) {
            if (!node.hitBox) continue;
            const { x, y, w, h } = node.hitBox;
            if (worldX >= x && worldX <= x + w && worldY >= y && worldY <= y + h) {

              const isLocked = this.lockedRiddles && this.lockedRiddles.some(riddle => riddle.riddle_id === parseInt(node.id));
              if (isLocked) {
                  console.log(`Node ${node.id} is locked. Click ignored.`);
                  return; // Ignore click if locked
              }
              
              if (node.route) {
                  this.$router.push({ path: node.route });
              }
              break; 
            }
        }
      }
    }
  }
  </script>
  
<style src="../assets/skill_tree.css"></style>
  