<template>
  <CalculatriceVue v-if="calculatrice" />
  <div>
    <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <h1 id="Titre_Enigme_12">Énigme N°12</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <div class="puzzle-container">
      <!-- Afficher l'image de fond -->
      <div class="image-section">
        <div class="grid-container">
          <img :src="imageSource" alt="Enigme" class="puzzle-image" />
          <canvas ref="gridCanvas" :width="gridWidth" :height="gridHeight" class="puzzle-grid"></canvas>
        </div>
      </div>
  
      <!-- Consigne -->
      <div class="text-section">
        <p>Tracez jusqu'à deux droites rouges entre les nœuds du quadrillage.</p>
        <p>Équations des droites à utiliser :</p>
        <ul>
          <li>(3x+18)/(x+2)</li>
          <li>(4x+2)/(x+1)</li>
        </ul>
        <button @click="resetCanvas" class="reset-button">Réinitialiser</button>
        <button @click="validateSolution" class="validate-button" :disabled="lines.length < 2">Valider</button>
      </div>
  
  
      <!-- Nouvelle section pour les points d'intersection
      <div v-if="intersections.length > 0" class="intersection-section">
        <p>Points d'intersection :</p>
        <ul>
          <li v-for="(point, index) in intersections" :key="index">
            ({{ point.x.toFixed(2) }}, {{ point.y.toFixed(2) }})
          </li>
        </ul>
      </div> -->
      <!-- Message utilisateur -->
    </div>
    <div v-if="message" class="message-section">
        <p class="enigme12-middle-message">{{ message }}</p>
      </div>
      <div class="quote-box">
    <p>" Vous ne voulez pas vous laisser faire. Après avoir cherché des informations sur Internet vous vous êtes rendu-compte que le Chef paraît ne pas exister, presque aucune trace trouvable.. Un site obscure vous donne seulement une minuscule information, non vérifiable, non sourcé, écrit n’importe comment mais vous n’avez rien d’autre
      "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import UserServices from "../services/UserServices";
import CalculatriceVue from "./Calculatrice.vue";
export default {
  name: "EnigmeDouze",
  components: {
    CalculatriceVue,
  },
  data() {
    return {
      imageSource: "/img/photo-enigme-2.png", // Chemin de l'image
      gridWidth: 500, // Largeur du quadrillage
      gridHeight: 500, // Hauteur du quadrillage
      rows: 10, // Nombre de lignes du quadrillage
      cols: 10, // Nombre de colonnes du quadrillage
      nodeSize: 8, // Taille des nœuds (en pixels)
      startNode: null, // Point de départ sélectionné
      lines: [], // Liste des droites tracées
      intersections: [], // Liste des intersections calculées
      gridContext: null, // Contexte 2D du canvas
      message: "", // Message utilisateur
      drawingDisabled: false, // Empêcher le traçage de nouvelles droites
      expectedIntersection: { x: 244, y: 144 }, // Intersection attendue
      calculatrice: false,
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.haveCalculatrice();
    this.drawGrid();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
    const canvas = this.$refs.gridCanvas;
    canvas.addEventListener("mousedown", this.handleMouseDown);
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(12);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(12, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(12, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(12, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(12, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async haveCalculatrice() {
      try {
        const response = await UserServices.haveCalculatrice();
        if (response) {
          this.calculatrice = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la calculatrice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 12);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },
    drawGrid() {
      const canvas = this.$refs.gridCanvas;
      this.gridContext = canvas.getContext("2d");

      const ctx = this.gridContext;
      ctx.clearRect(0, 0, this.gridWidth, this.gridHeight);

      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      // Dessiner les droites déjà tracées
      this.lines.forEach((line) => {
        ctx.strokeStyle = "#00FF56";
        ctx.lineWidth = 5;
        ctx.beginPath();
        ctx.moveTo(line.start.x, line.start.y);
        ctx.lineTo(line.end.x, line.end.y);
        ctx.stroke();
      });

      // Ajouter les axes gradués
      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          const x = col * spacingX;
          const y = row * spacingY;

          // Dessiner les nœuds sauf sur la première et dernière ligne ou colonne
          if (row > 0 && row < this.rows - 1 && col !== 0 && col !== this.cols - 1) {
            ctx.fillStyle =
              this.startNode &&
              this.startNode.x === x &&
              this.startNode.y === y
                ? "blue"
                : "black";

            ctx.beginPath();
            ctx.arc(x, y, this.nodeSize / 2, 0, Math.PI * 2);
            ctx.fill();
          }
        }
      }
    },
    handleMouseDown(event) {
      if (this.drawingDisabled) return; // Empêcher de dessiner si désactivé

      const canvas = this.$refs.gridCanvas;
      const rect = canvas.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;

      const node = this.getClosestNode(x, y);

      if (node) {
        if (!this.startNode) {
          // Si aucun point de départ, définir le nœud cliqué comme départ
          this.startNode = node;
        } else if (this.lines.length < 2) {
          // Limiter le nombre de droites à 2
          const start = this.startNode;
          const end = node;

          // Calculer les points d'intersection avec les bords du canvas
          const extendedLine = this.getExtendedLine(start, end);
          if (extendedLine) {
            this.lines.push(extendedLine);

            // Calculer les intersections avec toutes les lignes existantes
            this.calculateIntersections(extendedLine);

            // Réinitialiser le point de départ
            this.startNode = null;

            if (this.lines.length === 2) {
              this.message = "Vous avez tracé deux droites. Validez pour vérifier votre solution.";
              this.drawingDisabled = true;
            }

            this.drawGrid(); // Redessiner pour mettre à jour les droites, intersections et surbrillances
          }
        }
      }
    },
    calculateIntersections(newLine) {
      this.lines.forEach((line) => {
        // Ne pas vérifier l'intersection d'une ligne avec elle-même
        if (line === newLine) return;

        const intersection = this.getLineIntersection(
          newLine.start,
          newLine.end,
          line.start,
          line.end
        );
        if (intersection) {
          this.intersections.push(intersection);
        }
      });
    },
    async validateSolution() {
      console.log("check");
      if (!Array.isArray(this.intersections) || this.intersections.length === 0) {
        this.message = "Erreur : aucune intersection trouvée.";
        this.isPasswordIncorrect = true;
        return;
      }
      const result = this.intersections.find(point => this.isCloseToExpected(point));
      const formattedResult = {
        x: Math.floor(result.x),
        y: Math.floor(result.y)
      };
      try {
        const response = await riddleServices.IsRiddleSolved(12, formattedResult);
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.isPasswordIncorrect = false;
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.isPasswordIncorrect = true;
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        this.isPasswordIncorrect = true;
        console.log(error);
      }
    },
    isCloseToExpected(point) {
      const tolerance = 1; // Tolérance pour la précision
      return (
        Math.abs(point.x - this.expectedIntersection.x) <= tolerance &&
        Math.abs(point.y - this.expectedIntersection.y) <= tolerance
      );
    },
    resetCanvas() {
      // Réinitialiser toutes les données
      this.lines = [];
      this.intersections = [];
      this.startNode = null;
      this.message = "";
      this.drawingDisabled = false;
      this.drawGrid(); // Redessiner un canvas vierge
    },
    getLineIntersection(p1, p2, p3, p4) {
      const denominator =
        (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);

      if (denominator === 0) return null; // Pas d'intersection (droites parallèles)

      const t =
        ((p1.x - p3.x) * (p3.y - p4.y) - (p1.y - p3.y) * (p3.x - p4.x)) /
        denominator;
      const u =
        ((p1.x - p3.x) * (p1.y - p2.y) - (p1.y - p3.y) * (p1.x - p2.x)) /
        denominator;

      if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
        // Intersection dans les segments
        return {
          x: p1.x + t * (p2.x - p1.x),
          y: p1.y + t * (p2.y - p1.y),
        };
      }

      return null; // Pas d'intersection dans les segments
    },
    getClosestNode(x, y) {
      const spacingX = this.gridWidth / (this.cols - 1);
      const spacingY = this.gridHeight / (this.rows - 1);

      for (let row = 0; row < this.rows; row++) {
        for (let col = 0; col < this.cols; col++) {
          if (row === 0 || row === this.rows - 1 || col === 0 || col === this.cols - 1)
            continue;

          const nodeX = col * spacingX;
          const nodeY = row * spacingY;
          const distance = Math.sqrt((x - nodeX) ** 2 + (y - nodeY) ** 2);

          if (distance <= this.nodeSize) {
            return { x: nodeX, y: nodeY };
          }
        }
      }
      return null;
    },
    getExtendedLine(start, end) {
      const dx = end.x - start.x;
      const dy = end.y - start.y;

      if (dx === 0 && dy === 0) return null; // Lignes identiques

      const slope = dy / dx;
      const intercept = start.y - slope * start.x;

      const points = [];

      if (dx !== 0) {
        points.push({ x: 0, y: intercept });
        points.push({ x: this.gridWidth, y: slope * this.gridWidth + intercept });
      }

      if (dy !== 0) {
        points.push({ x: -intercept / slope, y: 0 });
        points.push({ x: (this.gridHeight - intercept) / slope, y: this.gridHeight });
      }

      const validPoints = points.filter(
        (point) =>
          point.x >= 0 &&
          point.x <= this.gridWidth &&
          point.y >= 0 &&
          point.y <= this.gridHeight
      );

      // Supprimer les doublons
      const uniquePoints = [];
      validPoints.forEach((pt) => {
        if (!uniquePoints.some((p) => p.x === pt.x && p.y === pt.y)) {
          uniquePoints.push(pt);
        }
      });

      if (uniquePoints.length >= 2) {
        return { start: uniquePoints[0], end: uniquePoints[uniquePoints.length - 1] };
      }

      return null; // Pas de points valides
    },
    revealHint(index) {
      if (!this.revealedHints.includes(index)) {
        this.revealedHints.push(index);
      }
    },
    isHintButtonDisabled(index) {
      if (index === 0) return false;
      return !this.revealedHints.includes(index - 1);
    },
  },
};
</script>

<style src="../assets/enigmecartedeux.css"></style>