<template>
  <div class="dashboard-login-container" v-show="dashboardData">
    <div class="dashboard-login-title">DASHBOARD</div>

    <div class="dashboard-login-left">
      
      <div class="dashboard-login-block dashboard-login-profile">
        <img 
          v-if="profilPicture" 
          :src="profilPicture" 
          alt="Profile Picture"
        >
        <p class="dynamic-text2 profile-name">{{ username }}</p>
      </div>

      <div class="dashboard-login-block">
        <p class="static-text">Clan:</p>
        <p class="dynamic-text text-center">
          {{ clanName ? clanName : 'Aucun clan' }}
        </p>
      </div>

      <div class="dashboard-login-block">
        <p class="static-text">CV:</p>
        <div class="cv-upload">
          <p v-if="cvFile">
            <strong>CV envoyé :</strong> Oui<br />
            <a :href="cvFile" target="_blank">Voir le CV</a>
          </p>
          <p v-else><strong>CV envoyé :</strong> Non</p>
          <input type="file" @change="onFileChange" />
          <button @click="uploadCV" :disabled="!selectedFile">Uploader CV</button>
          <p v-if="uploadError" class="error">{{ uploadError }}</p>
        </div>
      </div>

      <div class="dashboard-login-block-graph dashboard-login-graph">
        <canvas id="enigmesChart"></canvas>
      </div>
    </div>

    <div class="dashboard-login-right">
      <div class="dashboard-login-block">
        <p class="static-text">Nombre de points:</p>
        <p class="dynamic-text text-center dashboard-login-points">
          {{ dashboardData.score_solo }} Points
        </p>
      </div>

      <div class="dashboard-login-block dashboard-login-rank">
        <p class="static-text">Rank:</p>
        <p class="dynamic-text text-center">
          {{ rankName || 'Aucun rang' }}
          <img 
            v-if="rankImageUrl" 
            :src="rankImageUrl" 
            alt="Rank Image" 
            class="rank-img"
          >
        </p>
      </div>

      <div class="dashboard-login-block">
        <p class="static-text">Nombre de défis réalisés:</p>
        <p class="dynamic-text text-center dashboard-login-defis">
          {{ dashboardData.achieved_riddles_count }} Défis
        </p>
      </div>

      <div class="dashboard-login-block">
        <p class="static-text">Biographie:</p>
        <div v-if="isEditingBio">
          <!-- Champ d'édition -->
          <textarea
            v-model="editableBio"
            rows="4"
            class="edit-bio-textarea"
            placeholder="Écrivez votre biographie ici..."
          ></textarea>
          <div class="bio-buttons">
            <button @click="updateBio" class="btn-save-bio">Enregistrer</button>
            <button @click="cancelEditBio" class="btn-cancel-bio">Annuler</button>
          </div>
        </div>
        <div v-else>
          <!-- Affichage de la biographie -->
          <p class="dynamic-text text-center">
            {{ dashboardData.bio || 'Aucune biographie' }}
          </p>
          <button @click="enableEditBio" class="btn-edit-bio">Éditer</button>
        </div>
        <p v-if="bioUpdateError" class="error">{{ bioUpdateError }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import riddleServices from "../services/RiddleServices";
import cvServices from "../services/cvServices";
import userServices from "../services/UserServices";

export default {
  name: "DashboardLogin",
  data() {
    return {
      dashboardData: null,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      cvFile: null, // Lien vers le CV
      selectedFile: null, // Fichier sélectionné pour l'upload
      uploadError: "", // Erreur lors de l'upload
      isEditingBio: false, // État d'édition de la bio
      editableBio: "", // Biographie modifiable
      bioUpdateError: "", // Erreur lors de la mise à jour de la bio
    };
  },
  computed: {
    username() {
      return this.userInfo.username;
    },
    clanName() {
      return this.dashboardData?.clan_name ?? null;
    },
    rankName() {
      return this.dashboardData?.rank_name ?? null;
    },
    rankImageUrl() {
      return this.dashboardData?.rank_image_url 
        ? "https://kameleonback.jrcan.dev" + this.dashboardData.rank_image_url 
        : null;
    },
    profilPicture() {
      return this.userInfo?.profilePicture ?? null;
    },
  },
  methods: {
    async initDashboard() {
      try {
        const response = await riddleServices.fetchMemberDashboard(this.username);
        this.dashboardData = response.data;
        console.log("Dashboard data:", this.dashboardData);
        this.editableBio = this.dashboardData.bio || "";

        // Charger les informations du CV
        await this.fetchCV();

        this.$nextTick(() => {
          this.createPieChart();
        });
      } catch (error) {
        console.error("Erreur lors de la récupération du Dashboard", error);
      }
    },
    async fetchCV() {
      try {
        const response = await cvServices.getCV();
        this.cvFile = response.data.cv_file; // URL du CV
      } catch (error) {
        console.log("Aucun CV trouvé pour cet utilisateur.");
        this.cvFile = null;
      }
    },
    onFileChange(event) {
      this.selectedFile = event.target.files[0];
    },
    async uploadCV() {
      if (!this.selectedFile) return;

      try {
        await cvServices.uploadCV(this.selectedFile);
        alert("CV uploadé avec succès !");
        this.uploadError = "";
        await this.fetchCV(); // Actualiser les informations du CV après l'upload
        console.log("CV uploadé:", this.selectedFile.name);
      } catch (error) {
        console.error("Erreur lors de l'upload du CV :", error);
        this.uploadError = error.response?.data?.error || "Une erreur s'est produite lors de l'upload.";
      }
    },
    createPieChart() {
      if (!this.dashboardData) return;

      const themeDist = this.dashboardData.riddle_theme_distribution || {};
      const labels = Object.keys(themeDist);
      const dataValues = Object.values(themeDist);

      if (labels.length === 0) {
        return;
      }

      const ctx = document.getElementById('enigmesChart').getContext('2d');
      new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [{
            data: dataValues,
            backgroundColor: ['#ff00ff', '#00ff00', '#0000ff', '#808080'],
          }]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                color: '#fff',
                boxWidth: 20,
                boxHeight: 15,
              }
            }
          }
        }
      });
    },
    enableEditBio() {
      console.log("Bouton Éditer cliqué");
      this.isEditingBio = true;
      this.editableBio = this.dashboardData.bio || "";
    },
    cancelEditBio() {
      this.isEditingBio = false;
      this.bioUpdateError = "";
      console.log("Édition annulée");
    },
    async updateBio() {
      try {
        await userServices.updateBio({ bio: this.editableBio });
        this.dashboardData.bio = this.editableBio;
        this.isEditingBio = false;
        this.bioUpdateError = "";
        alert("Biographie mise à jour avec succès !");
        console.log("Biographie mise à jour:", this.editableBio);
      } catch (error) {
        console.error("Erreur lors de la mise à jour de la biographie :", error);
        this.bioUpdateError = error.response?.data?.error || "Une erreur s'est produite lors de la mise à jour.";
      }
    }
  },
  mounted() {
    this.initDashboard();
  }
};
</script>


<style src="../assets/dashboard_login.css"></style>
