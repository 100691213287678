<template>
  <div class="fond-footer">
  <div class="footer">
    <div class="footer-mentions">
        <h2><span>© 2025</span> KAMELEON.</h2>
        <a href="/mentions_legales" class="footer-nav-link-mention">Mentions légales</a>
        <br>
      <br>
      </div>
      
    <div class="footer-logo-container">
      <a href="/"><img src="../../public/img/logo_kameleon_k.png" alt="logo" class="footer-logo" /></a>
    </div>
    <div class="footer-content">
      
      <nav class="footer-nav">
        <RouterLink to="/" class="footer-nav-link">Accueil</RouterLink>
        <RouterLink to="/enigme" class="footer-nav-link">Énigmes</RouterLink>
        <RouterLink to="/clan" class="footer-nav-link">Clan</RouterLink>
        <RouterLink to="/versus" class="footer-nav-link">Versus</RouterLink>
        <RouterLink to="/faq" class="footer-nav-link">FAQ</RouterLink>
        <button class="footer-button">
          <RouterLink to="/contact" class="footer-button-link">Formulaire</RouterLink>
        </button>
      </nav>
      
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>

<style src="../assets/footer.css"></style>