<template>
  <div class="login_css">
    <div class="gauche_login">
      <img src="/img/login.png" alt="photo du login" />
    </div>
    <div class="droite_login">
      <img src="/img/logo_kameleon.png" alt="Logo du site" />
      
      <!-- Formulaire de connexion -->
      <form v-if="!isResetting" @submit.prevent="logIn" class="form_login">
        <p>Username/E-mail</p>
        <input
          type="text"
          class="icon-mail"
          v-model="usernameOrEmail"
          placeholder="Entrez votre Username/E-mail..."
        />
        <p>Mot de passe</p>
        <div class="password-container">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            placeholder="Entrez votre Mot de passe..."
          />
          <button type="button" @click="togglePasswordVisibility" class="toggle-password">
            <img :src="showPassword ? '/img/oeil.png' : '/img/oeil_ferme.png'" alt="Afficher/Masquer le mot de passe" />
          </button>
        </div>
        <br />
        <p>
          Vous n'avez pas de compte ? 
          <RouterLink to="/sign-in" class="login-color-text">Création</RouterLink>
        </p>
        <a href="#" @click.prevent="showResetForm" class="forgot-password-link">Mot de passe oublié?</a>
        <br />
        <button class="button_login" type="submit">Connexion</button>
        <br />
        
      </form>

      <!-- Formulaire de réinitialisation du mot de passe -->
      <form v-else @submit.prevent="resetPassword" class="form_reset">
        <p>Adresse E-mail</p>
        <input
          type="email"
          v-model="resetEmail"
          placeholder="Entrez votre adresse e-mail..."
          required
        />
        <br />
        <button class="button_reset" type="submit">Envoyer le lien de réinitialisation</button>
        <br />
        <a href="#" @click.prevent="hideResetForm" class="back-to-login-link">Retour à la connexion</a>
      </form>

      <!-- Formulaire de mise à jour du mot de passe via le lien de réinitialisation -->
      <div v-if="isUpdating" class="form_update">
        <p>Nouveau Mot de passe</p>
        <input
          type="password"
          v-model="newPassword"
          placeholder="Entrez votre nouveau mot de passe..."
          required
        />
        <p>Confirmer Mot de passe</p>
        <input
          type="password"
          v-model="confirmPassword"
          placeholder="Confirmez votre nouveau mot de passe..."
          required
        />
        <br />
        <button class="button_update" @click="updatePassword" type="button">Valider</button>
      </div>

      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: "AppLogin",
  data() {
    return {
      // Formulaire de connexion
      usernameOrEmail: '',
      password: '',
      showPassword: false,
      // Messages
      errorMessage: '',
      successMessage: '',
      // Réinitialisation du mot de passe
      isResetting: false,
      resetEmail: '',
      // Mise à jour du mot de passe
      isUpdating: false,
      newPassword: '',
      confirmPassword: '',
      resetToken: '',
      resetUserId: '',
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    showResetForm() {
      this.isResetting = true;
      this.errorMessage = '';
      this.successMessage = '';
    },
    hideResetForm() {
      this.isResetting = false;
      this.errorMessage = '';
      this.successMessage = '';
    },
    async logIn() {
      try {
        const response = await axios.post('https://kameleonback.jrcan.dev/api/login/', {
          username: this.usernameOrEmail,
          password: this.password,
        });

        const accessToken = response.data.access;

        // Stocker les tokens
        localStorage.setItem('accessToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);

        // Récupérer les informations de l'utilisateur
        const userResponse = await axios.get('https://kameleonback.jrcan.dev/api/user/', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const username = userResponse.data.username;
        const memberResponse = await axios.get(`https://kameleonback.jrcan.dev/api/members/${username}/dashboard/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        localStorage.setItem('userInfo', JSON.stringify({
          username: username,
          email: userResponse.data.email,
          profilePicture: "https://kameleonback.jrcan.dev" + userResponse.data.profile_picture,
          rank: userResponse.data.rank,
          createdAt: userResponse.data.created_at,
          clan: memberResponse.data.clan_name,
          isAdmin: userResponse.data.is_staff,
        }));

        this.successMessage = 'Connexion réussie. Redirection...';
        this.errorMessage = '';

        setTimeout(() => {
          this.$router.push('/dashboard_login');
        }, 500);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } catch (error) {
        this.errorMessage = error.response?.data?.error || "Une erreur s'est produite.";
        this.successMessage = '';
      }
    },
    async resetPassword() {
      try {
        const response = await axios.post('https://kameleonback.jrcan.dev/password-reset/', {
          email: this.resetEmail,
        });
        console.log(response);
        this.successMessage = 'Un e-mail de réinitialisation a été envoyé.';
        this.errorMessage = '';
      } catch (error) {
        this.errorMessage = error.response?.data?.error || "Une erreur s'est produite.";
        this.successMessage = '';
      }
    },
  },
  mounted() {
    // Vérifier si les paramètres uid et token sont présents dans l'URL
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get('uid');
    const token = urlParams.get('token');

    if (uid && token) {
      this.isUpdating = true;
      this.resetUserId = uid;
      this.resetToken = token;
    }
  },
};
</script>


<style src="../assets/login.css"></style>
