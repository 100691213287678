<template>
  <CalculatriceVue />
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="equations-puzzle">
    <h1 class="enigme8_titre">Énigme N°9</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
        <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
        <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
      </p>
    <h2>Résolution d'un système de 3 équations</h2>
    <p>
      "Trouvez les valeurs de x, y, et z qui satisfont le système d'équations suivant
      (tous les coefficients sont choisis aléatoirement entre 0 et 40)."
    </p>

    <!-- Section affichant les équations générées -->
    <div v-if="coeffs && solution" class="equations-display">
      <h3>Système :</h3>
      <p>
        {{ eq1.a }}x + {{ eq1.b }}y + {{ eq1.c }}z = {{ eq1.d }}
      </p>
      <p>
        {{ eq2.a }}x + {{ eq2.b }}y + {{ eq2.c }}z = {{ eq2.d }}
      </p>
      <p>
        {{ eq3.a }}x + {{ eq3.b }}y + {{ eq3.c }}z = {{ eq3.d }}
      </p>
    </div>

    <button @click="generateSystem" class="generate-button-equation">
      Générer de nouvelles équations
    </button>

    <!-- Champs pour la réponse utilisateur -->
    <div class="answer-section" v-if="coeffs && solution">
      <h4>Vos réponses :</h4>
      <label for="xInput">x :</label>
      <input id="xInput" type="number" step="any" v-model.number="userX" />

      <label for="yInput">y :</label>
      <input id="yInput" type="number" step="any" v-model.number="userY" />

      <label for="zInput">z :</label>
      <input id="zInput" type="number" step="any" v-model.number="userZ" />
      <br><br>
      <button @click="validateSolution" class="validate-button-equation">
        Valider
      </button>
    </div>

    <div class="hint-section">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
    </div>

    <!-- Afficher les indices demandés -->
    <div class="hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>

    <!-- Message de validation -->
    <div class="validation-message-equation" v-if="message">
      <p>{{ message }}</p>
    </div>
    <div class="quote-box">
      <p>" Vous voilà devant la maison abandonnée, l’exacte même que sur la photographie, vous entrez. Devant vous, une table et un coffre sécurisé
        "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import CalculatriceVue from "./Calculatrice.vue";
/**
 * Exemple d'une fonction de résolution de 3 équations à 3 inconnues
 * par la méthode du déterminant (Cramer's Rule), simple et direct.
 */
function solve3x3(eq1, eq2, eq3) {
  // On veut résoudre :
  // eq1.a*x + eq1.b*y + eq1.c*z = eq1.d
  // eq2.a*x + eq2.b*y + eq2.c*z = eq2.d
  // eq3.a*x + eq3.b*y + eq3.c*z = eq3.d

  // Calcul du déterminant principal
  const D = 
      eq1.a * (eq2.b * eq3.c - eq2.c * eq3.b)
    - eq1.b * (eq2.a * eq3.c - eq2.c * eq3.a)
    + eq1.c * (eq2.a * eq3.b - eq2.b * eq3.a);

  // Pour éviter la division par zéro
  if (D === 0) {
    return null; // on pourrait retourner un objet spécial si le système est indéterminé ou impossible
  }

  // Dét. Dx
  const Dx =
    eq1.d * (eq2.b * eq3.c - eq2.c * eq3.b)
    - eq1.b * (eq2.d * eq3.c - eq2.c * eq3.d)
    + eq1.c * (eq2.d * eq3.b - eq2.b * eq3.d);

  // Dét. Dy
  const Dy =
    eq1.a * (eq2.d * eq3.c - eq2.c * eq3.d)
    - eq1.d * (eq2.a * eq3.c - eq2.c * eq3.a)
    + eq1.c * (eq2.a * eq3.d - eq2.d * eq3.a);

  // Dét. Dz
  const Dz =
    eq1.a * (eq2.b * eq3.d - eq2.d * eq3.b)
    - eq1.b * (eq2.a * eq3.d - eq2.d * eq3.a)
    + eq1.d * (eq2.a * eq3.b - eq2.b * eq3.a);

  const x = Dx / D;
  const y = Dy / D;
  const z = Dz / D;

  return { x, y, z };
}

export default {
  components: {
    CalculatriceVue,
  },
  data() {

    return {
      hints: { 1: false, 2: false, 3: false },
      hintsText: "",
      // eq1, eq2, eq3 stockeront les coefficients a, b, c, d pour chaque équation
      eq1: null,
      eq2: null,
      eq3: null,
      solution: null, // la solution calculée par le résolveur
      columns: 3, 
      // user input
      userX: null,
      userY: null,
      userZ: null,
      message: "",
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  computed: {
    coeffs() {
      // Renvoie true si on a bien eq1, eq2, eq3
      return this.eq1 && this.eq2 && this.eq3;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(9);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(9, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(9, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(9, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(9, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 9);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },

    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(9, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    generateSystem() {
      // Génération aléatoire de coefficients entre 0 et 40
      // On s'assure de ne pas avoir un système trivial (par ex. tout 0)
      const getRand = () => Math.floor(Math.random() * 10); // [0..40]

      this.eq1 = {
        a: getRand(), b: getRand(), c: getRand(), d: getRand(),
      };
      this.eq2 = {
        a: getRand(), b: getRand(), c: getRand(), d: getRand(),
      };
      this.eq3 = {
        a: getRand(), b: getRand(), c: getRand(), d: getRand(),
      };

      // Résoudre via la fonction solve3x3
      const result = solve3x3(this.eq1, this.eq2, this.eq3);
      if (result) {
        this.solution = result; // {x, y, z}
      } else {
        // Système indéterminé ou sans solution
        this.solution = null;
      }
      // Reset user inputs & message
      this.userX = null;
      this.userY = null;
      this.userZ = null;
      this.message = "";
      console.log(this.solution);
    },
    async validateSolution() {
      // Vérifier si solution est null (alors on a un système non résolu)
      if (!this.solution) {
        this.message =
          "Le système généré est indéterminé ou impossible, veuillez regénérer un autre système.";
        return;
      }
      // Comparer userX, userY, userZ avec solution.x, solution.y, solution.z
      // On peut accepter une certaine tolérance
      const tol = 0.0001;

      const correctX = Math.abs(this.userX - this.solution.x) < tol;
      const correctY = Math.abs(this.userY - this.solution.y) < tol;
      const correctZ = Math.abs(this.userZ - this.solution.z) < tol;

      if (correctX && correctY && correctZ) {
        const response = await riddleServices.IsRiddleSolved(9, "equation");
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        }
      } else {
        this.message = "Ce n’est pas la bonne solution. Réessayez ou regénérez un autre système.";
        this.incrementErrors();
        this.errors_count += 1;
      }
    },
  },
  mounted() {
    // Générer un premier système au montage
    this.generateSystem();
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
};
</script>

<style src="../assets/enigmeEquation.css"></style>
