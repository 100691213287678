<template>
    <div id="app" class="faq-page">
        <br>
        <div class="faq-container">
            <div class="title-faq">
                <h1>FAQ's</h1>
            </div>
            <details>
                <summary>Comment se créer un compte ?</summary>
                <div class="faq-content">
                    Cliquez sur le bouton "Se connecter" dans le menu en haut de la page, puis sur le lien "Création".
                    Remplissez le formulaire, puis cliquez sur le bouton "Création" pour finaliser l'inscription.
                </div>

            </details>
            <details>
                <summary>Comment se connecter ?</summary>
                <div class="faq-content">
                    Cliquez sur le bouton "Se connecter" dans le menu en haut de la page.
                    Saisissez ensuite votre identifiant et votre mot de passe, puis validez en cliquant sur le bouton
                    "Connexion". </div>
            </details>
            <details>
                <summary>Comment nous contacter ?</summary>
                <div class="faq-content">
                    En bas de la page, cliquez sur le bouton "Formulaire" dans la rubrique Contact.
                    Une fois redirigé(e), remplissez le formulaire en décrivant le problème rencontré ou en posant votre
                    question, puis cliquez sur le bouton "Envoyer".
                </div>
            </details>
            <details>
                <summary>Utilisable sur telephone ?</summary>
                <div class="faq-content">
                    Le site n’a pas été conçu pour une utilisation sur téléphone. Il est donc recommandé de l’utiliser
                    sur un ordinateur.
                    Toutefois, certaines pages peuvent être accessibles depuis un téléphone, mais certaines
                    fonctionnalités pourraient ne pas être disponibles.
                </div>

            </details>
            <details>
                <summary>Comment modifier son Pseudo ?</summary>
                <div class="faq-content">
                    Une fois connecté(e), cliquez sur votre nom d’utilisateur en haut à droite de la page.
                    Dans la section "Profil", cliquez sur le bouton "Modifier pseudo" à côté de votre pseudo.
                    Saisissez le nouveau pseudo souhaité, puis cliquez sur "Valider".
                </div>

            </details>
            <details>
                <summary>Quels navigateurs sont compatibles avec le site ?</summary>
                <div class="faq-content">
                    Le site est compatible avec les navigateurs modernes comme Google Chrome, Mozilla Firefox, Microsoft
                    Edge.
                    Nous vous recommandons de maintenir votre navigateur à jour pour une meilleure expérience
                    utilisateur.
                </div>
            </details>

            <details>
                <summary>Comment supprimer mon compte ?</summary>
                <div class="faq-content">
                    Pour supprimer votre compte, contactez notre service client en utilisant le formulaire de contact
                    en bas de la page.
                    Indiquez que vous souhaitez supprimer votre compte, et notre équipe vous guidera dans la procédure
                    à suivre.
                </div>
            </details>

            <details>
                <summary>Le site est-il sécurisé ?</summary>
                <div class="faq-content">
                    Oui, le site utilise une connexion sécurisée (HTTPS) et protège vos données personnelles grâce à des
                    protocoles de sécurité conformes aux normes actuelles.
                    Nous ne partageons jamais vos informations avec des tiers sans votre consentement.
                </div>
            </details>

            <details>
                <summary>Comment signaler un bug ?</summary>
                <div class="faq-content">
                    Si vous rencontrez un problème, cliquez sur le bouton "Formulaire" dans la rubrique Contact, en bas
                    de la page.
                    Décrivez le problème rencontré en détail (étapes pour reproduire, messages d’erreur, etc.) et
                    cliquez sur "Envoyer".
                    Notre équipe technique vous répondra dès que possible.
                </div>
            </details>
        </div>

    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style src="../assets/faq.css"></style>