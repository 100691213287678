<template>
  <div class="dashboard-clan">
    <h1 class="dashboard-title">Dashboard du Clan</h1>

    <div class="clan-info">
      <div class="clan-header">
        <h2>{{ clanData.name }}</h2>
        <p>Taille: {{ clanData.size }} membres</p>
        <img :src="clanData.picture" alt="Image du clan" class="clan-image" />
      </div>

      <div class="clan-members">
        <h3>Membres du clan</h3>
        <ul>
          <li 
            v-for="member in clanData.members" 
            :key="member.id" 
            class="member-item"
          >
            <span :class="{ 'leader-name': member.is_clan_admin }">{{ member.username }}</span>
            <span>{{ member.rank }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="clan-stats">
      <h3>Statistiques du Clan</h3>
      <p>Nombre total d'énigmes réalisées : {{ clanData.totalRiddlesCompleted }}</p>
      <canvas id="clanStatsChart"></canvas>
    </div>

    <div class="clan-challenges">
      <h3>Détails des membres</h3>
      <ul>
        <li 
          v-for="(member, index) in clanData.members" 
          :key="index"
          class="member-details"
        >
          <div class="member-info">
            <h4>{{ member.username }}</h4>
            <p>Score : {{ member.score }}</p>
          </div>
          <canvas :id="`memberChart-${index}`" class="member-chart"></canvas>
        </li>
      </ul>
    </div>
    <!-- Bouton pour ouvrir/fermer le chat -->
    <button class="toggle-chat-button" @click="toggleChat">
      {{ isChatOpen ? '>' : '<' }}
    
    </button>

    <!-- Container qui "slide" depuis la droite -->
    <div 
      class="chat-slide-wrapper" 
      :class="{ open: isChatOpen }"
    >
      <ChatBox />
    </div>
</div>
</template>

<script>
import { ref, onMounted } from "vue";
import Chart from "chart.js/auto";
import clanServices from "../services/ClanServices";
import ChatBox from "./ChatBox.vue";

export default {
  name: "ClanDashboard",
  components: {
    ChatBox,
  },
  setup() {
    const clanData = ref({
      name: "",
      size: 0,
      bio: "",
      totalPoints: 0,
      members: [],
      riddles: [],
      riddleThemeDistribution: {},
      totalRiddlesCompleted: 0,
    });

    // booléen pour gérer l'ouverture/fermeture du chat
    const isChatOpen = ref(false);

    const toggleChat = () => {
      isChatOpen.value = !isChatOpen.value;
    };

    const fetchClanData = async () => {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const response = await clanServices.getDetailClanInfo(userInfo.clan);
        console.log(response.data);
        clanData.value = {
          name: response.data.clan.name,
          size: response.data.clan.members_count,
          totalPoints: response.data.clan.elo,
          bio: response.data.clan.bio,
          members: response.data.members,
          picture: "https://kameleonback.jrcan.dev" + response.data.clan.picture,
          riddleThemeDistribution: response.data.riddle_theme_distribution,
          totalRiddlesCompleted: response.data.clan.total_riddles_completed,
        };
        createClanChart();
      } catch (error) {
        console.error("Erreur lors de la récupération des données du clan", error);
      }
    };

    const createClanChart = () => {
      const ctx = document.getElementById("clanStatsChart").getContext("2d");
      const distribution = clanData.value.riddleThemeDistribution;

      new Chart(ctx, {
        type: "pie",
        data: {
          labels: Object.keys(distribution),
          datasets: [
            {
              data: Object.values(distribution),
              backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              display: true,
            },
            title: {
              display: true,
              text: "Répartition des thèmes des énigmes résolues",
            },
          },
        },
      });
    };

    const createMemberCharts = () => {
      clanData.value.members.forEach((member, index) => {
        const ctx = document.getElementById(`memberChart-${index}`).getContext("2d");
        const distribution = member.riddle_theme_distribution;

        new Chart(ctx, {
          type: "pie",
          data: {
            labels: Object.keys(distribution),
            datasets: [
              {
                data: Object.values(distribution),
                backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
              },
            ],
          },
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: true,
              },
              title: {
                display: true,
                text: `Répartition des thèmes : ${member.username}`,
              },
            },
          },
        });
      });
    };

    onMounted(() => {
      fetchClanData().then(() => {
        createMemberCharts();
      });
    });

    return {
      clanData,
      isChatOpen,
      toggleChat,
    };
  },
  methods: {
    totalRiddles(member) {
      const distribution = member.riddle_theme_distribution;
      return Object.values(distribution).reduce((sum, percentage) => sum + percentage, 0);
    },
  },
};
</script>

<style src="../assets/dashboard_clan.css"></style>