<template>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div id="enigme6_app">
    <h1 class="enigme6_titre">Énigme N°8</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <br>
    <div id="enigme6_ordinateur">
      <div
        class="enigme6_lock-screen"
        :class="{ unlocked: isPasswordCorrect }">
        <div class="enigme6_lock-screen-content" v-if="!isPasswordCorrect || showPasswordMessage">
          <h2>Écran verrouillé</h2>
          <input
            type="text"
            placeholder="Entrez le mot de passe"
            class="enigme6_password-input"
            v-model="maskedPassword"
            readonly
          />
          <div v-if="isPasswordCorrect" class="enigme6_password-message">
            Bon mot de passe
          </div>
          <div
            v-if="isPasswordIncorrect"
            class="enigme6_password-message incorrect"
          >
            Mauvais mot de passe
          </div>
        </div>
      </div>
      <!-- Boutons pour demander les indices -->
      <div class="hints-section">
        <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
        <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      </div>

      <!-- Afficher les indices demandés -->
      <div class="hint-text">
        <p v-if="hintsText">{{ hintsText }}</p>
      </div>
    </div>

    <div id="enigme6_python-interpreter">
      <div id="enigme6_consigne">
        <p>
          Ecrire un algorithme bruteforce pour trouver le mot de passe à 4
          chiffres.
        </p>
      </div>
      <div id="enigme6_actions">
        <button @click="resetCode">Réinitialiser</button>
        <button @click="executeCode">Exécuter</button>
      </div>
      <div class="enigme8-validation-message" v-if="feedback">
        <p>{{ feedback }}</p>
      </div>
      <div class="enigme6_editor-output-container">
        <div class="enigme6_code-section">
          <h3>Code</h3>
          <textarea
            v-model="code"
            placeholder="Ecrivez votre code Python ici..."
            @keydown="handleKeydown"
            class="enigme6_code-editor"
            spellcheck="false"
          ></textarea>
        </div>
        <div class="enigme6_output-section">
          <h3>Output</h3>
          <div class="enigme6_output-terminal">
            <pre>{{ output }}</pre>
          </div>
        </div>
      </div>
    </div>
    <div class="quote-box">
      <p>" Vous trouvez dans l’ordinateur un fichier texte protégé par un mot de passe numérique, aucun indice sur le côté, vous voilà bien embarrassé
        "</p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import riddleServices from "../services/RiddleServices";

export default defineComponent({
  name: "EnigmeSix",
  data() {
    return {
      hints: { 1: false, 2: false, 3: false },
      hintsText: "",
      code: "", // Le code Python écrit par l'utilisateur
      output: "", // La sortie du code Python
      pyodideInstance: null, // Instance Pyodide
      isPasswordCorrect: false, // Indicateur de mot de passe correct
      isPasswordIncorrect: false, // Indicateur de mot de passe incorrect
      maskedPassword: "", // Mot de passe masqué pour l'animation
      showPasswordMessage: false, // Indicateur pour afficher le message de mot de passe
      isUnlocked: false, // Indicateur pour déverrouiller l'écran
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(8);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(8, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(8, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(8, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(8, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(8, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 8);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },
    async loadPyodide() {
      try {
        const script = document.createElement("script");
        script.src = "https://cdn.jsdelivr.net/pyodide/v0.23.0/full/pyodide.js";
        script.onload = async () => {
          this.pyodideInstance = await window.loadPyodide({
            indexURL: "https://cdn.jsdelivr.net/pyodide/v0.23.0/full/",
          });

          // Rediriger stdout et stderr
          this.pyodideInstance.globals.set("print_output", (text) => {
            this.output += text + "\n"; // Ajoute la sortie dans la zone
          });
        };
        document.head.appendChild(script);
      } catch (error) {
        this.output = "Erreur : Impossible de charger Pyodide.";
      }
    },
    async executeCode() {
      if (!this.pyodideInstance) {
        this.output = "Erreur : Pyodide n'est pas encore chargé.";
        return;
      }
      try {
        // Réinitialise la sortie
        this.output = "";
        this.isPasswordCorrect = false;
        this.isPasswordIncorrect = false;
        this.showPasswordMessage = false;

        const correctedCode = this.code.replace(/\t/g, "    ");

        // Préparation du code pour capturer stdout
        const codeWithPrint = `
    import sys
    class OutputCatcher:
        def __init__(self):
            self.content = []
        def write(self, text):
            if text.strip():
                self.content.append(text.strip())
                print_output(text)
        def flush(self):
            pass

    output_catcher = OutputCatcher()
    sys.stdout = output_catcher
    sys.stderr = output_catcher

    # Code utilisateur
    ${correctedCode}

    output_catcher.content
    `;

        // Exécuter le code Python et capturer le résultat
        const result = await this.pyodideInstance.runPythonAsync(codeWithPrint);
        const outputLines = result.toJs(); // Convertir en tableau JavaScript

        // Formater en JSON attendu
        const formattedOutput = outputLines.reduce((acc, line, index) => {
          acc[(index + 1).toString()] = line.toString();
          return acc;
        }, {});

        // Envoyer au backend
        await this.checkPassword(formattedOutput);
      } catch (error) {
        this.output += `Erreur : ${error.message}`;
      }
    },
    resetCode() {
      this.code = "";
      this.output = "";
      this.isPasswordCorrect = false;
      this.isPasswordIncorrect = false;
      this.maskedPassword = "";
      this.showPasswordMessage = false;
    },
    handleKeydown(event) {
      if (event.key === "Tab") {
        event.preventDefault(); // Empêche le comportement par défaut de Tab (changement de focus)
        const textarea = event.target;
        const start = textarea.selectionStart;
        const end = textarea.selectionEnd;

        // Ajouter 4 espaces à l'emplacement du curseur
        const newValue =
          this.code.substring(0, start) +
          "     " +
          this.code.substring(end);

        this.code = newValue;

        // Ajuster la position du curseur
        this.$nextTick(() => {
          textarea.selectionStart = textarea.selectionEnd = start + 5;
        });
      }
    },
    async checkPassword(text) {
      try {
        // Get the response data
        const data = await riddleServices.IsRiddleSolved(8, text);
        if (data.is_solved) {
          this.feedback = "Bravo ! Vous avez résolu l'énigme ! Rédirection dans 2 secondes..."; // Send feedback
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.feedback = "Mauvaise réponse. Réessayez !"; // Send feedback
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("Une erreur est survenue. Veuillez réessayer.");
      }
    },
    startBruteForceAnimation() {
      const duration = 1500; // Durée de l'animation en millisecondes
      const interval = 100; // Intervalle entre chaque mise à jour en millisecondes
      const endTime = Date.now() + duration;
      let currentLength = 0;

      const updatePassword = () => {
        if (Date.now() < endTime) {
          this.maskedPassword = "*".repeat((currentLength % 4) + 1);
          currentLength++;
          setTimeout(updatePassword, interval);
        } else {
          this.maskedPassword = "";
          this.checkPassword(this.output); // Vérifie le mot de passe après l'animation
        }
      };

      updatePassword();
    },
  },
  async mounted() {
    await this.loadPyodide();
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
});
</script>

<style src="../assets/enigmeOrdinateur.css"></style>
