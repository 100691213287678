<template>
  <CalculatriceVue v-if="calculatrice" />
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="hill-puzzle-container">
    <!-- <div class="enonce">
        <img :src="imageSource" alt="Enigme" class="puzzle-image" />
      </div> -->

    <!-- Matrice Inverse de A (2x2) -->
    <h1 id="title-11">Énigme N°11</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
        <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
        <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
      </p>
    <div class="matrix-all">
      <div class="matrix-section">
        <h3>Matrice Inv(A) :</h3>
        <div class="matrix-line">
          <input v-model.number="invA[0][0]" type="number" class="matrix-input" />
          <input v-model.number="invA[0][1]" type="number" class="matrix-input" />
        </div>
        <div class="matrix-line">
          <input v-model.number="invA[1][0]" type="number" class="matrix-input" />
          <input v-model.number="invA[1][1]" type="number" class="matrix-input" />
        </div>
      </div>

      <!-- Matrice B = A x Inv(A) mod 26 -->
      <div class="matrix-section">
        <h3 id="h3_matrice_B">Matrice B = A × Inv(A) (mod 26) :</h3>
        <div class="matrix-line">
          <input v-model.number="matB[0][0]" type="number" class="matrix-input" />
          <input v-model.number="matB[0][1]" type="number" class="matrix-input" />
        </div>
        <div class="matrix-line">
          <input v-model.number="matB[1][0]" type="number" class="matrix-input" />
          <input v-model.number="matB[1][1]" type="number" class="matrix-input" />
        </div>
      </div>
    </div>

    <!-- Module de déchiffrement : une paire de lettres à la fois -->
    <div class="decrypt-pair-section">
      <h3>Déchiffrer une paire :</h3>
      <label>Paire : </label>
      <input type="text" maxlength="2" v-model="decryptPair" class="pair-input" />
      <button @click="decryptOnePair" class="action-button">Déchiffrer</button>
      <p v-if="pairDecryptedResult !== ''">
        Résultat : <strong>{{ pairDecryptedResult }}</strong>
      </p>
    </div>

    <!-- L'utilisateur recopie ensuite tout le texte déchiffré final -->
    <div class="answer-section">
      <h3>Votre texte final :</h3>
      <input type="text" v-model="userPlaintext" class="plaintext-input"
        placeholder="Tapez le texte déchiffré complet" />
      <button @click="validateFinal" class="validate-button">Valider</button>
    </div>

    <div class="validation-message" v-if="message">
      <p>{{ message }}</p>
      <p>AJOUTER UNE TABLE DE SUBSTITUTION AVEC A = 0 JUSQUE Z = 26</p>
    </div>

    <div class="hint-section">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
      <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
    </div>

    <!-- Afficher les indices demandés -->
    <div class="hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>
    <div class="quote-box">
    <p>" Vous voilà devant un grand château, une immense porte vous barre la route. Des nombres sont inscrits dessus ainsi que quelques phrases.
      "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import UserServices from "../services/UserServices";
import CalculatriceVue from "./Calculatrice.vue";
function charToNum(c) {
  return c.toUpperCase().charCodeAt(0) - 65;
}
function numToChar(n) {
  return String.fromCharCode(((n % 26) + 26) % 26 + 65);
}

export default {
  components: {
    CalculatriceVue,
  },
  data() {
    return {
      hints: {
        1: false,
        2: false,
        3: false,
      },
      hintsText: "",
      // Texte chiffré imposé
      ciphertext: "ZAITMYNPRJZADW",
      // Matrice Inv(A) à saisir
      // On s’attend à (17, -5; -6, 3) en notation math, 
      // c.-à-d. indices [0][0] =17, [0][1] = -5, etc. (mod 26).
      invA: [
        [null, null],
        [null, null],
      ],
      // Matrice B = A * Inv(A) mod 26 à saisir
      // On s’attend à (7,1;22,15)
      matB: [
        [null, null],
        [null, null],
      ],
      // Paires à déchiffrer
      decryptPair: "",
      pairDecryptedResult: "",
      // L'utilisateur recopie son texte final
      userPlaintext: "",
      // Le texte clair attendu (à personnaliser)
      message: "",
      imageSource: "/img/enigme11.png",
      calculatrice: false,
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.haveCalculatrice();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(11);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(11, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(11, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(11, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(11, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async haveCalculatrice() {
      try {
        const response = await UserServices.haveCalculatrice();
        if (response) {
          this.calculatrice = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la calculatrice :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(11, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 11);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },
    decryptOnePair() {
      // Prendre la paire, la convertir
      const pair = this.decryptPair.toUpperCase().replace(/[^A-Z]/g, "");
      if (pair.length < 2) {
        this.pairDecryptedResult = "Paire invalide (2 lettres requises).";
        return;
      }
      // Extraire invA
      const [a, b] = this.invA[0];
      const [c, d] = this.invA[1];
      if (a === null || b === null || c === null || d === null) {
        this.pairDecryptedResult = "Matrice Inv(A) incomplète !";
        return;
      }

      const [e, f] = this.matB[0];
      const [g, h] = this.matB[1];
      if (e === null || f === null || g === null || h === null) {
        this.pairDecryptedResult = "Matrice B incomplète !";
        return;
      }

      // Convertir la paire
      const v1 = charToNum(pair[0]);
      const v2 = charToNum(pair[1]);
      // Calcul mod 26
      let r1 = (e * v1 + f * v2);
      let r2 = (g * v1 + h * v2);
      r1 = ((r1 % 26) + 26) % 26;
      r2 = ((r2 % 26) + 26) % 26;
      const dch = numToChar(r1) + numToChar(r2);
      this.pairDecryptedResult = dch;
    },
    async validateFinal() {
      // Compare userPlaintext au texte clair attendu
      const userUp = this.userPlaintext.toUpperCase().replace(/[^A-Z]/g, "");
      try {
        const response = await riddleServices.IsRiddleSolved(11, userUp);
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>


<style src="../assets/enigmeHill.css"></style>