// services/CoopServices.js

import axios from 'axios';

const API_BASE_URL = 'https://kameleonback.jrcan.dev'; // Remplacez par l'URL de votre API si différent

// Fonction pour obtenir le token d'accès
const getAccessToken = () => {
  return localStorage.getItem('accessToken');
};

// Instance Axios avec configuration de base
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Intercepteur pour ajouter le token d'accès à chaque requête
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default {
  // Inviter un membre à une coopérative pour une énigme spécifique
  inviteMemberToCoop(riddle_id, invitee_username) {
    return axiosInstance.post('api/clans/invite/', {
      riddle_id,
      invitee_username,
    });
  },

  // Répondre à une invitation (accept ou reject)
  respondToInvitation(invitation_id, response) {
    return axiosInstance.post(`/api/coop/invitations/${invitation_id}/respond/`, {
      response, // 'accept' ou 'reject'
    });
  },

  // Récupérer les membres connectés d'une coopérative pour une énigme spécifique
  fetchConnectedMembers(riddle_id) {
    return axiosInstance.get(`/api/coop/members/${riddle_id}/`);
  },

  // Récupérer les invitations reçues par l'utilisateur
  fetchReceivedInvitations() {
    return axiosInstance.get('/api/clans/invitations/received/');
  },
};
