<template>
  <CalculatriceVue v-if="calculatrice" />
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="wolseley-puzzle-container">
    <h2 class="enigme15-title">Énigme N°14</h2>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>

    <!-- Énoncé -->
    <div class="enonce">
      <p>
        Devant César, il jette ses armes et se rend. Garnet Joseph aurait pu s'en servir 
        comme clef pour dissimuler son chiffre...
      </p>
    </div>

    <!-- Message crypté -->
    <div class="crypted-section">
      <h3 class="enigme15-h3">Message crypté :</h3>
      <p class="crypted-text">
        {{ cryptedMessage }}
      </p>
    </div>

    <!-- Tableau "Escargot de Wolseley" à compléter -->
    <div class="escargot-section">
      <h3 class="enigme15-h3">Escargot de Wolseley (complétez la spirale) :</h3>
      <div class="escargot-grid">
        <div 
          v-for="(cell, index) in 25"
          :key="index"
          class="escargot-cell"
          :class="getCellClass(index)"
        >
          <input
            type="text"
            v-model="wolseleyTable[index]"
            maxlength="1"
            class="cell-input"
          />
        </div>
      </div>
      <!-- Bouton pour vérifier la complétion de l'escargot -->
      <button @click="checkEscargot" class="check-button">
        Vérifier l'escargot
      </button>
      <p v-if="escargotMessage" class="escargot-message">{{ escargotMessage }}</p>
    </div>
    <!-- Champ pour la réponse finale -->
    <div class="answer-section">
      <h3>Votre réponse :</h3>
      <input
        type="text"
        v-model="userAnswer"
        placeholder="Entrez le texte décrypté ou le mot clé"
        class="answer-input"
      />
      <button @click="validateAnswer" class="validate-button">
        Valider
      </button>
    </div>
    <!-- Message de validation finale -->
    <div class="validation-message" v-if="finalMessage">
      <p>{{ finalMessage }}</p>
    </div>

    <div class="hint-section">
      <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
      <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2] }">Indice 3</button>
    </div>

    <!-- Afficher les indices demandés -->
    <div class="hints">
      <p v-if="hintsText">{{ hintsText }}</p>
    </div>
    <div class="quote-box">
    <p>" La porte ne s’ouvre pas mais un petit papier tombe au sol, vous le ramassez
      "</p>
    </div>
  </div>
</template>

<script>
import CalculatriceVue from "./Calculatrice.vue";
import riddleServices from "../services/RiddleServices";
import UserServices from "../services/UserServices";
export default {
  components: {
    CalculatriceVue,
  },
  data() {
    return {
      // Le message crypté
      cryptedMessage: "SQCMSOY DY TY UAYWUAYW",

      // L'escargot de Wolseley : 25 cases
      wolseleyTable: new Array(25).fill(""),

      // Indicateur textuel après vérif de l'escargot
      escargotMessage: "",

      hints: {
        1: false,
        2: false,
        3: false,
      },
      hintsText: "",

      // Réponse de l'utilisateur
      userAnswer: "",

      finalMessage: "",

      // Définition de la spirale (ordre des indexes de 0 à 24)
      // par exemple, on part du haut-gauche, on va à droite, puis on descend, 
      // on va à gauche, etc. en spirale jusqu'au centre
      snailIndices: [
        0,1,2,3,4, 9,14,19,24, 23,22,21,20, 15,10,5, 6,7,8, 13,18,17,16,11,12
      ],

      correctIndicesColors: {
        blue: [0, 1, 2, 3, 4, 9, 11, 14, 16, 17, 18, 19],
        lightBlue: [5, 6, 7, 8, 10, 13, 15, 20, 21, 22, 23, 24],
        white: [12]
      },
      calculatrice: false,
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  mounted() {
    this.haveCalculatrice();
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
    
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(14);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(14, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(14, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(14, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(14, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    // Renvoie la classe CSS selon la position de 'index' dans la spirale
    getCellClass(index) {
      if (this.escargotValidated) {
        if (this.correctIndicesColors.blue.includes(index)) {
          return "dark-blue";
        } else if (this.correctIndicesColors.lightBlue.includes(index)) {
          return "light-blue";
        } else if (this.correctIndicesColors.white.includes(index)) {
          return "center-red";
        }
      }
      return "";
    },
    // Vérifier l'escargot
    checkEscargot() {
      // Vérifier qu'il n'y a pas de vide
      if (this.wolseleyTable.some((cell) => !cell)) {
        this.escargotMessage = "Il reste des cases vides, complétez tout d'abord.";
        return;
      }
      // Vérifier qu'on a 25 lettres distinctes 
      const tableLetters = this.wolseleyTable.map((c) => c.toUpperCase());
      const unique = new Set(tableLetters);
      if (unique.size < 25) {
        this.escargotMessage = "Des doublons sont présents ou il manque des lettres.";
        return;
      }

      // Validation réussie
      this.escargotValidated = true;
      this.escargotMessage = "Votre escargot semble correct ou du moins complet.";
    },
    async haveCalculatrice() {
      try {
        const response = await UserServices.haveCalculatrice();
        if (response) {
          console.log("calculatrice");
          this.calculatrice = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la calculatrice :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(14, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 14);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },

    // Validation
    async validateAnswer() {
      const userUp = this.userAnswer.toUpperCase().trim();
      try {
        const response = await riddleServices.IsRiddleSolved(14, userUp);
        if (response.is_solved) {
          this.finalMessage = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.finalMessage = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style src="../assets/enigmewolseley.css"></style>
