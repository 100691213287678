<template>
  <div class="signIn">
    <div class="gauche_signIn">
      <img src="/img/login.png" alt="photo du login" />
    </div>
    <div class="droite_signIn">
      <img src="/img/logo_kameleon.png" alt="Logo du site" />
      <form @submit.prevent="signUp" class="form_signIn">
        <p>Username</p>
        <input
          type="user"
          class="icon-user"
          v-model="username"
          placeholder="Entrez votre Username..."
        />
        <p>E-mail</p>
        <input
          type="e-mail"
          class="icon-mail"
          v-model="email"
          placeholder="Entrez votre E-mail..."
        />
        <p>Mot de passe</p>
        <div class="password-container">
          <input
            :type="showPassword ? 'text' : 'password'"
            v-model="password"
            placeholder="Entrez votre Mot de passe..."
          />
          <button type="button" @click="togglePasswordVisibility" class="toggle-password">
            <img :src="showPassword ? '/img/oeil.png' : '/img/oeil_ferme.png'" alt="Afficher/Masquer le mot de passe" />
          </button>
        </div>

        <p>Confirmez votre mot de passe</p>
        <div class="password-container">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            v-model="confirmPassword"
            placeholder="Confirmez votre Mot de passe..."
          />
          <button type="button" @click="toggleConfirmPasswordVisibility" class="toggle-confirm-password">
            <img :src="showConfirmPassword ? '/img/oeil.png' : '/img/oeil_ferme.png'" alt="Afficher/Masquer le mot de passe" />
          </button>
        </div>

        <br />
        <p>Vous déjà un compte ? <RouterLink to="/login" class="signIn-color-text">Connexion</RouterLink></p>
        <br />
        <button class="button_signIn" type="submit">Création</button>
      </form>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <p v-if="successMessage" class="success">{{ successMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "AppSignIn",
  data() {
    return {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      errorMessage: '',
      successMessage: '',
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    async signUp() {
      if (this.password !== this.confirmPassword) {
        this.errorMessage = "Les mots de passe ne correspondent pas.";
        return;
      }

      try {
        console.log("Email envoyé :", this.email);
        const response = await axios.post('https://kameleonback.jrcan.dev/api/signup/', {
          username: this.username,
          email: this.email,
          password: this.password,
        });
        this.successMessage = response.data.message;
        this.errorMessage = '';

        alert("Compte crée avec succès ! Pour vous connecter, veuillez valider votre adresse mail.");
        setTimeout(() => {
          this.$router.push('/login');
        }, 1000);
      } catch (error) {
        this.errorMessage = error.response.data.error || "Une erreur s'est produite.";
        this.successMessage = '';
      }
    },
  },
};
</script>


<style src="../assets/signIn.css"></style>
