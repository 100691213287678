<template>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
    <div class="rsa-puzzle-container">
      <h2 class="enigme15-title">Énigme N°19</h2>
      <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
      <p class="time_enigme-difficulty">   
        <span class="time_enigme-difficulty-level">Niveau : Impossible 🔴</span>
        <span class="time_enigme-attempts">Nombre de tentatives : 3</span> 
        <span class="time_enigme-points">Points : 50</span>
      </p>
  
      <p class="enonce">
        "Votre candidature doit comporter votre nom"
        "Choisissez p et q (premiers), puis renseignez n et φ(n). 
        Aucun calcul n’est fait pour vous: vérifiez vous-même! 
        Ensuite, trouvez e et d, puis chiffrez un message."
      </p>
  
      <!-- Étape 1 : p et q -->
      <div class="step-1">
        <h3>Étape 1 : Choisir p et q (premiers)</h3>
        <label>p </label>
        <input type="number" v-model.number="p" class="param-input" />
        <br>
        <label>q </label>
        <input type="number" v-model.number="q" class="param-input" />
        <br>
        <button @click="validatePQ" class="action-button">
          Valider p, q
        </button>
        <p v-if="pqMessage" class="validation-msg">{{ pqMessage }}</p>
      </div>
  
      <!-- Étape 2 : n et phi(n) fournis par l'utilisateur -->
      <div class="step-2" v-if="pOk && qOk">
        <h3>Étape 2 : Indiquer n et φ(n)</h3>
        <div>
          <label>n :</label>
          <input type="number" v-model.number="userN" class="param-input" />
          <label>φ(n) :</label>
          <input type="number" v-model.number="userPhi" class="param-input" />
          <button @click="validateNandPhi" class="action-button">Valider n, φ(n)</button>
        </div>
        <p v-if="nPhiMessage" class="validation-msg">{{ nPhiMessage }}</p>
      </div>
  
      <!-- Étape 3 : e -->
      <div class="step-3" v-if="nOk && phiOk">
        <h3>Étape 3 : Choisir e (copremier avec φ(n))</h3>
        <label>e :</label>
        <input type="number" v-model.number="e" class="param-input" />
        <button @click="validateE" class="action-button">
          Valider e
        </button>
        <p v-if="eMessage" class="validation-msg">{{ eMessage }}</p>
      </div>
  
      <!-- Étape 4 : d -->
      <div class="step-4" v-if="eOk">
        <h3>Étape 4 : Indiquer d tel que e×d ≡ 1 (mod φ(n))</h3>
        <label>d :</label>
        <input type="number" v-model.number="d" class="param-input" />
        <button @click="validateD" class="action-button">
          Valider d
        </button>
        <p v-if="dMessage" class="validation-msg">{{ dMessage }}</p>
      </div>
  
      <!-- Étape 5 : Chiffrer un message -->
      <div class="step-5" v-if="dOk">
        <h3>Étape 5 : Chiffrer votre pseudo</h3>
        <p>
          Vous pouvez faire le calcul vous-même : 
          c = m^e mod n
        </p>
      </div>
  
      <!-- Étape finale : l’utilisateur donne sa réponse -->
      <div class="answer-section" v-if="dOk">
        <h3>Votre réponse:</h3>
        <input 
          type="text"
          v-model="userAnswer"
          class="answer-input"
          placeholder="Recopiez votre message chiffré ici"
        />
        <button @click="validateAnswer" class="validate-button">
          Valider
        </button>
      </div>
  
      <!-- Message final -->
      <div class="validation-message" v-if="finalMessage">
        <p>{{ finalMessage }}</p>
      </div>
    </div>
  </template>
  
  <script>
  function isPrime(n) {
    if (n < 2) return false;
    for (let i = 2; i <= Math.sqrt(n); i++) {
      if (n % i === 0) return false;
    }
    return true;
  }
  function gcd(a, b) {
    if (b === 0) return a;
    return gcd(b, a % b);
  }
  export default {
    data() {
      return {
        // p, q
        p: null,
        q: null,
        pOk: false,
        qOk: false,
        pqMessage: "",
  
        // n, phi indiqués par l'utilisateur
        userN: null,
        userPhi: null,
        nOk: false,
        phiOk: false,
        nPhiMessage: "",
  
        // e, d
        e: null,
        d: null,
        eOk: false,
        dOk: false,
        eMessage: "",
        dMessage: "",
  
        // message chiffré
        plainMessage: "",
        cipherMessage: "",
  
        // réponse finale
        userAnswer: "",
        // on peut définir un expectedAnswer si on veut une solution unique
        // ou laisser l'utilisateur
        finalMessage: "",
      };
    },
    methods: {
      goBack() {
        this.$router.go(-1);
      },
      goToRules() {
        this.$router.push('/enigme_regle'); 
      },
      // Valider p, q
      validatePQ() {
        if (!this.p || !this.q) {
          this.pqMessage = "Veuillez renseigner p et q.";
          this.pOk = false;
          this.qOk = false;
          return;
        }
        if (!isPrime(this.p) || !isPrime(this.q)) {
          this.pqMessage = "p et q doivent être premiers.";
          this.pOk = false;
          this.qOk = false;
          return;
        }
        this.pqMessage = "p, q validés.";
        this.pOk = true;
        this.qOk = true;
      },
      // Valider n, phi
      validateNandPhi() {
        if (!this.userN || !this.userPhi) {
          this.nPhiMessage = "Veuillez renseigner n et φ(n).";
          this.nOk = false;
          this.phiOk = false;
          return;
        }
        // Vérifier userN = p*q
        if (this.userN !== this.p * this.q) {
          this.nPhiMessage = "n n'est pas égal à p×q !";
          this.nOk = false;
          this.phiOk = false;
          return;
        }
        // Vérifier userPhi = (p-1)*(q-1)
        if (this.userPhi !== (this.p - 1) * (this.q - 1)) {
          this.nPhiMessage = "φ(n) n'est pas (p-1)×(q-1) !";
          this.nOk = false;
          this.phiOk = false;
          return;
        }
        this.nOk = true;
        this.phiOk = true;
        this.nPhiMessage = "n, φ(n) validés.";
      },
      // Valider e
      validateE() {
        if (!this.e) {
          this.eMessage = "Veuillez renseigner e.";
          this.eOk = false;
          return;
        }
        if (!this.phiOk) {
          this.eMessage = "Validez d'abord n et φ(n).";
          this.eOk = false;
          return;
        }
        // Vérifier gcd(e, phi) = 1
        if (gcd(this.e, this.userPhi) !== 1) {
          this.eMessage = "e n'est pas copremier avec φ(n).";
          this.eOk = false;
          return;
        }
        this.eMessage = "e validé.";
        this.eOk = true;
      },
      // Valider d
      validateD() {
        if (!this.d) {
          this.dMessage = "Veuillez renseigner d.";
          this.dOk = false;
          return;
        }
        if (!this.eOk) {
          this.dMessage = "Validez d'abord e.";
          this.dOk = false;
          return;
        }
        // Vérifier e*d ≡ 1 mod phi(n)
        const modVal = (this.e * this.d) % this.userPhi;
        if (modVal !== 1) {
          this.dMessage = "e×d n’est pas ≡ 1 mod φ(n).";
          this.dOk = false;
          return;
        }
        this.dMessage = "d validé. Vous avez votre clé privée.";
        this.dOk = true;
      },
      // Valider la réponse finale
      validateAnswer() {
        // L'utilisateur recopie son cipherMessage
        // On peut faire un check minimal
        if (this.userAnswer.trim() === this.cipherMessage.trim()) {
          this.finalMessage = "Félicitations, vous avez chiffré le message via RSA correctement !";
        } else {
          this.finalMessage = "Ce n'est pas le même message chiffré. Réessayez.";
        }
      },
    },
  };
  </script>

<style src="../assets/enigmersa.css"></style>
  
