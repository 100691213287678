<template>
  <CalculatriceVue v-if="calculatrice"/>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="enigme13-puzzle-container">
    <!-- Énoncé -->
    <h2 class="enigme13-enonce-section">Énigme N°13</h2>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>

    <!-- Section en deux colonnes -->
    <div class="enigme13-columns">
      <!-- Colonne gauche : Téléchargements -->
      <div class="download-section">
        <p class="enonce">Les données cachées peuvent être révélées.</p>
        <h3>Téléchargements :</h3>
        <button @click="downloadFile1" class="download-button">
          Télécharger enigme13.pdf
        </button>
        <button @click="downloadFile2" class="download-button">
          Télécharger script_enigme13.py
        </button>
        <div class="download-section">
          <h3>Téléchargements du ZIP:</h3>
          <button @click="downloadFile1" class="download-zip-button">
            Télécharger le ZIP des 2 fichiers
          </button>
        </div>
      </div>

      <!-- Colonne droite : Indices -->
      <div class="hint-section">
        <button :disabled="false" @click="showHint(1)" :class="{ disabled: false }">Indice 1</button>
        <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1] }">Indice 2</button>
      </div>

      <!-- Afficher les indices demandés -->
      <div class="hints">
        <p v-if="hintsText">{{ hintsText }}</p>
      </div>
    </div>

    <!-- Champ pour le mot final -->
    <div class="enigme13-answer-row">
      <h3 class="enigme13-answer">Votre mot final :</h3>
      <input 
        type="text" 
        v-model="userAnswer" 
        class="enigme13-answer-input"
        placeholder="Entrez le mot clé" 
      />
      <button @click="validateAnswer" class="enigme13-validate-button">
        Valider
      </button>
    </div>

    <!-- Message final -->
    <div class="enigme13-validation-message" v-if="message">
      <p>{{ message }}</p>
    </div>
    <div class="quote-box">
    <p>" Vous lisez la mise en demeure, n’ayant pas fait d’études de droit vous ne sauriez pas dire si il l’a écrit lui même ou si un vrai avocat s’en ai chargé
      "</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import UserServices from "../services/UserServices";
import CalculatriceVue from "./Calculatrice.vue";
import JSZip from 'jszip';

export default {
  name: "EnigmeTelechargement",
  components: {
    CalculatriceVue
  },
  data() {
    return {
      file1Url: "/docs/enigme13.pdf",
      file2Url: "/scripts/script_enigme13.py",
      hints: {
        1: false,
        2: false
      },
      hintsText: "",
      userAnswer: "",
      expectedAnswer: "MAGIE",
      message: "",
      calculatrice: false,
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0, // Points de l'énigme
      riddle_difficulty: "", // Difficulté de l'énigme
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.haveCalculatrice();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(13);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(13, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(13, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(13, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(13, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    downloadFile1() {
      const link = document.createElement("a");
      link.href = this.file1Url;
      link.download = "enigme13.pdf";
      link.click();
    },
    downloadFile2() {
      const link = document.createElement("a");
      link.href = this.file2Url;
      link.download = "script_enigme13.py";
      link.click();
    },
    downloadZip() {
      const zip = new JSZip();
      zip.file("enigme13.pdf", fetch(this.file1Url).then(res => res.blob()));
      zip.file("script_enigme13.py", fetch(this.file2Url).then(res => res.blob()));
      zip.generateAsync({ type: "blob" }).then(content => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "enigme13_files.zip";
        link.click();
      });
    },
    async haveCalculatrice() {
      try {
        const response = await UserServices.haveCalculatrice();
        if (response) {
          console.log("calculatrice");
          this.calculatrice = true;
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la calculatrice :", error);
      }
    },
    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(13, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 13);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },
    async validateAnswer() {
      const userUp = this.userAnswer.toUpperCase().trim();
      try {
        const response = await riddleServices.IsRiddleSolved(13, userUp);
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
  }
};
</script>

<style src="../assets/enigmetelechargement.css"></style>