<template>
    <div class="succes-container">
        <div class="succes-content">
            <h1>Félicitation !</h1>
            <p>Vous venez de vérifier votre <span class="highlight-purple">adresse mail</span>.</p>
            <p>Vous pouvez désormais acceder a votre compte en vous connectant</p>
            <form class="succes-form">
                <input type="button" onclick="window.location.href = 'https://kameleon.jrcan.dev/login';"
                    value="En cliquant ici" />
            </form>
            <div class="succes">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style src="../assets/succesMail.css"></style>
