import axios from "axios";

export default {
  updateBio(data) {
    const accessToken = localStorage.getItem("accessToken");
    return axios.patch("https://kameleonback.jrcan.dev/api/members/update-bio/", data, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  async haveCalculatrice() {
    const accessToken = localStorage.getItem("accessToken");
    const response = await axios.get("https://kameleonback.jrcan.dev/api/members/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return response.data.have_calculatrice;
  },

  async fetchMembers() {
    const accessToken = localStorage.getItem("accessToken");
    return await axios.get("https://kameleonback.jrcan.dev/api/members/all/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },

  async fetchUserWithCV() {
    const accessToken = localStorage.getItem("accessToken");
    return await axios.get("https://kameleonback.jrcan.dev/api/users/cv/", {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};
