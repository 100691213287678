import axios from 'axios';

export default {

    createClan(data) {
        const accessToken = localStorage.getItem("accessToken");
        return axios.post(`https://kameleonback.jrcan.dev/api/clans/create/`, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },

    joinClan(data) {
        const accessToken = localStorage.getItem("accessToken");
        return axios.post(`https://kameleonback.jrcan.dev/api/clans/joinclan/`, data, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
            },
        });
    },

    getClan() {
        const accessToken = localStorage.getItem("accessToken");
        return axios.get(`https://kameleonback.jrcan.dev/api/clans/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },

    getDetailClanInfo(clanName) {
        const accessToken = localStorage.getItem("accessToken");
        return axios.get(`https://kameleonback.jrcan.dev/api/clans/${clanName}/`, {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    },

    getGlobalStats() {
        return axios.get(`https://kameleonback.jrcan.dev/api/clans/stats/`, {});
    }

}