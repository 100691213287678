<template>
  <div id="app" class="clan-page">
    <div class="clan-section-violette">
      <div class="content-clan">
        <h1>
          Avec le <span class="clan-highlight-purple">Clan</span>, chaque <br />
          défi devient une
          <span class="clan-highlight-green">victoire</span>.<br />
        </h1>
        <p>
          Rejoignez un clan en fonction de votre niveau <br />
          et participez à des guerres de clans palpitantes ! <br />
          <br />

          <span class="clan-highlight-purple">• Clan Public.</span><br />
          Accessible à tous, ce type de clan vous permet de découvrir le système de jeu et de vous entraîner avec
          d'autres joueurs de tous niveaux. <br /><br />
        </p>
      </div>
      <div class="clan-img">
        <img src="/img/clan.png" alt="Embleme de clan" class="embleme-clan" />
      </div>
    </div>

    <div class="clan-section-blanc">
      <h1>Quelques chiffres <span class="clan-highlight-purple">...</span></h1>
      <div class="clan-content">
        <div class="clan-content-1">
          <h2>Nombre de clan</h2>
          <p>Nombre total de clan crée</p>
          <h1>{{ numberOfClans }} <span class="clan-highlight-green">Clans</span></h1>
        </div>
        <div class="clan-content-1">
          <h2>Défi réalisé</h2>
          <p>Nombre total des défis réalisé inter-clan</p>
          <h1>{{ totalDefis }}  <span class="clan-highlight-purple">Défis</span></h1>
        </div>
        <div class="clan-content-1">
          <h2>Nombre de point</h2>
          <p>
            Tout les points récoltées depuis le début lors de la réalisation de
            défi
          </p>
          <h1>{{ totalScores }} <span class="clan-highlight-green">Points</span></h1>
        </div>
      </div>
    </div>

    
    <br><br>
  </div>
</template>

<script>
import ClanServices from "../services/ClanServices";
export default {
  name: "App",
  data() {
    return {
      numberOfClans: 0,
      totalDefis: 0,
      totalScores: 0,
    };
  },
  mounted() {
    this.getClanStats();
  },
  methods: {
    async getClanStats() {
      try {
        const response = await ClanServices.getGlobalStats();
        this.numberOfClans = response.data.total_clans;
        this.totalDefis = response.data.total_defis_realises;
        this.totalScores = response.data.total_scores;
      } catch (error) { 
        console.error("Erreur lors de la récupération des stats globales", error);
      }
    },
  },
};
</script>

<style src="../assets/clan.css"></style>
