<template>
  <div class="coop-riddles-main">
    <h2>Énigmes Coopératives</h2>

    <!-- Liste des énigmes -->
    <div class="riddles-list">
      <div
        v-for="riddle in allRiddles"
        :key="riddle.riddle_id"
        class="riddle-item"
        :class="{
          'achieved': isAchieved(riddle),
          'locked': isLocked(riddle),
        }"
        @click="!isLocked(riddle) ? handleRiddleClick(riddle) : null"
      >
        <h3>{{ riddle.riddle_type }}</h3>
        <p>Thème : {{ riddle.riddle_theme }}</p>
        <p>Difficulté : {{ riddle.riddle_difficulty }}</p>
      </div>
    </div>

    <!-- Panneau d'invitation -->
    <div class="invite-window" :class="{ open: isInviteWindowOpen }">
      <!-- Bouton pour fermer le panneau -->
      <button class="close-button" @click="closeInviteWindow">
        &times;
      </button>

      <!-- Titre de l'énigme sélectionnée -->
      <h3 v-if="selectedRiddle">
        Inviter des Membres pour "{{ selectedRiddle.riddle_type }}"
      </h3>

      <!-- Nombre de membres connectés -->
      <p v-if="selectedRiddle">
        Membres : {{ connectedMembers.length }}/{{ maxMembers }}
      </p>

      <!-- Liste des membres connectés et leur rôle -->
      <ul class="members-list" v-if="selectedRiddle">
        <li v-for="(member, index) in connectedMembers" :key="index">
          <span>{{ member.username }}</span> <span>({{ member.role }})</span>
        </li>
      </ul>

      <!-- Champ pour inviter un membre -->
      <input
        v-if="selectedRiddle"
        v-model="inviteeUsername"
        placeholder="Pseudo du membre"
        @keyup.enter="inviteMember"
      />
      <button
        class="invite-member-button"
        @click="inviteMember"
        :disabled="connectedMembers.length >= maxMembers || !inviteeUsername"
        v-if="selectedRiddle"
      >
        Inviter un Membre
      </button>

      <!-- Liste des invitations reçues -->
      <div class="invitations" v-if="selectedRiddle">
        <h3>Invitations Reçues</h3>
        <ul>
          <li v-for="invitation in receivedInvitations" :key="invitation.id">
            <!-- Exemple : on suppose que l’API renvoie 'inviter_username' et 'riddle_type' -->
            {{ invitation.inviter_username }} vous a invité à rejoindre la coopérative
            pour l'énigme '{{ invitation.riddle_type }}'.
            <button @click="respondToInvitation(invitation.id, 'accept')">
              Accepter
            </button>
            <button @click="respondToInvitation(invitation.id, 'reject')">
              Refuser
            </button>
          </li>
        </ul>
      </div>

      <!-- Bouton “Démarrer” visible seulement si on est Chef -->
      <button
        class="start-game-button"
        v-if="isChef"
        @click="startGame"
      >
        Démarrer l’énigme
      </button>

      <!-- Bouton pour fermer le panneau et revenir à la liste -->
      <button
        class="back-button"
        @click="closeInviteWindow"
        v-if="isInviteWindowOpen"
      >
        Retour à la liste
      </button>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
import coopServices from "../services/CoopServices";

export default {
  name: "CoopMainMenu",
  data() {
    return {
      allRiddles: [], // Toutes les énigmes coop
      achievedCoopRiddles: [], // Énigmes coop réussies par le membre
      lockedCoopRiddles: [], // Énigmes coop verrouillées pour le membre
      isInviteWindowOpen: false, // État de la fenêtre d'invitation
      connectedMembers: [], // Liste des membres connectés
      maxMembers: 3, // Nombre maximum de membres
      inviteeUsername: '', // Pseudo du membre à inviter
      receivedInvitations: [], // Liste des invitations reçues
      selectedRiddle: null, // Énigme actuellement sélectionnée pour l'invitation
      ws: null,
      isChef: false, // Flag pour savoir si c'est l'hôte
    };
  },
  methods: {
    async fetchRiddles() {
      try {
        // Récupérer toutes les énigmes coop
        const riddlesResponse = await riddleServices.fetchCoopRiddles();
        this.allRiddles = riddlesResponse.data;

        // Récupérer les énigmes coop du membre
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const memberRiddlesResponse = await riddleServices.fetchMemberCoopRiddles(username);

        this.achievedCoopRiddles = memberRiddlesResponse.data.achievedCoopRiddles;
        this.lockedCoopRiddles = memberRiddlesResponse.data.lockedCoopRiddles;
      } catch (error) {
        console.error("Erreur lors de la récupération des énigmes coop :", error);
        alert("Impossible de charger les énigmes. Veuillez réessayer plus tard.");
      }
    },
    isAchieved(riddle) {
      // Vérifie si l'énigme est dans les énigmes réussies
      return this.achievedCoopRiddles.some(achieved => achieved.riddle_id === riddle.riddle_id);
    },
    isLocked(riddle) {
      // Vérifie si l'énigme est dans les énigmes verrouillées
      return this.lockedCoopRiddles.some(locked => locked.riddle_id === riddle.riddle_id);
    },
    handleRiddleClick(riddle) {
      this.selectedRiddle = riddle;
      this.isInviteWindowOpen = true;
      
      // 1) Ouvrir la connexion WebSocket
      // ws:// ou wss:// selon config
      //const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const wsUrl = `${protocol}//kameleonback.jrcan.dev/ws/coop/${riddle.riddle_id}/`;

      this.ws = new WebSocket(wsUrl);

      // 2) Gérer les événements du WebSocket
      this.ws.onopen = () => {
        console.log("WebSocket connected");
      };

      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("WS message: ", data);

        if (data.type === 'init' || data.type === 'member_joined' || data.type === 'member_left') {
          // data.members = [{username, role}, ...]
          this.connectedMembers = data.members || [];
          // Vérifier si l'utilisateur courant est le chef
          const userInfo = JSON.parse(localStorage.getItem("userInfo"));
          this.isChef = this.connectedMembers.some(m => m.username === userInfo.username && m.role === 'Chef');
        }
        else if (data.type === 'start_game') {
          // L’hôte vient de démarrer le jeu !
          alert(data.message + " => Redirection vers la page de l'énigme !");
          // On peut rediriger par exemple :
          // this.$router.push(`/riddle/${this.selectedRiddle.riddle_id}/play`);
        }
      };
      this.ws.onclose = () => {
        console.log("WebSocket closed");
        this.connectedMembers = [];
        this.isChef = false;
      };

      // Charger via API la liste des invitations reçues, etc.
      // + tout ce que vous aviez déjà dans handleRiddleClick...
    },
    async fetchConnectedMembers(riddle_id) {
      try {
        const membersResponse = await coopServices.fetchConnectedMembers(riddle_id);
        this.connectedMembers = membersResponse.data;

        // Si aucun membre n'est connecté, l'utilisateur actuel est le chef
        if (this.connectedMembers.length === 0) {
          const userInfo = JSON.parse(localStorage.getItem("userInfo"));
          this.connectedMembers.push({
            id: userInfo.id,
            username: userInfo.username,
            role: "Chef",
          });
        } else {
          // Vérifier si un chef existe déjà
          const isChef = this.connectedMembers.some(
            (member) => member.role === "Chef"
          );
          if (!isChef && this.connectedMembers.length > 0) {
            this.connectedMembers[0].role = "Chef"; // Par défaut, le premier membre devient le chef
          }
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des membres connectés :", error);
        alert("Impossible de charger les membres connectés. Veuillez réessayer plus tard.");
      }
    },
    async fetchReceivedInvitations(riddle_id) {
      try {
        const response = await coopServices.fetchReceivedInvitations(riddle_id);
        this.receivedInvitations = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des invitations reçues :", error);
        alert("Impossible de charger les invitations reçues. Veuillez réessayer plus tard.");
      }
    },
    async inviteMember() {
      try {
        if (this.connectedMembers.length >= this.maxMembers) {
          alert("Nombre maximum de membres atteint.");
          return;
        }

        const newMemberUsername = this.inviteeUsername.trim();
        if (!newMemberUsername) {
          alert("Veuillez entrer un pseudo valide.");
          return;
        }

        // Assurez-vous qu'une énigme est sélectionnée
        if (!this.selectedRiddle) {
          alert("Aucune énigme sélectionnée.");
          return;
        }

        const riddle_id = this.selectedRiddle.riddle_id;

        await coopServices.inviteMemberToCoop(riddle_id, newMemberUsername);
        alert(`Invitation envoyée à ${newMemberUsername}`);
        this.fetchConnectedMembers(riddle_id);
        this.inviteeUsername = '';
      } catch (error) {
        console.error("Erreur lors de l'invitation d'un membre :", error);
        alert(
          error.response?.data?.error ||
            "Impossible d'inviter le membre. Veuillez réessayer plus tard."
        );
      }
    },
    async fetchReceivedInvitation(riddle_id) {
      try {
        const response = await coopServices.fetchReceivedInvitations(riddle_id);
        this.receivedInvitations = response.data;
      } catch (error) {
        console.error("Erreur lors de la récupération des invitations reçues :", error);
        alert("Impossible de charger les invitations reçues. Veuillez réessayer plus tard.");
      }
    },
    async respondToInvitation(invitation_id, response) {
      try {
        await coopServices.respondToInvitation(invitation_id, response);
        alert(
          `Invitation ${response === "accept" ? "acceptée" : "refusée"}.`
        );
        this.fetchReceivedInvitation(this.selectedRiddle.riddle_id);
        if (response === "accept") {
          // Rafraîchir la liste des membres connectés pour l'énigme correspondante
          this.fetchConnectedMembers(this.selectedRiddle.riddle_id);
        }
      } catch (error) {
        console.error("Erreur lors de la réponse à l'invitation :", error);
        alert(
          "Impossible de répondre à l'invitation. Veuillez réessayer plus tard."
        );
      }
    },
    closeInviteWindow() {
      // Fermer le panneau
      this.isInviteWindowOpen = false;
      this.selectedRiddle = null;
      this.connectedMembers = [];
      this.inviteeUsername = "";
      this.receivedInvitations = [];
      this.isChef = false;

      // 3) Fermer la socket
      if (this.ws) {
        this.ws.close();
        this.ws = null;
      }
    },
    startGame() {
      if (!this.ws) return;
      // Envoyer le message “start_game”
      this.ws.send(JSON.stringify({ action: 'start_game' }));
    },
  },
  async created() {
    // Charger les énigmes au démarrage
    await this.fetchRiddles();
  },
};
</script>

<style scoped>
.coop-riddles-main {
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative; /* position pour la fenêtre d'invitation */
}

.riddles-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 500px;
  overflow-y: auto;
}

.riddle-item {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.riddle-item:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.riddle-item.achieved {
  background-color: #d4edda; 
  color: #155724;
}
.riddle-item.locked {
  background-color: #e2e3e5;
  color: #6c757d;
  cursor: not-allowed;
}

/* Fenêtre d'invitation */
.invite-window {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  max-width: 400px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
  transition: right 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  z-index: 1000;
}
.invite-window.open {
  right: 0;
}

.close-button {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  background-color: #6c757d;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.close-button:hover {
  background-color: #5a6268;
  transform: scale(1.1);
}

.members-list {
  list-style: none;
  padding: 0;
  width: 100%;
  margin-bottom: 20px;
}
.members-list li {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #ccc;
}

.invite-member-button {
  padding: 10px 20px;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  margin-top: 10px;
}
.invite-member-button:hover {
  background-color: #218838;
  transform: scale(1.05);
}

.invitations {
  width: 100%;
  margin-top: 20px;
}
.invitations h3 {
  margin-bottom: 10px;
}
.invitations ul {
  list-style: none;
  padding: 0;
}
.invitations li {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.invitations button {
  margin-left: 10px;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.invitations button:first-of-type {
  background-color: #007bff;
  color: white;
}
.invitations button:first-of-type:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}
.invitations button:last-of-type {
  background-color: #dc3545;
  color: white;
}
.invitations button:last-of-type:hover {
  background-color: #c82333;
  transform: scale(1.05);
}

.start-game-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #ffc107;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: #212529;
  transition: background-color 0.3s, transform 0.2s;
}
.start-game-button:hover {
  background-color: #e0a800;
  transform: scale(1.05);
}

.back-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #17a2b8;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.back-button:hover {
  background-color: #138496;
  transform: scale(1.05);
}
</style>