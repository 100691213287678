<template>
  <div class="header-container">
      <button @click="goBack" class="bouton-gauche">Retour</button>
      <button @click="goToRules" class="bouton-gauche">Règles</button>
    </div>
  <div class="enigme5-puzzle-container">
    <h1>Énigme N°5</h1>
    <span class="time_enigme-completed-button">Énigmes réalisées {{ completedEnigmas }}/20</span>
    <p class="time_enigme-difficulty">   
      <span class="time_enigme-difficulty-level">Niveau : {{ this.riddle_difficulty }}</span>
      <span class="time_enigme-attempts">Nombre de tentatives : {{ errors_count }}</span> 
      <span class="time_enigme-points">Points : {{ this.riddle_points }}</span>
    </p>
    <div class="enigme5-columns">
      <div class="enigme5-column">
        <div class="enigme5-enonce-section">
          <p>Les chiffres ont parfois plusieurs significations.</p>
        </div>
        <div class="enigme5-image-section">
          <img :src="imageSource" alt="Image Enigme 5" class="enigme5-enigme-image" @click="downloadImage" />
        </div>
      </div>

      <div class="enigme5-column">
        <div class="enigme5-indices-section">
          <h4 class="enigme5-enonce-section">💡 Indices</h4>
          <button :disabled="false" @click="showHint(1)" :class="{ disabled: false, 'enigme5-hint-button': true }">Indice 1</button>
          <button :disabled="!hints[1]" @click="showHint(2)" :class="{ disabled: !hints[1], 'enigme5-hint-button': true }">Indice 2</button>
          <button :disabled="!hints[2]" @click="showHint(3)" :class="{ disabled: !hints[2], 'enigme5-hint-button': true }">Indice 3</button>

          <!-- Afficher les indices demandés -->
          <div class="enigme5-hint-text">
            <p v-if="hintsText">{{ hintsText }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="enigme5-answer-row">
      <h4>Votre réponse</h4>
      <div class="enigme5-answer-fields-vertical">
        <label>Champ 1 :</label>
        <input placeholder="Tapez ici la phrase" v-model="answerPart1" type="text" />
        <label>Champ 2 :</label>
        <input placeholder="Tapez ici la phrase" v-model="answerPart2" type="text" />
        <br />
        <button @click="validateAnswer" class="enigme5-validate-button">Valider</button>
      </div>
      <div class="enigme5-validation-message" v-if="message">
        <p>{{ message }}</p>
      </div>
    </div>
    <br />
    <div class="quote-box">
      <p>" À l’intérieur du coffre, vous trouvez aussi une photographie qui vous interpelle"</p>
    </div>
  </div>
</template>

<script>
import riddleServices from "../services/RiddleServices";
export default {
  name: "EnigmeCinq",
  data() {
    return {
      imageSource: "/img/enigme5.png",
      indices: ["opzjrioh", "ioeji", "Longueur et hauteur"],
      hints: {
        1: false,
        2: false,
        3: false,
      },
      hintsText: "",
      answerPart1: "",
      answerPart2: "",
      message: "",
      errors_count: 0, // Nombre d'erreurs
      riddle_points: 0,
      riddle_difficulty: "",
    };
  },
  mounted() {
    this.isRiddleLocked();
    this.createStatsForRiddle();
    this.incrementTries();
    this.fetchRiddlesInfo();
  },  
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goToRules() {
      this.$router.push('/enigme_regle'); 
    },
    async fetchRiddlesInfo() {
      try {
        const response = await riddleServices.fetchRiddleDetails(5);
        this.riddle_points = response.data.riddle_points;
        const difficulty = response.data.riddle_difficulty;
        if (difficulty === 1) {
          this.riddle_difficulty = "facile 🟢";
        } else if (difficulty === 2) {
          this.riddle_difficulty = "moyen 🟡";
        } else if (difficulty === 3) {
          this.riddle_difficulty = "difficile 🔴";
        } else if (difficulty === 4) {
          this.riddle_difficulty = "impossible ⚫";
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des informations de l'énigme :", error);
      }
    },
    async createStatsForRiddle() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.createRiddleStats(5, username);
        console.log("Statistiques de l'énigme créées avec succès.");
      } catch (error) {
        console.error("Erreur lors de la création des statistiques :", error);
      }
    },
    async incrementTries() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(5, username, 'increment_tries');
        console.log("Nombre d'essais incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des essais :", error);
      }
    },
    async incrementErrors() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(5, username, 'increment_errors');
        console.log("Nombre d'erreurs incrémenté.");
      } catch (error) {
        console.error("Erreur lors de l'incrémentation des erreurs :", error);
      }
    },
    async markSolved() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        await riddleServices.updateRiddleStats(5, username, 'mark_solved');
        console.log("Énigme marquée comme résolue.");
      } catch (error) {
        console.error("Erreur lors de la mise à jour des statistiques pour la résolution :", error);
      }
    },
    async isRiddleLocked() {
      try {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        const username = userInfo?.username;
        const response = await riddleServices.fetchMemberRiddles(username);
        const riddle = response.data;
        const isLocked = await riddleServices.checkIfRiddleIsLocked(riddle.lockedRiddles, 5);
        if (isLocked) {
          this.$router.push("/enigme_indisponible");
        }
      } catch (error) {
        const userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo) {
          this.$router.push("/enigme_indisponible");
          return;
        }
      console.error("Erreur lors de la vérification de l'énigme :", error);
    }
    },

    async showHint(hintNumber) {
      try {
        const hint = await riddleServices.getRiddleHint(5, hintNumber);
        this.hintsText = hint.hint;
        this.hints[hintNumber] = true;
      } catch (error) {
        console.error("Erreur lors de la récupération de l'indice :", error);
      }
    },

    async validateAnswer() {
      const result = {"width": Number(this.answerPart1), "height": Number(this.answerPart2)};
    
      try {
        const response = await riddleServices.IsRiddleSolved(5, result);
        if (response.is_solved) {
          this.message = "Bravo ! Vous avez trouvé la solution. Redirection dans 2 secondes";
          this.markSolved();
          await new Promise(resolve => setTimeout(resolve, 2000));
          this.$router.push('/skilltree');
        } else {
          this.message = "Erreur ! Essayez de nouveau.";
          this.incrementErrors();
          this.errors_count += 1;
        }
      } catch (error) {
        console.log(error);
      }
    },
    downloadImage() {
      const link = document.createElement("a");
      link.href = this.imageSource;
      link.download = "enigme5.png";
      link.click();
    },
  },
};
</script>

  
<style src="../assets/enigmeimage.css"></style>