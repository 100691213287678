<template>
    <div class="interactive-table">
      <table>
        <thead>
          <tr>
            <th v-for="(header, index) in headers" :key="index">
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(row, index) in rows" 
            :key="index" 
            :class="{ clickable: isClickable }"
            @click="isClickable ? handleRowClick(row) : null"
          >
            <td v-for="(value, key) in row" :key="key">
              {{ value }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "InteractiveTable",
    props: {
      headers: {
        type: Array,
        required: true,
      },
      rows: {
        type: Array,
        required: true,
      },
      isClickable: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      handleRowClick(row) {
        this.$emit("row-click", row);
      },
    },
  };
  </script>
  
  <style src="../assets/interactiveTable.css"></style>  
